import React, { useEffect, useState } from "react";
import SectionOne from "./card/SectionOne";
import SectionTwo from "./card/SectionTwo";
import SectionThree from "./card/SectionThree";
import SectionFour from "./card/SectionFour";
import SectionFive from "./card/SectionFive";
import SectionSix from "./card/SectionSix";
import SectionSeven from "./card/SectionSeven";
import SectionEight from "./card/SectionEight";
import SectionNinth from "./card/SectionNinth";
import SectionEnd from "./card/SectionEnd";
import { visitAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import uuid from "react-uuid";
import SectionIntro from "./card/SectionIntro";
import { useMediaQuery } from "react-responsive";
import MobileSectionFour from "./card/MobileSectionFour";
import MobileSectionTwo from "./card/MobileSectionTwo";
import useDeviceSize from "../../components/hooks/useDeviceSize";
import MobileLandingPage from "../../components/layout/MobileLandingPage";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { browserFetched } from "../../store/reducer/browerSlice";
import { LOCAL_STORAGE } from "../../lib/const";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../util/useContext";
import NoticeModal from "../../components/common/NoticeModal";

export default function LandingPage() {
  const { isDesktop } = useDeviceSize();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const isLogin = localStorage.getItem(LOCAL_STORAGE.LOGIN);
  const navigate = useNavigate();
  const { me } = useContext();

  // const browserData = useAppSelector(
  //   (state) => state.browser.browser,
  //   shallowEqual,
  // );
  //
  // async function onVisitantFetch({ browserData }: any) {
  //   console.log(browserData);
  //
  //   const res = await visitAxios.post(
  //     `${API_DEFAULT}/user/visit?browser=${browserData}`,
  //   );
  // }
  //
  // useEffect(() => {
  //   const visitData: any = localStorage.getItem("id");
  //
  //   if (visitData && browserData) {
  //     return;
  //   } else {
  //     localStorage.setItem("id", uuid());
  //     onVisitantFetch({ browserData });
  //   }
  // }, [browserData]);

  // useEffect(() => {
  //   if (isLogin && me?.agreementSelectiveTerms === false) {
  //     navigate("/social-check");
  //   } else if (isLogin && me?.agreementSelectiveTerms) {
  //     navigate("/feed");
  //   } else if (!isLogin) {
  //     navigate("/");
  //   }
  // }, [isLogin, me]);

  // const expireDay: any = localStorage.getItem("popupNoShow");
  // const [isPopupShow, setPopupShow] = useState(true);
  // const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  //
  // const closePopup = (expireDays: any) => {
  //   let expire: any = new Date();
  //   expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
  //   localStorage.setItem("popupNoShow", expire.getTime());
  // };
  //
  // const checkPopupClose = () => {
  //   const expireDay: any = localStorage.getItem("popupNoShow");
  //   let today: any = new Date();
  //
  //   if (today.getTime() > expireDay) {
  //     // 이렇게 하면 localStorage에 아무것도 저장되어 있지 않을 경우 undefined 거나 null 이므로 true를 반환한다.
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  //
  // const closePopupToday = () => {
  //   closePopup(1); // 하루 동안 표시 안 할 것임을 명시
  //   setPopupShow(false); // 오늘 하루 안 보기 누름과 동시에 팝업 사라짐
  // };
  //
  // useEffect(() => {
  //   checkPopupClose() ? setPopupShow(true) : setPopupShow(false);
  //   // 최초 컴포넌트가 마운트되었을 때, 팝업을 표시할 지 말지 조회
  // }, []);
  //
  // useEffect(() => {
  //   setInfoModal({ show: true });
  //   if (expireDay) {
  //     setPopupShow(false);
  //   } else {
  //     setPopupShow(true);
  //   }
  // }, [expireDay]);

  return (
    <>
      {isDesktop ? (
        <div className={"max-w-full"}>
          <SectionOne />
          {!isMobile && <SectionIntro />}
          {isMobile ? <MobileSectionTwo /> : <SectionTwo />}
          <SectionThree />
          {isMobile ? <MobileSectionFour /> : <SectionFour />}
          {!isMobile && <SectionFive />}
          {!isMobile && <SectionSix />}
          {!isMobile && <SectionSeven />}
          {!isMobile && <SectionEight />}
          {!isMobile && <SectionNinth />}
          {!isMobile && <SectionEnd />}
          <CustomFooter />
        </div>
      ) : (
        <MobileLandingPage />
      )}

      {/*{infoModal.show && isPopupShow && (*/}
      {/*  <NoticeModal*/}
      {/*    open={infoModal.show}*/}
      {/*    onClose={() => setInfoModal({ show: false })}*/}
      {/*    data={infoModal.data}*/}
      {/*    closePopupToday={closePopupToday}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
}
