//@ts-nocheck
import axios from "axios";
import { LOCAL_STORAGE } from "../lib/const";
import { API_DEFAULT } from "./api";

export const instance = axios.create({
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: true,
  async: true,
  crossDomain: true,
});

const _token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
const r_token = localStorage.getItem(LOCAL_STORAGE.R_TOKEN);
const browserData = localStorage.getItem(LOCAL_STORAGE.BROWSER);

// 토큰 재발급 상태와 대기열을 관리하기 위한 변수들
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// 요청 인터셉터
instance.interceptors.request.use(
  async (config) => {
    const url = config?.url;

    if (url && !url.includes("user/auth/sign-in")) {
      // 항상 최신 토큰을 가져옴
      const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// 응답 인터셉터
instance.interceptors.response.use(
  (response) => {
    return response; // 성공적인 응답은 그대로 반환
  },
  (error) => {
    const originalRequest = error.config;

    // 인증 오류인 경우 (예: 401) && 재시도하지 않은 경우에만
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // 이미 토큰 재발급 중이면 대기열에 요청을 추가하고 대기
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            // 새로운 토큰으로 헤더 업데이트 후 요청 재시도
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem(LOCAL_STORAGE.R_TOKEN);

      return new Promise(function (resolve, reject) {
        axios
          .post(`${API_DEFAULT}/user/auth/re-issue`, {
            accessToken: localStorage.getItem(LOCAL_STORAGE.TOKEN),
            refreshToken: refreshToken,
            browser: localStorage.getItem(LOCAL_STORAGE.BROWSER),
          })
          .then(({ data }) => {
            const newAccessToken = data.data.accessToken;
            const newRefreshToken = data.data.refreshToken;

            // 새로운 토큰 저장
            localStorage.setItem(LOCAL_STORAGE.TOKEN, newAccessToken);
            if (newRefreshToken) {
              localStorage.setItem(LOCAL_STORAGE.R_TOKEN, newRefreshToken);
            }

            // 기본 헤더 업데이트
            instance.defaults.headers.common["Authorization"] =
              "Bearer " + newAccessToken;

            // 대기열에 있는 요청들을 처리
            processQueue(null, newAccessToken);

            // 원래의 요청 헤더 업데이트 후 재시도
            originalRequest.headers["Authorization"] =
              "Bearer " + newAccessToken;
            resolve(instance(originalRequest));
          })
          .catch((err) => {
            if (err.response.data.error.message === "Another Browser") {
              const userConfirmed = window.confirm(
                "다른 브라우저에서 로그인 중 입니다.\n다시 로그인 할까요?",
              );

              if (userConfirmed) {
                // "네" 클릭 시 동작
                instance.delete(`${API_DEFAULT}/user/auth/sign-out`, {
                  data: {
                    accessToken: _token,
                    refreshToken: r_token,
                    browser: browserData,
                  },
                });
                localStorage.removeItem(LOCAL_STORAGE.TOKEN);
                localStorage.removeItem(LOCAL_STORAGE.R_TOKEN);
                localStorage.removeItem(LOCAL_STORAGE.EXPIRES_AT);
                localStorage.removeItem(LOCAL_STORAGE.LOGIN);
                localStorage.removeItem(LOCAL_STORAGE.BROWSER);

                window.location.href = `https://www.standbuy.io/login` as any;
              } else {
                instance.delete(`${API_DEFAULT}/user/auth/sign-out`, {
                  data: {
                    accessToken: _token,
                    refreshToken: r_token,
                    browser: localStorage.getItem(LOCAL_STORAGE.BROWSER),
                  },
                });
                localStorage.removeItem(LOCAL_STORAGE.TOKEN);
                localStorage.removeItem(LOCAL_STORAGE.R_TOKEN);
                localStorage.removeItem(LOCAL_STORAGE.EXPIRES_AT);
                localStorage.removeItem(LOCAL_STORAGE.LOGIN);
                localStorage.removeItem(LOCAL_STORAGE.BROWSER);

                window.location.href = `https://www.standbuy.io/login` as any;
              }
            } else if (
              err.response.data.error.message === "Auth Valid Fail" ||
              err.response.data.error.message === "Expired Request"
            ) {
              instance.delete(`${API_DEFAULT}/user/auth/sign-out`, {
                data: {
                  accessToken: _token,
                  refreshToken: r_token,
                  browser: localStorage.getItem(LOCAL_STORAGE.BROWSER),
                },
              });
              localStorage.removeItem(LOCAL_STORAGE.TOKEN);
              localStorage.removeItem(LOCAL_STORAGE.R_TOKEN);
              localStorage.removeItem(LOCAL_STORAGE.EXPIRES_AT);
              localStorage.removeItem(LOCAL_STORAGE.LOGIN);
              localStorage.removeItem(LOCAL_STORAGE.BROWSER);

              window.location.href = `https://www.standbuy.io/login` as any;
            } else {
              // 토큰 재발급 실패 시 대기열에 있는 요청들에 에러 전달
              processQueue(err, null);
              reject(err);
            }
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);
