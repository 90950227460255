import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/store";
import { shallowEqual, useSelector } from "react-redux";
import Toast from "./components/design-system/Toast";
import { toastFetched } from "./store/reducer/toastSlice";
import { useContext } from "./util/useContext";
import { isEmptyObject } from "./util/object";
import { LOCAL_STORAGE, SESSION_STORAGE } from "./lib/const";
import { signIn, signOut } from "./lib/modules/auth";
import { API_DEFAULT } from "./api/api";
import { useAxios, visitAxios } from "./lib/api/useAxios";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import LayoutComponent from "./components/layout/LayoutTemplete";
import { removeCookie, setCookie } from "./util/cookies";
import LoginAuthModal from "./components/design-system/modal/LoginAuthModal";
import {
  modalFetched,
  niceModalFetched,
  overlapModalFetched,
} from "./store/reducer/globalModalSlice";
import NiceAuthModal from "./components/design-system/modal/NiceAuthModal";
import { setStockListInit } from "./store/reducer/mainStock";
import ScrollToTop from "./components/hooks/useScrollReset";
import { useQuery } from "@tanstack/react-query";
import { uploadFlagFetched } from "./store/reducer/uploadEndSlice";
import UploadStatCheck from "./components/upload/UploadStatCheck";
//@ts-ignore
import TagManager from "react-gtm-module";
import { searchFlagFetched, setVersionData } from "./store/reducer/mobileSlice";
import { browserFetched } from "./store/reducer/browerSlice";
import OverlapModal from "./components/common/OverlapModal";
import uuid from "react-uuid";
import NoticeModal from "./components/common/NoticeModal";
import { instance } from "./api";

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const location = useGeolocation();
  const navigate = useNavigate();
  const toastData = useAppSelector((state) => state.toast.data, shallowEqual);
  const modalData = useAppSelector((state) => state.modal.data, shallowEqual);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual,
  );
  const niceData = useAppSelector(
    (state) => state.modal.niceData,
    shallowEqual,
  );
  const overlapData = useAppSelector(
    (state) => state.modal.overlapData,
    shallowEqual,
  );

  const { searchFlag } = useSelector((state: any) => state.mobile);
  const stat = useAppSelector((state) => state.uploadEnd.stat, shallowEqual);
  const { me } = useAppSelector((state) => state.auth);
  const { isLogin } = useContext();
  const { post, del, get } = useAxios();
  const { accessToken, refreshToken, expiresAt } = useAppSelector(
    (state) => state.auth,
  );

  const [refetchFlag, setRefetchFlag] = useState(false);
  // useEffect(() => {
  //   setCookie("isLogin", isLogin);
  // }, [isLogin]);

  useEffect(() => {
    TagManager.initialize({
      gtmId: `GTM-KXW2QMDT`,
    });
  }, []);

  function handleInAppBrowser() {
    const userAgent = navigator.userAgent;
    if (
      /inapp|NAVER|KAKAOTALK|Snapchat|Line|Instagram|WhatsApp|Electron|FBAN|FBAV|FBIOS|FBSS|SamsungBrowser/i.test(
        userAgent,
      )
    ) {
      dispatch(setVersionData(true));
    }
  }
  // 페이지 로드 시 인앱 브라우저 감지 및 처리
  window.onload = handleInAppBrowser;

  if (process.env.NODE_ENV === "production") {
    console = window.console || {};
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.error = function () {};
  }

  useEffect(() => {
    if (location.pathname === "/feed/filter/") {
      navigate("/feed");
    }

    dispatch(searchFlagFetched(false));
  }, [location]);

  const r_token = localStorage.getItem(LOCAL_STORAGE.R_TOKEN);

  async function refreshLogin() {
    const _token = await localStorage.getItem(LOCAL_STORAGE.TOKEN);

    const result =
      !!_token &&
      ((await signIn({
        dispatch: dispatch,
        data: { accessToken: _token },
      })) as any);

    // if (result?.status === "fail") {
    //   const res = await post(`${API_DEFAULT}/user/auth/re-issue`, {
    //     accessToken: _token,
    //     refreshToken: r_token,
    //   });
    //
    //   if (res?.success) {
    //     signIn({
    //       dispatch: dispatch,
    //       data: res?.data,
    //     });
    //     // window.location.reload();
    //   } else {
    //   }
    //
    //   // await del(`${API_DEFAULT}/user/auth/sign-out`, {
    //   //   accessToken: accessToken,
    //   //   refreshToken: refreshToken,
    //   // });
    //   // signOut({ dispatch: dispatch });
    // }
  }

  async function socialLogin(token: string) {
    const res = await post(`${API_DEFAULT}/user/auth/sign-in/success`, {
      socialToken: token,
      browser: browserData,
    });
    if (res?.success) {
      const result = (await signIn({
        dispatch: dispatch,
        data: res?.data,
      })) as any;

      if (result?.status === "fail") {
        await del(`${API_DEFAULT}/user/auth/sign-out`, {
          accessToken: accessToken,
          refreshToken: refreshToken,
          browser: browserData,
        });
        signOut({ dispatch: dispatch });
      } else {
        navigate("/", { replace: true });
      }
    } else {
      alert(JSON.stringify(res?.error, null, 1));
    }
  }

  useEffect(() => {
    refreshLogin();
  }, []);

  useEffect(() => {
    // 'socialToken' 파라미터의 존재 여부를 확인합니다.
    const temp = new URL(window.location.href).search;
    const params = new URLSearchParams(temp).get("socialToken");
    const hasSocialToken = new URLSearchParams(temp).has("socialToken");

    if (!!hasSocialToken) socialLogin(params?.toString() || "");
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      removeCookie("me");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== "/feed") {
      dispatch(setStockListInit());
    }
  }, [location.pathname]);

  const { data, status } = useQuery({
    queryKey: ["convert-stat", stat],
    queryFn: async () =>
      await instance.get(
        `${API_DEFAULT}/contents/convert-stat?convertId=${stat}`,
      ),
    enabled: stat !== "", // 조건에 따라 쿼리 활성화 여부 결정
    refetchInterval: stat !== "" ? 3000 : false,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (data?.data.data === "PROGRESSING") {
      setRefetchFlag(data?.data.data === "PROGRESSING");
    }
    if (data?.data.data === "COMPLETE") {
      setRefetchFlag(false);
      dispatch(
        toastFetched({
          show: true,
          text: "업로드를 완료 했습니다!",
          type: "",
        }),
      );
      dispatch(uploadFlagFetched(""));
    }
    if (data?.data.data === "ERROR") {
      setRefetchFlag(false);
      dispatch(
        toastFetched({
          show: true,
          text: "업로드에 실패했어요. 다시 시도해주세요.",
          type: "error",
        }),
      );
      dispatch(uploadFlagFetched(""));
    }
  }, [data?.data]);

  useEffect(() => {
    //*----------------------채널톡연동--------------------------*
    ChannelService.loadScript();
    // 로그인
    if (isLogin) {
      ChannelService.boot(
        {
          pluginKey: "618376fc-ab1c-42f0-85dc-a25e45c8644b", // 플러그인 키 기입, 키값은 그대로 사용해주시면 됩니다,
          memberId: me?.email, // email
          profile: {
            name: me?.name, // name
            mobileNumber: me?.phone, // phone
            birth: me?.birth, // birth
            grade: me?.grade, // grade
          },
        },
        function onBoot(error, user) {
          if (error) {
            console.error(error);
          } else {
            // console.log('boot success', user)
          }
        },
      );
    } else {
      // 익명 유저 (로그인하지 않았을 경우)
      ChannelService.boot(
        {
          pluginKey: "618376fc-ab1c-42f0-85dc-a25e45c8644b", // 플러그인 키 기입, 키값은 그대로 사용해주시면 됩니다,
        },
        function onBoot(error, user) {
          if (error) {
            console.error(error);
          } else {
            // console.log('boot success', user)
          }
        },
      );
    }
    //*----------------------채널톡연동종료--------------------------*
  }, [me]);

  function detectBrowser() {
    const userAgent = navigator.userAgent;
    const isMobile = /Mobile|Android/i.test(userAgent);

    if (
      userAgent.indexOf("Chrome") > -1 &&
      userAgent.indexOf("Edge") === -1 &&
      userAgent.indexOf("OPR") === -1
    ) {
      return isMobile ? "Mobile Chrome" : "PC Chrome";
    } else if (
      userAgent.indexOf("Safari") > -1 &&
      userAgent.indexOf("Chrome") === -1
    ) {
      return isMobile ? "Mobile Safari" : "PC Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      return isMobile ? "Mobile Firefox" : "PC Firefox";
    } else if (
      userAgent.indexOf("OPR") > -1 ||
      userAgent.indexOf("Opera") > -1
    ) {
      return isMobile ? "Mobile Opera" : "PC Opera";
    } else if (
      userAgent.indexOf("Edge") > -1 ||
      userAgent.indexOf("Edg") > -1
    ) {
      return isMobile ? "Mobile Edge" : "PC Edge";
    } else if (
      userAgent.indexOf("MSIE") > -1 ||
      userAgent.indexOf("Trident/") > -1
    ) {
      return "Internet Explorer";
    } else {
      return "Unknown";
    }
  }

  const browserCheck = detectBrowser();

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.BROWSER, detectBrowser());
    dispatch(browserFetched(detectBrowser()));
  }, []);

  async function onVisitantFetch(browserCheck: any) {
    const res = await visitAxios.post(
      `${API_DEFAULT}/user/visit?browser=${browserCheck}`,
    );
  }

  useEffect(() => {
    const visitData: any = localStorage.getItem("id");

    if (visitData) {
      return;
    } else {
      localStorage.setItem("id", uuid());
      onVisitantFetch(browserCheck);
    }
  }, []);

  return (
    <>
      <LayoutComponent>
        <Outlet />
      </LayoutComponent>
      <ScrollToTop />

      {toastData && (
        <Toast
          type={toastData.type || "success"}
          open={toastData.show}
          text={toastData.text}
          setOpen={() => {
            dispatch(
              toastFetched({
                show: false,
                text: "",
                type: "",
              }),
            );
          }}
        />
      )}
      {modalData.show && (
        <LoginAuthModal
          open={modalData.show}
          onClose={() => {
            dispatch(
              modalFetched({
                show: false,
              }),
            );
          }}
        />
      )}

      {niceData.show && (
        <NiceAuthModal
          open={niceData.show}
          onClose={() => {
            dispatch(
              niceModalFetched({
                show: false,
              }),
            );
          }}
        />
      )}

      {overlapData.show && (
        <OverlapModal
          open={overlapData.show}
          onClose={() => {
            dispatch(
              overlapModalFetched({
                show: false,
              }),
            );
          }}
        />
      )}
    </>
  );
}

export default App;
