import React, { useState } from "react";
import Text from "../../../components/design-system/Text";
import { motion } from "framer-motion";
import { Avatar } from "@material-tailwind/react";
import { Pagination } from "../../../components/design-system/Pagination";
import { checkRole } from "../../../util";
import { useNavigate } from "react-router-dom";

export default function MySubsList({ data }: any) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const firstPostIndex = (currentPage - 1) * postsPerPage;
  const lastPostIndex = firstPostIndex + postsPerPage;
  const currentPosts = data?.slice(firstPostIndex, lastPostIndex);

  return (
    <div>
      <Text className="dark:text-darkText">총 {data?.length}명</Text>

      <div className="w-full min-h-[660px] h-[660px]">
        <div className="grid grid-cols-8 gap-6">
          {currentPosts?.map((item: any, index: any) => {
            return (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <div className="w-full flex flex-col items-center mt-10 gap-5">
                  <Avatar
                    variant="circular"
                    alt="profile img"
                    src={
                      item?.profileImage
                        ? item?.profileImage
                        : "/img/standbuy/icon/no-profile.svg"
                    }
                    placeholder={undefined}
                    className="border border-[#134B70] ring-2 ring-[#134B70] p-0.5 w-[80px] h-[80px] cursor-pointer"
                    size="lg"
                    onClick={() =>
                      navigate(`/studio/${item?.memberId}`, {
                        state: item?.memberId,
                      })
                    }
                  />

                  <div className="flex flex-col gap-[6px] max-w-[120px]">
                    <p className="break-words dark:text-darkText truncate text-[14px] font-semibold text-[#353535]">
                      {item?.nickname}
                    </p>
                  </div>

                  <Text fontSize={14} fontWeight={600} color="#828282">
                    {checkRole(item?.grade)}
                  </Text>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>

      <Pagination
        postsNum={data?.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
}
