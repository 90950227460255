import React, { useEffect, useState } from "react";
import Card from "../../components/design-system/Card";
import Text from "../../components/design-system/Text";
import Switcher from "../../components/common/Switcher";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import FillButton from "../../components/design-system/button/FillButton";
import { useMediaQuery } from "react-responsive";

export default function LoginErrorPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const navigate = useNavigate();
  const [email, setEmail] = useState<any>("");
  const [type, setType] = useState<any>("");

  useEffect(() => {
    //http://localhost:3000/login-error/?email=chlgusgh0815@gmail.com&type=chlgusgh0815@gmail.com
    const temp = new URL(window.location.href).search;
    const params = new URLSearchParams(temp).get("email");
    const paramsTwo = new URLSearchParams(temp).get("type");
    setEmail(params);
    setType(paramsTwo);
  }, []);

  return (
    <div className="bg-gray-200 dark:bg-black h-screen flex justify-center items-center">
      <Switcher className="hidden" />
      <div
        className={`w-full flex flex-col items-center gap-12 ${isMobile && "mx-5"}`}
      >
        <img
          src={
            themeData === "light" || themeData === ""
              ? "/img/standbuy/logo/logo.svg"
              : "/img/standbuy/logo/logo-white.svg"
          }
          alt="standbuy"
          onClick={() => navigate("/")}
          className="cursor-pointer"
          width={200}
        />
        <Card>
          <div className="flex flex-col overflow-auto">
            <Text className="subtitle-1 dark:text-darkText">로그인 실패</Text>
            <Text className="subtitle-2 dark:text-darkText mt-4">
              이미 중복된 이메일 계정이 존재합니다.
            </Text>

            <div className="mt-4">
              <Text
                fontSize={16}
                fontWeight={500}
                color="#535353"
                className="dark:text-darkText"
              >
                - 이메일 : {email}
              </Text>
              <Text
                fontSize={16}
                fontWeight={500}
                color="#535353"
                className="dark:text-darkText"
              >
                - 가입경로 : {type}
              </Text>
            </div>

            <FillButton
              onClick={() => navigate("/")}
              text="홈으로"
              className="mt-10"
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
