import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  searchFlag: false,
  versionData: false,
};

export const mobileSlice = createSlice({
  name: "mobile",
  initialState: initialToastState,
  reducers: {
    searchFlagFetched: (state, action) => {
      state.searchFlag = action.payload;
    },
    setVersionData: (state, action) => {
      state.versionData = action.payload;
    },
  },
});

export const { searchFlagFetched, setVersionData } = mobileSlice.actions;

export default mobileSlice.reducer;
