import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const feedSearchDetail = async (payload: any) => {
  const url = `${API_DEFAULT}/contents/search/detail`;
  const response = await instance
    .get(url, { params: { contentId: payload } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const itemModify = async (payload: any) => {
  const url = `${API_DEFAULT}/contents/modify`;
  const response = await instance
    .post(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const updateLocation = async (payload: any) => {
  const url = `${API_DEFAULT}/contents/modify/location`;
  const response = await instance
    .post(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const itemResell = async (payload: any) => {
  const url = `${API_DEFAULT}/user/contents-box/re-sell`;
  const response = await instance
    .post(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const contentsInsight = async (contentId: any) => {
  const url = `${API_DEFAULT}/contents/search/insight?contentId=${contentId}`;
  const response = await instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
