import React, { useEffect, useRef, useState } from "react";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import CustomCheckBox from "../../design-system/CustomCheckBox";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Text from "../../design-system/Text";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { shallowEqual } from "react-redux";

export default function KeywordCheckBox() {
  const params = useParams();
  const [checked, setChecked] = useState(false);
  const [checkedVideo, setCheckedVideo] = useState(false);
  const [checkedSubs, setCheckedSubs] = useState(false);
  const [checkedRoll, setCheckedRoll] = useState(false);
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  useEffect(() => {
    if (params.value) {
      const valuesArray = params.value.split(",");
      setChecked(valuesArray.includes("IMAGE"));
      setCheckedVideo(valuesArray.includes("VIDEO"));
      setCheckedSubs(valuesArray.includes("구독자"));
      setCheckedRoll(valuesArray.includes("디렉터"));
    }
  }, [params]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { get } = useAxios();
  const [info, setInfo] = useState<any[] | null>(null);
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    if (params.value) {
      const valuesArray: any = params.value.split(",");
      if (checkedItems.length < 1) {
        setCheckedItems(valuesArray);
      }
    }
  }, [checkedItems, params]);

  async function onInit() {
    const res = await get(`${API_DEFAULT}/contents/search/get/keywords`);
    if (res?.success) {
      setInfo(res?.data);
    } else {
    }
  }

  useEffect(() => {
    onInit();
  }, []);

  const handleCheckboxChange = (event: any) => {
    const value = event.target.name;
    const newCheckedItems: any = event.target.checked
      ? [...checkedItems, value]
      : checkedItems.filter((item) => item !== value);

    setCheckedItems(newCheckedItems);

    const checkedItemsString = newCheckedItems.join(",");
    navigate(`/feed/filter/${checkedItemsString}`, {
      state: newCheckedItems,
      replace: true,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex  items-center gap-4">
        <CustomCheckBox
          label="구독한 아티스트"
          labelStyle="label-1"
          checked={checkedSubs}
          onChange={handleCheckboxChange}
          name={"구독자"}
        />
      </div>
      <div className="flex items-center gap-4">
        <CustomCheckBox
          label="디렉터 콘텐츠만"
          labelStyle="label-1"
          checked={checkedRoll}
          onChange={handleCheckboxChange}
          name={"디렉터"}
        />
      </div>

      <hr className="my-5" />

      <Text className="subtitle-3">콘텐츠 유형</Text>
      <CustomCheckBox
        label="사진/그림"
        labelStyle="label-1"
        checked={checked}
        onChange={handleCheckboxChange}
        name={"IMAGE"}
      />

      <CustomCheckBox
        label="영상"
        labelStyle="label-1"
        checked={checkedVideo}
        onChange={handleCheckboxChange}
        name={"VIDEO"}
      />
      <hr className="my-5" />

      <div className="flex items-center gap-2">
        <Text className="subtitle-3">추천 키워드</Text>
        <FontAwesomeIcon
          icon={faRotateRight}
          className={`w-[14px] h-[14px] cursor-pointer ${
            themeData === "light" || themeData === "" ? "" : "text-darkText"
          }`}
          onClick={onInit}
        />
      </div>
      {info?.map((keyword, index) => (
        <CustomCheckBox
          key={index}
          name={keyword}
          checked={undefined}
          onChange={handleCheckboxChange}
          label={keyword}
          labelStyle="label-1"
        />
      ))}
    </div>
  );
}
