//@ts-nocheck
import { shallowEqual } from "react-redux";
import Text from "../../../design-system/Text";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../../../store/store";
import KeywordCheckBox from "../KeywordCheckBox";

export default function FilterContainer({ toggle, setToggle }: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  return (
    <div
      className={`sticky top-[100px] transition-all duration-300 ease-in-out mr-4 ${
        toggle
          ? "w-[220px] px-5 py-6 rounded-xl border-[1px] border-gray overflow-y-auto"
          : ""
      }`}
      style={{ maxHeight: "calc(100vh - 100px)" }}
    >
      {toggle && (
        <>
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon
                icon={faSliders}
                className={`w-[14px] h-[14px] ${
                  themeData === "light" || themeData === ""
                    ? ""
                    : "text-darkText"
                }`}
              />
              <Text fontWeight={700}>필터</Text>
            </div>
            <img
              src="/img/standbuy/icon/filterToggle.svg"
              alt=""
              className="cursor-pointer"
              onClick={() => setToggle(!toggle)}
            />
          </div>
          <KeywordCheckBox />
        </>
      )}
      {!toggle && (
        <IconButton
          variant="outlined"
          className="rounded-full focus:ring-transparent"
          size="sm"
          onClick={() => setToggle(!toggle)}
        >
          <img
            src="/img/standbuy/icon/filterToggle.svg"
            alt=""
            className="cursor-pointer"
          />
        </IconButton>
      )}
    </div>
  );
}
