import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";
import { useEffect, useRef } from "react";

export default function ToastViewer({ writing }: any) {
  const viewerRef = useRef<any>(null);

  useEffect(() => {
    if (viewerRef.current) {
      viewerRef.current.getInstance().setMarkdown(writing || "");
    }
  }, [writing]);

  return <Viewer ref={viewerRef} />;
}
