import React, { useEffect, useRef, useState } from "react";
import Text from "../../components/design-system/Text";
import { Pagination } from "../../components/design-system/Pagination";
import { motion } from "framer-motion";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT, URL_HOST } from "../../api/api";
import { useMutation } from "@tanstack/react-query";
import FeedInfoModal from "../feed/modal/FeedInfoModal";
import ResellModal from "./modal/ResellModal";
import FeedRemoveModal from "../../components/feed/modal/FeedRemoveModal";
import { useAppDispatch } from "../../store/store";
import Dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ResellWarningModal from "./modal/ResellWarningModal";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { instance } from "../../api";

export default function LockerList({ lockerItem }: any) {
  const navigate = useNavigate();
  const { post, postFile } = useAxios();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const dispatch = useAppDispatch();
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const [editModal, setEditModal] = useState<any>({ show: false, data: "" });
  const [alarmModal, setAlarmModal] = useState<any>({ show: false, data: "" });
  const [removeModal, setRemoveModal] = useState<any>({
    show: false,
    data: "",
  });
  const [resellModal, setResellModal] = useState<any>({
    show: false,
    data: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [fileUrl, setFileUrl] = useState();

  const firstPostIndex = (currentPage - 1) * postsPerPage;
  const lastPostIndex = firstPostIndex + postsPerPage;
  const currentPosts = lockerItem?.slice(firstPostIndex, lastPostIndex);

  const handleDownload = (fileUrl: any) => {
    if (fileUrl !== undefined) {
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = "downloadedFile"; // 필요하다면 실제 파일 이름으로 대체
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  async function fileDownload({ buyType, contentId, manifestName }: any) {
    const res = await instance.post(`${API_DEFAULT}/contents/download`, {
      buyType: buyType,
      contentId: contentId,
      manifestName: manifestName,
    });

    if (res?.data.success) {
      setFileUrl(res.data.data.downloadUrl);
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: fileDownload,
    onSuccess: (fileUrl) => {
      // setLoading(true);
      // handleDownload(fileUrl);
    },
  });

  useEffect(() => {
    handleDownload(fileUrl);
  }, [fileUrl]);

  const formatDateWithOneYearAdded = (date: any) => {
    return Dayjs(date).add(1, "year").format("YYYY.MM.DD");
  };

  const popupWidth = 1400;
  const popupHeight = 1000;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  // 팝업 창을 화면 가운데에 위치시키기 위한 좌표 계산
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  useEffect(() => {
    window.navigateToPage = (url: string, options?: any) => {
      navigate(url, options);
    };
  }, [navigate]);

  return (
    <>
      <div>
        <div className="flex justify-between mx-5">
          <Text className="dark:text-darkText">총 {lockerItem?.length}개</Text>

          {isMobile && (
            <Pagination
              postsNum={lockerItem?.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </div>

        <div className="w-full flex flex-col min-h-[660px] h-[660px]">
          <div className={`${isMobile ? "" : "grid grid-cols-5 gap-4"}`}>
            {currentPosts?.map((item: any, index: any) => {
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  key={index}
                >
                  <div
                    className={`flex ${isMobile ? "" : "flex-col"}  mt-10 gap-3 mx-5 mb-10`}
                    key={index}
                  >
                    <div className="relative">
                      <img
                        src={item?.contentThumbnailPath}
                        alt=""
                        className={` ${isMobile ? "w-[100px] h-[100px]" : "w-full h-[200px]"} rounded-lg object-cover cursor-pointer`}
                        onClick={() => {
                          window.name = JSON.stringify({
                            contentId: item?.contentId,
                          });
                          window.open(
                            `${URL_HOST}feed-info`,
                            "feed",
                            `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`,
                          );
                        }}
                      />

                      <div className={"absolute right-2 bottom-2"}>
                        <img
                          className={"min-w-[50px]"}
                          src={
                            item?.buyType === "TICKET"
                              ? "/img/standbuy/icon/ticket-badge.svg"
                              : "/img/standbuy/icon/ownership-badge.svg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="absolute right-1 top-1">
                        <Menu
                          placement="bottom-end"
                          //@ts-ignore
                          dismiss={true}
                        >
                          <MenuHandler>
                            <IconButton
                              //@ts-ignore
                              variant="text"
                              children={undefined}
                              placeholder={undefined}
                            >
                              <img
                                src="/img/standbuy/icon/menu-dot.svg"
                                alt=""
                              />
                            </IconButton>
                          </MenuHandler>
                          <MenuList //@ts-ignore
                            children={undefined}
                            placeholder={undefined}
                            className="min-w-[120px] text-[14px] text-[#2f2f2f] font-semibold"
                          >
                            {item?.buyType === "TICKET" ||
                            item?.resellStatus === true ? null : (
                              <MenuItem
                                //@ts-ignore
                                children={undefined}
                                placeholder={undefined}
                                onClick={() => {
                                  setResellModal({ show: true, data: item });
                                }}
                              >
                                판매 시작
                              </MenuItem>
                            )}

                            <MenuItem
                              //@ts-ignore
                              children={undefined}
                              placeholder={undefined}
                              onClick={() => {
                                setAlarmModal({ show: true, data: item });
                              }}
                            >
                              파일 다운로드
                            </MenuItem>
                            {(item?.download ||
                              item?.resellStatus === true) && (
                              <MenuItem
                                //@ts-ignore
                                children={undefined}
                                placeholder={undefined}
                                onClick={() =>
                                  navigate("/cert", {
                                    state: {
                                      boxId: item?.boxId,
                                      state: item?.contentId,
                                      type: item?.buyType,
                                    },
                                  })
                                }
                              >
                                인증서 다운로드
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      </div>
                    </div>

                    <div className={`flex flex-col gap-[6px]`}>
                      <Text
                        className="locker-list-title max-w-[237px] break-words dark:text-darkText"
                        color="#111"
                      >
                        {item?.contentName}
                      </Text>
                      <Text
                        className="locker-list-nickname dark:text-darkText"
                        color="#4C4C4C"
                      >
                        {item?.producerNickname}
                      </Text>
                      <div
                        className={`flex items-center justify-between ${isMobile && "min-w-[193px]"}`}
                      >
                        <Text
                          className="locker-list-type  dark:text-darkText"
                          color="#929292"
                        >
                          {item?.buyType === "TICKET" ? "이용권" : "소유권"}
                        </Text>

                        {item?.buyType === "TICKET" && (
                          <Text
                            className="locker-list-type  dark:text-darkText"
                            color="#929292"
                          >
                            {formatDateWithOneYearAdded(item?.createTime)} 까지
                          </Text>
                        )}
                      </div>

                      {item?.resellStatus && (
                        <div
                          className={
                            "bg-[#EBF1EE] w-[46px] flex justify-center py-1 rounded"
                          }
                        >
                          <Text
                            fontSize={13}
                            fontWeight={600}
                            color={"#42BC7F"}
                          >
                            판매중
                          </Text>
                        </div>
                      )}
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
          {isMobile && <MobileFooter />}
        </div>

        {isMobile ? null : (
          <Pagination
            postsNum={lockerItem?.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </div>

      {infoModal.show && (
        <FeedInfoModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
      {resellModal.show && (
        <ResellModal
          open={resellModal.show}
          onClose={() => setResellModal({ show: false })}
          data={resellModal.data}
        />
      )}
      {removeModal.show && (
        <FeedRemoveModal
          open={removeModal.show}
          onClose={() => setRemoveModal({ show: false })}
          data={removeModal.data}
        />
      )}
      {alarmModal.show && (
        <ResellWarningModal
          open={alarmModal.show}
          onClose={() => setAlarmModal({ show: false })}
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
          data={alarmModal.data}
        />
      )}
    </>
  );
}
