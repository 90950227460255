//@ts-nocheck
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  googleMapPanTo,
  isLoadedGoogleApi,
  loadGoogleApi,
} from "./google-map-util";
import { GoogleMapProps } from "./google-map";
import { useAppDispatch } from "../../../store/store";
import {
  boundsFetched,
  zoomFetched,
} from "../../../store/reducer/mapBoundSlice";
import debounce from "lodash/debounce";

const GoogleMap = forwardRef((props: GoogleMapProps, ref) => {
  const dispatch = useAppDispatch();
  const mapElement = useRef(null);
  const createdMarkers = useRef([]) as any;
  const infoWindows = useRef<google.maps.InfoWindow[]>([]);
  const mapInstance = useRef<google.maps.Map | null>(null);

  const initMap = useCallback(() => {
    const { google } = window;
    if (!mapElement.current || !google) return;
    infoWindows.current = [];
    createdMarkers.current = [];

    const _mapOptions = {
      zoom: 12,
      center: {
        lat: 37.5656,
        lng: 126.9769,
      },
      zoomControl: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: "greedy",
      ...props.mapOptions,
    };

    const map = new google.maps.Map(mapElement.current, _mapOptions);

    mapInstance.current = map;

    map.addListener("zoom_changed", () => {
      const currentZoomLevel = map.getZoom();
      dispatch(zoomFetched({ zoom: currentZoomLevel }));
    });

    map.addListener("bounds_changed", () => {
      const bounds: any = map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      dispatch(
        boundsFetched({
          ne: { lat: ne.lat(), lng: ne.lng() },
          sw: { lat: sw.lat(), lng: sw.lng() },
        }),
      );
    });

    map.addListener("mousemove", (e: google.maps.MapMouseEvent) => {
      if (props.onMousemove) props.onMousemove(e);
    });

    map.addListener("click", (e: google.maps.MapMouseEvent) => {
      const _position: any = {
        lat: e.latLng?.lat(),
        lng: e.latLng?.lng(),
        zoom: map.getZoom(),
      };
      if (props.isAddMarker) {
        const newMarker = new google.maps.Marker({
          position: _position,
          map: map,
        });
        createdMarkers.current.push(newMarker);
      }
      infoWindows.current.forEach((window) => window.close());
      if (props.onClick) props.onClick(_position);
    });

    if (props.onInit) props.onInit(map);
    window.googlemap = map;
    if (props.positions) window.googlemap.positions = props.positions;
  }, [
    props.mapOptions,
    props.onMousemove,
    props.onClick,
    props.isAddMarker,
    props.onInit,
    dispatch,
  ]);

  const updateMarkers = useCallback(
    debounce((positions) => {
      if (!mapInstance.current || !positions) return;

      // 기존 마커 제거
      createdMarkers.current.forEach((marker: google.maps.Marker) => {
        marker.setMap(null);
      });
      createdMarkers.current = [];
      infoWindows.current = [];

      // 같은 좌표에 있는 마커들을 그룹화
      const positionMap = new Map();
      positions.forEach((markerData) => {
        const key = `${markerData.lat},${markerData.lng}`;
        if (!positionMap.has(key)) {
          positionMap.set(key, []);
        }
        positionMap.get(key).push(markerData);
      });

      // 각 그룹별로 마커 생성
      positionMap.forEach((group, key) => {
        group.forEach((markerData, index) => {
          // 오프셋 계산
          const offset = index * 0.00005; // 오프셋 크기 조절 가능
          const angle = (index / group.length) * 360; // 각도 계산
          const radians = (angle * Math.PI) / 180;

          const offsetLat = markerData.lat + offset * Math.cos(radians);
          const offsetLng = markerData.lng + offset * Math.sin(radians);

          const position = { lat: offsetLat, lng: offsetLng };

          // 마커 아이콘 또는 라벨을 'count' 값에 따라 조절
          const markerOptions: google.maps.MarkerOptions = {
            position: position,
            map: mapInstance.current,
          };

          if (markerData.count && markerData.count > 1) {
            markerOptions.label = {
              text: String(markerData.count),
              color: "white",
              fontSize: "14px",
            };
            markerOptions.icon = {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#4285F4",
              fillOpacity: 0.7,
              scale: Math.min(markerData.count * 2, 60),
              strokeColor: "white",
              strokeWeight: 1,
            };
          } else {
            // 단일 마커의 경우 기본 아이콘 사용
            if (props.markerIcon) {
              markerOptions.icon = {
                url: props.markerIcon.url,
                scaledSize: new google.maps.Size(
                  props.markerIcon.width,
                  props.markerIcon.height,
                ),
              };
            }
          }

          const googleMarker = new google.maps.Marker(markerOptions);

          createdMarkers.current.push(googleMarker);

          let content = markerData.content || "인포윈도우";
          if (markerData.renderItem)
            content = markerData.renderItem(markerData);

          const infoWindow = new google.maps.InfoWindow({
            content: `<div class="google-map-infowindow">${content}</div>`,
          });

          google.maps.event.addListener(infoWindow, "domready", () => {
            if (props.onInfoWindowDomReady)
              props.onInfoWindowDomReady(markerData);
          });

          markerData.infoWindow = infoWindow;
          markerData.marker = googleMarker;
          infoWindows.current.push(infoWindow);

          // 클릭 이벤트 추가
          googleMarker.addListener("click", () => {
            infoWindows.current.forEach((info) => info.close());

            if (markerData.count && markerData.count > 1) {
              // 클러스터 마커 클릭 시 줌인 및 지도 이동
              const currentZoom = mapInstance.current.getZoom();
              const targetZoom = currentZoom + 2; // 원하는 만큼 줌인
              mapInstance.current.setZoom(targetZoom);
              mapInstance.current.panTo(googleMarker.getPosition());

              // 클러스터 내 아이템 목록 표시 (옵션)
              if (props.onClusterClick) {
                props.onClusterClick(markerData);
              }
            } else {
              // 일반 마커 클릭 시 인포윈도우 표시 또는 상세 페이지 이동
              infoWindow.open(mapInstance.current, googleMarker);
              if (props.onMarkerClick) props.onMarkerClick(markerData);
            }
          });
        });
      });
    }, 100),
    [
      props.markerIcon,
      props.onInfoWindowDomReady,
      props.onMarkerClick,
      props.onClusterClick,
    ],
  );

  useImperativeHandle(ref, () => ({
    addMarker: (data: any) => {
      if (!data) return;
      const newMarker = new google.maps.Marker({
        position: data,
        map: window.googlemap,
      });
      createdMarkers.current.push(newMarker);
    },
    setCenter: (data: any) => {
      if (data) googleMapPanTo(data);
    },
    setZoom: (data: any) => {
      if (data && window.googlemap) window.googlemap.setZoom(data);
    },
  }));

  useEffect(() => {
    if (isLoadedGoogleApi()) {
      initMap();
    } else {
      window.initMap = initMap;
      loadGoogleApi({
        key: props.API_KEY,
        language: "ko",
        region: "KR",
        callback: "initMap",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.API_KEY]);

  useEffect(() => {
    updateMarkers(props.positions);
  }, [props.positions, updateMarkers]);

  return (
    <section
      style={{ width: "100%", height: "500px", ...props.style }}
      ref={mapElement}
    >
      {/* 이곳에 지도가 표시됩니다. */}
    </section>
  );
});

export default GoogleMap;
