import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";

export default function SectionIntro() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const videoEl: any = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error: any) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();

    const removeDownloadButton = () => {
      if (videoEl.current) {
        const videoControls = videoEl.current.controlsList;
        if (videoControls) {
          videoControls.add("nodownload"); // 최신 브라우저에서 다운로드 버튼 제거
        } else {
          // 폴백: controlsList를 지원하지 않는 브라우저의 경우
          const downloadButton = videoEl.current.parentElement.querySelector(
            'button[title="Download"]',
          );
          if (downloadButton) {
            downloadButton.remove();
          }
        }
      }
    };

    videoEl.current &&
      videoEl.current.addEventListener("loadeddata", removeDownloadButton);

    return () => {
      videoEl.current &&
        videoEl.current.removeEventListener("loadeddata", removeDownloadButton);
    };
  }, []);

  return (
    <div className="flex flex-col justify-between">
      <video
        playsInline
        loop
        muted
        controls
        src="/video/standbuy-seller-video.mp4"
        ref={videoEl}
      />
    </div>
  );
}
