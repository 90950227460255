import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Wrap = styled.div`
  box-shadow: rgba(9, 30, 66, 0.1) 0px 8px 12px 0px;
`;

export default function Card({ children, width = "max-w-[480px]" }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <Wrap
      className={`bg-white dark:bg-darkBg p-12 w-full ${width} rounded-3xl ${isMobile && "mx-5"}`}
    >
      {children}
    </Wrap>
  );
}
