import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

export default function FadeMotionDiv({ children, flag = false }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <motion.div
      className={`w-full mx-auto flex flex-col items-center justify-center ${flag ? "h-full" : ""} ${isMobile && "mx-5"}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
}
