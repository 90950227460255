import styled from "styled-components";
import { CustomNavBar } from "./CustomNavBar";
import CustomFooter from "./footer/CustomFooter";
import { useLocation } from "react-router-dom";
import useDeviceSize from "../hooks/useDeviceSize";
import ResponsivePage from "./ResponsivePage";
import UploadStatCheck from "../upload/UploadStatCheck";
import React from "react";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";

const Wrap = styled.div`
  height: 100vh;
`;

const Child = styled.div<any>`
  height: 100%;
`;

const LayoutComponent = ({ children }: any) => {
  const location = useLocation();
  const { isDesktop } = useDeviceSize();
  const stat = useAppSelector((state) => state.uploadEnd.stat, shallowEqual);

  return (
    <>
      <Wrap className="flex flex-col">
        {location?.pathname === "/" ||
        location?.pathname.includes("/login") ||
        location?.pathname.includes("/join") ||
        location?.pathname.includes("/password") ||
        location?.pathname.includes("/nice") ||
        location?.pathname === "/social-check" ||
        location?.pathname === "/reset-auth" ||
        location?.pathname === "/feed-info" ||
        location?.pathname.includes("/reset-password") ? null : (
          <CustomNavBar />
        )}
        <Child pathName={location.pathname}>{children}</Child>
        {stat !== "" && <UploadStatCheck />}
        {location?.pathname === "/" ||
        location?.pathname.includes("/login") ||
        location?.pathname.includes("/join") ||
        location?.pathname.includes("/password") ||
        location?.pathname.includes("/feed") ||
        location?.pathname.includes("/cart") ||
        location?.pathname.includes("/locker") ||
        location?.pathname.includes("/pay") ||
        location?.pathname.includes("/studio") ||
        location?.pathname.includes("/landing") ||
        location?.pathname.includes("/notice") ||
        location?.pathname.includes("/my-subs") ||
        location?.pathname.includes("/fof-list") ||
        location?.pathname.includes("/fof-credit") ||
        location?.pathname.includes("/exchange-detail") ||
        location?.pathname.includes("/my-page") ||
        location?.pathname.includes("/sales") ||
        location?.pathname.includes("/location-feed") ||
        location?.pathname.includes("/nice") ||
        location?.pathname.includes("/terms") ||
        location?.pathname === "/upload" ||
        location?.pathname === "/upload-end" ||
        location?.pathname.includes("/my-studio") ||
        location?.pathname === "/social-check" ||
        location?.pathname === "/policy" ||
        location?.pathname === "/reset-auth" ||
        location?.pathname.includes("/reset-password") ||
        location?.pathname === "/cert" ||
        location?.pathname === "/location-terms" ||
        location?.pathname === "/upload-sccuess" ||
        location?.pathname.includes("/share") ||
        location?.pathname === "/feed-info" ||
        location?.pathname.includes("/mobile-feed") ||
        location?.pathname === "/profile-edit" ||
        location?.pathname === "/exchange" ||
        location?.pathname === "/alarm" ? null : (
          <CustomFooter />
        )}
      </Wrap>
    </>
  );
};
export default LayoutComponent;
