import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import TermsPage from "../../terms/TermsPage";
import Text from "../../../components/design-system/Text";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Content = styled.div`
  .wrapper {
    //padding: 24px;
    font-size: 16px;
    line-height: 140%;
    flex-direction: column;
    background: #fff;
    background-color: #fff;
    overflow-y: auto;
    max-height: 850px;
    h2 {
      padding-left: 10px;
      color: #111111;
      font-size: 14px;
    }
    ul {
      margin-bottom: 20px;
      padding-left: 20px;
      li {
        /* padding-left: 10px; */
        color: #5f5f5f;
        font-size: 12px;
      }
    }
    p {
      color: #5f5f5f;
      font-size: 12px;
    }
  }
`;

export default function JoinTermsModal({ open, onClose }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <Modal
      open={open}
      onClose={onClose}
      width={isMobile ? 375 : 600}
      headerNone
    >
      <div className="flex justify-end my-4">
        <FontAwesomeIcon
          icon={faXmark}
          onClick={onClose}
          fontSize={24}
          className="cursor-pointer"
        />
      </div>
      <Content>
        <div className="wrapper">
          <h1>스탠바이 이용약관</h1>

          <br />
          <h1>제 1 장 총칙</h1>

          <br />

          <h2>제1조 (목적)</h2>
          <p>
            이 약관은 주식회사 에프오에프(이하 “회사”라 한다)에서 운영하는
            스탠바이(standbuy.io/앱 포함)에서 웹사이트, 모바일 기기 등을 통해
            제공하는 디지털 콘텐츠 거래 관련 서비스(이하 “스탠바이 서비스”라
            한다)를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항, 기타
            필요한 사항을 규정함을 목적으로 합니다.
          </p>

          <br />

          <h2>제2조 (용어의 정의)</h2>
          <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              “스탠바이”란 “회사”가 재화 또는 용역 및 디지털 콘텐츠를 이용자에게
              제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 또는 용역
              및 디지털 콘텐츠를 거래할 수 있도록 설정한 가상의 영업장을
              말합니다.
            </li>
            <li>
              “이용자”란 “스탠바이”에 접속하여 본 약관에 따라 "스탠바이"가
              제공하는 서비스를 받는 회원 및 비회원을 말하며, “콘텐츠 라이선스
              규정”을 준수하여야 합니다.
            </li>
            <li>
              “회원”이라 함은 "회사"가 운영하는 “스탠바이”에 개인정보를 제공하여
              회원등록을 한 사업자 또는 사람을 말하며 “스탠바이”의 이용정책 및
              “콘텐츠 라이선스 규정”에 따라 스탠바이가 제공하는 서비스를
              이용하는 자를 말합니다.
            </li>
          </ul>

          <ol
            style={{
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              1.“비회원”이라 함은 회원에 가입하지 않고 "스탠바이"가 제공하는
              서비스를 이용하는 자를 말합니다.
            </li>

            <li>
              2. “아이디(ID)”란 이용자의 식별과 서비스 이용을 위하여 이용자가
              정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다
            </li>

            <li>
              3. “비밀번호(Password)”란 이용자가 부여받은 아이디와 일치되는
              이용자임을 확인하고 비밀 보호를 위해 이용자 자신이 정한 문자 또는
              숫자의 조합을 의미합니다.
            </li>

            <li>
              4. “모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수
              있는 기기로서, PC, 휴대폰, 스마트폰, 휴대정보단말기(PDA), 태블릿,
              각종 디지털기기 등을 의미합니다.
            </li>

            <li>
              5. “웹사이트 등”이란 회사가 서비스 제공을 위하여 운영하는
              웹사이트(http://주소기입) 및 모바일 기기를 통해 다운로드 받거나
              설치하여 사용하는 프로그램 및 모바일 기기를 통해 접속할 수 있는
              네트워크, 클라이언트 프로그램 등 일체를 의미합니다.
            </li>

            <li>
              6. “스탠바이 서비스”란 회사가 standbuy.io/앱에서 제공하는 서비스를
              통칭하며, 이 약관이 정하는 바에 따라 콘텐츠의 판매, 중개 및 기타
              정보서비스를 의미합니다.
            </li>

            <li>
              7. "콘텐츠 사용권"이라 함은 이 약관에 따라 콘텐츠를 1년의 기간
              동안 사용할 수 있는 권한을 의미합니다. 사용권에는 콘텐츠를 이용할
              수 있는 권리가 포함되나, 지적재산권 이전은 포함되지 않습니다.
            </li>

            <li>
              8. "콘텐츠"라 함은 이 약관에서 정한 바에 따라 콘텐츠 사용권 대상이
              되는 저작물, 자료, 정보 등을 의미합니다. 콘텐츠는 문자, 영상,
              음원, 이미지 등 그 형태를 불문하며, 회사에서 제공하는 모든 자료를
              포괄합니다.
            </li>
            <li>
              9. "콘텐츠"라 함은 이 약관에서 정한 바에 따라 콘텐츠 사용권 또는
              콘텐츠 소유권의 대상이 되는 저작물, 자료, 정보 등을 의미합니다.
              콘텐츠는 문자, 영상, 음원, 이미지 등 그 형태를 불문하며, 회사에서
              제공하는 모든 자료를 포괄합니다.
            </li>
          </ol>

          <br />

          <h2>제3조 (약관의 명시와 개정)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명,
              사업자등록번호, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가
              알 수 있도록 “스탠바이”의 서비스화면(전면)에 게시합니다. 다만, 본
              약관의 내용은 이용자가 연결화면을 통하여 볼 수 있습니다.
            </li>
            <li>
              회사는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법,
              정보통신망 이용촉진 등에 관한 법률, 소비자보호법 등 관련법을
              위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
            </li>
            <li>
              회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
              현행약관과 함께 “스탠바이” 사이트의 초기화면에 그 적용
              일자7일전부터 적용 일자 전 일까지 공지함으로써 그 효력이
              발생합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는
              경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이
              경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여
              이용자가 알기 쉽도록 표시합니다.
            </li>
            <li>
              회사가 약관을 개정할 경우에는 그 개정약관은 그 적용 일자 이후에
              체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는
              개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한
              이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한
              개정약관의 공지기간 내에 회사에 송신하여 회사의 동의를 받은
              경우에는 개정약관 조항이 적용됩니다.
            </li>

            <li>
              이 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는
              「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에
              관한 법률」, 「콘텐츠산업진흥법」 기타 관계 법령 또는 상관례에
              따릅니다 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
              정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에
              따릅니다.
            </li>
          </ul>

          <h2>제4조 (운영정책)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              약관을 적용하기 위하여 필요한 사항과 약관에서 구체적 범위를 정하여
              위임한 사항을 서비스 운영정책(이하 “운영정책”이라 합니다)으로 정할
              수 있습니다. 회사는 운영정책의 내용을 회원이 알 수 있도록 서비스
              내 또는 그 연결화면에 게시합니다.
            </li>
            <li>
              운영정책을 개정하는 경우에는 제3조 제3항의 절차에 따릅니다. 다만,
              운영정책 개정내용이 다음 각 호의 어느 하나에 해당하는 경우에는
              사전에 공지합니다.
            </li>
          </ul>

          <ol
            style={{
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              1) 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우
            </li>

            <li>2) 회원의 권리·의무와 관련 없는 사항을 개정하는 경우</li>

            <li>
              3) 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고
              회원이 예측할 수 있는 범위 내에서 운영정책을 개정하는 경우
            </li>
          </ol>

          <br />

          <h1>제2장 서비스의 이용과 제한</h1>

          <br />

          <h2>제5조 (회원의 이용계약의 성립)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <Text fontSize={14}>
              회원의 콘텐츠는 아래와 같은 상업적 용도로 사용됩니다.
            </Text>
            <ul
              style={{
                listStyle: "inside",
                color: "#A7A7A7",
                fontSize: "12px",
                fontWeight: "500",
                marginTop: "8px",
              }}
            >
              <li>
                이용계약은 이용자가 “스탠바이”의 “회원가입약관” 및 “콘텐츠
                라이선스 규정”, 개인정보취급방침”에 동의한다는 의사표시를
                함으로써 “스탠바이”의 이용을 신청하고, 회사는 그 신청을
                승낙함으로써 성립됩니다.
              </li>
              <li>
                회사는 가입신청자의 신청에 대하여 승낙함을 원칙으로 합니다.
                다만, 회사는 다음 각호의 어느 하나에 해당하는 이용 신청에
                대해서는 승낙을 거절할 수 있습니다.
              </li>
              <li>
                이용신청서 내용을 허위로 기재하거나 필수 제공사항인 정보를
                누락하거나 오기하여 신청하는 경우 또는 이용신청 요건을 충족하지
                못한 경우
              </li>
              <li>
                회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인
                방법을 통해 서비스를 이용하는 경우
              </li>
              <li>
                「콘텐츠산업진흥법」등 관련 법령에서 금지하는 행위를 할 목적으로
                신청하는 경우
              </li>

              <li>
                사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우
              </li>
              <li>부정한 용도로 서비스를 이용하고자 하는 경우</li>
              <li>타인 또는 허위 명의로 이용신청을 한 경우</li>

              <li>
                하나 이상의 계정을 가진 회원이 복수의 계정을 사용하고자 하는
                경우
              </li>

              <li>
                다른 이용자의 서비스 이용을 방해하거나 그 정보를 도용하는 등
                전자상거래 질서를 위협하는 경우
              </li>

              <li>
                가입신청자가 회원탈퇴 또는 신청자의 귀책사유로 인한 회사의 계약
                해지로 회원 자격을 상실한 사실이 있는 경우
              </li>

              <li>
                회사로부터 서비스 이용 정지 조치 등을 받은 자가 그 조치기간 중에
                이용계약을 임의 해지하고 재이용신청을 하는 경우
              </li>

              <li>
                그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는
                경우
              </li>

              <li>
                회원 이용계약의 성립시기는 “회사”의 승낙이 회원에게 전자우편
                또는 SMS(휴대폰 문자 메시지)가 도달한 시점, 회원가입 시 요청한
                아이디 계정으로 첫 회 로그인을 한 경우 중 어느 하나에 속하는
                시점부터 회원 이용계약이 성립됩니다.
              </li>

              <li>
                회원의 본 이용정책에 대한 동의는 이용신청 시 “스탠바이” 회원가입
                절차 중 회원가입약관 동의 메뉴에서 “위 약관에 동의합니다.”를
                체크함으로써 의사표시를 합니다.
              </li>

              <li>
                이용자가 만 14세 미만인 경우에는 법정대리인의 동의를 받아
                이용신청을 할 수 있고, 이 때 동의서를 첨부하여 이용 신청을
                하여야 합니다.
              </li>
            </ul>
          </ul>

          <br />

          <h2>제 6조 (회원의 이용신청)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원으로 가입하고자 하는 이용자는 반드시 회사가 요청하는
              제반정보(실명, 연락처, 핸드폰번호, 이메일, 주소, 사업자명,
              사업자번호 등)을 거짓 없이 등록해야 합니다. 등록하신 제반 정보와
              일치하지 않을 경우 이용자는 일체의 권리를 주장할 수 없습니다.
            </li>
            <li>
              타인의 명의를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며,
              관계법령에 따라 처벌을 받을 수 있습니다.
            </li>
            <li>
              회사는 본 서비스를 이용하는 회원에 대하여 등급(라이선스)별로
              구분하여 서비스 이용에 차등을 둘 수 있습니다.
            </li>
            <li>
              회원이 등록하신 제반 정보가 변경된 경우, 회원 본인이 직접 변경해야
              합니다.
            </li>
          </ul>

          <br />

          <h2>제7조 (회원 구분 및 가입비)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              “스탠바이”의 모든 서비스는 이용자의 콘텐츠 사용 용도 등에 따라
              회원을 분류하여 가입비를 적용하고 있으며, 회원 별 가입비와 서비스
              기간은 회사의 사정으로 인해 변동될 수 있으며, 변동 시 사이트를
              통해 사전 공지하여야 합니다.
            </li>
            <li>회원 구분</li>
            <ul>
              <li>
                1) 아티스트 : 회사의 이용 승낙 후 “스탠바이” 내 무료서비스를
                이용할 수 있는 회원으로, 이용기간은 회원이 탈퇴할 때까지입니다.
              </li>
              <li>
                2) 디렉터 : 회사의 선정 기준에 적합한 아티스트를 디렉터로 선정
                합니다. 이용 승낙 후 “스탠바이” 내 프리미엄 서비스를 이용할 수
                있는 회원으로, “디렉터 안내” 페이지에서 디렉터의 혜택을 확인할
                수 있습니다.
              </li>
            </ul>
          </ul>

          <br />

          <h2>제8조 (회원 ID 부여/변경 및 이용기간)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 회원에게 이메일 당 한 개의 ID만을 제공합니다.
              개인(개인사업자 포함)의 경우 1인 또는 1개의 사업자등록번호
              기준으로 1개의 ID를, 법인 또는 단체의 경우 1개의 사업자등록번호를
              기준으로 1개의 ID를 제공합니다.
            </li>
            <li>모든 회원의 이용기간은 회원이 탈퇴할 때 까지 입니다.</li>
          </ul>

          <br />

          <h2>제9조 (서비스의 내용)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 판매중개자로서 회원 간의 자유로운 상품 등의 거래를 위한
              시스템을 운영 및 관리, 제공 하며 구체적으로 다음과 같은 업무를
              수행합니다.
            </li>

            <ul>
              <li>
                1) 디지털 콘텐츠에 대한 정보 제공 및 콘텐츠 사용권 거래에 대한
                중개 서비스
              </li>

              <li>
                2) 구매계약이 체결된 재화 또는 용역 및 디지털 콘텐츠의 전송 및
                배송
              </li>

              <li>3) 중개 서비스에 대한 인증서 제공</li>

              <li>4) 기타 회사가 정하는 업무.</li>
            </ul>

            <li>
              아티스트(판매회원)은 스탠바이에서 콘텐츠를 구매회원에게 그
              콘텐츠의 사용권 판매할 수 있습니다.
            </li>
            <li>
              콘텐츠 사용권에 대한 매매계약은 아티스트(판매회원)이 제시한 콘텐츠
              등의 판매 조건에 응하여 구매회원이 청약의 의사표시를 하고 이에
              대하여 아티스트(판매회원)이 승낙의 의사를 표시함으로써 체결됩니다.
            </li>

            <li>
              아티스트(판매회원)과 구매회원은 회사의 정책 및 안내에 따라
              정하여진 방법에 따라 계약을 체결하고, 구매회원은 PG사 결제시스템을
              이용하여 구매 합니다.
            </li>

            <li>
              아티스트(판매회원)과 구매회원이 계약을 체결하고 구매금액의 정산이
              완료된 건에 대하여 계약이 취소·해지·해제 등 종료하는 경우, 회사는
              이에 개입하거나 책임지지 않습니다.
            </li>

            <li>
              회사는 구매회원 또는 아티스트(판매회원)을 대리하지 않고, 회사는
              아티스트(판매회원)이 등록한 콘텐츠 등의 내용, 지적재산권 보유 및
              적법성, 거래조건에 대해서 어떠한 보증이나 대리를 하지 않으며,
              구매회원은 전적으로 본인의 책임 하에 상품 등을 구매해야 하고,
              구매회원과 아티스트(판매회원) 사이에 성립된 거래와 관련된 책임과
              회원이 제공한 정보에 대한 책임은 해당 회원이 직접 부담하여야
              합니다.
            </li>

            <li>
              회사는 구매회원과 아티스트(판매회원) 간의 모든 행위(거래행위
              포함)와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 등록
              콘텐츠의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한
              비침해성, 구매회원 또는 아티스트(판매회원)이 입력하는 정보 및 그
              정보를 통하여 연결된 웹사이트에 게재된 자료의 진실성 또는 적법성
              등 일체(계약의 체결, 아티스트(판매회원)이 콘테츠에 대한 적법한
              권리 보유, 콘텐츠 등의 하자·손상 등)에 대하여 보증하지 아니하며,
              이와 관련된 일체의 위험과 책임은 해당 회원들이 부담합니다.
            </li>
          </ul>

          <h2>제10조 (사용권 거래)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              아티스트(판매회원)은 스탠바이에서 콘텐츠를 구매회원에게 그
              콘텐츠의 사용권을 판매할 수 있으며, 스탠바이 결제 시스템을 통해
              거래해야 합니다.
            </li>
            <li>
              2024년 10월 25일 이후 “콘텐츠 소유권 거래”를 잠정 중단 합니다.
              이전에 게시한 소유권판매 게시글은 아래와 같이 조치 됩니다
            </li>
            <ul>
              <li>
                - 사용권과 소유권을 함께 판매하고 있는 경우 : 사용권 판매 유지
              </li>
              <li>
                - 소유권만 판매하고 있는 경우 : 소유권 판매 게시글을 사용권 판매
                게시로 전환
              </li>
            </ul>
            <li>
              회사는 회원이 업로드 혹은 제출한 콘텐츠에 대해 중개 및 마케팅용도,
              데이터 수집 용도로 활용에 대한 권한을 가집니다.
            </li>
            <li>
              사용권을 구매한 콘텐츠의 사용 권한에 대한 안내는 다음과 같습니다.
            </li>
            <ul>
              <li>
                1) 웹디자인(홈페이지, 웹 배너, 팝업, 뉴스레터, 쇼핑몰, 블로그,
                SNS 등)
              </li>
              <li>2) 모바일 디자인(모바일 홈페이지, 애플리케이션 등)</li>
              <li>
                3) 인쇄 디자인(브로셔, 명함, 비판매출판, 실사 출력, 사보,
                카탈로그 등)
              </li>
              <li>
                4) 광고 디자인(옥외광고, 신문광고, 홍보용 포스터, 현수막, 전단
                등){" "}
              </li>
              <li>5) 영상디자인</li>
              <li>6) 출판물(서적, e-book, 교과서, 학습지, 단행본 등)</li>
              <li>7) 기타 상업적 용도의 모든 콘텐츠</li>
            </ul>
            <li>
              회원은 개별 콘텐츠 사용권 계약 및 소유권 판매계약 건에 대하여,
              해당 콘텐츠의 재산권 및 초상권, 지적재산권 등 권리를 보유하고
              있음을 별도로 동의하며, 이러한 요구는 콘텐츠 업로드 시 혹은 별도의
              계약서를 통해 이루어집니다. 이에 대한 동의서를 작성합니다.
            </li>

            <li>
              사용권 구매시, 1년의 콘텐츠의 사용권한이 부여 됩니다. 해당 약관
              내용에 의하여, 사용범위를 숙지하여야 합니다. 사용한 콘텐츠는
              재판매를 할 수 없습니다.
            </li>

            <li>사용권에 대한 비독점적 이용 허락은 다음과 같습니다.</li>

            <ul>
              <li>
                가. 본 계약의 이용허락 대상이 되는 권리는 판매자가 판매하는
                콘텐츠 사용권을 구매했을 때, 그 콘텐츠에 대해 권리를 취득할 수
                있습니다.
              </li>
              <li>
                나. 사용권을 구매한 콘텐츠의 이용허락 기간은 구매일을 기준으로
                1년으로 계산 합니다.
              </li>
              <li>
                다. 사용권 구매자는 대상 콘텐츠의 이용권을 제3자에게 양도할 수
                없습니다.
              </li>
            </ul>

            <li>콘텐츠 소유권 거래에 대한 권한은 다음과 같습니다.</li>

            <ul>
              <li>
                가. 콘텐츠 소유권 거래는 구매회원이 콘텐츠에 대한 재산권, 일체의
                저작권(2차 저작물 작성권 포함), 상표권, 특허권 등 모든
                지적재산권을 포함한 권리를 판매회원에게 이전하는 것입니다.
              </li>
              <li>
                나. 판매회원은 구매회원의 허락 없이 저작 콘텐츠를 이용할 수
                없고, 판매회원은 콘텐츠에 관한 권리를 제3자에게 양도하거나
                질권을 설정, 이용허락을 위한 설정계약 등을 하여서는 안됩니다.
              </li>
            </ul>
          </ul>

          <br />

          <h2>제11조 (콘텐츠의 관리)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              “스탠바이”에서 회원에게 제공하는 콘텐츠는 회사의 독자적 판단에
              의해 임의로 삭제, 변경할 수 있습니다.
            </li>
            <li>
              저작권 및 제 3자의 권리침해 등 콘텐츠에 문제가 발생할 경우 회사는
              이를 공지함(사이트 공지 또는 e-mail 공지)으로써 회원들에 대한
              고지의무를 다한 것으로 하며, 회원은 그 사용을 중지해야 합니다.
              콘텐츠 문제에 대한 공지 후 사용으로 인한 모든 법적 책임은 회사에
              있지 않고 사용한 회원에게 있습니다.
            </li>
            <li>
              회원이 스탠바이에 업로드한 모든 콘텐츠는 회사가 제공하는 서비스
              개선 및 신서비스 개발 등의 목적으로 마케팅 및 빅데이터 분석 및 AI
              기술에 활용될 수 있습니다.
            </li>
            <li>
              회원은 콘텐츠 관리에 대하여 동의함으로써 서비스를 제공하고
              홍보하기 위한 목적으로 회원 콘텐츠를 복제, 수정, 2차적저작물 작성,
              공중송신(전송 포함), 배포, 전시, 공연 등의 방식으로 온라인 또는
              오프라인으로 직접 이용하고 제3자에게 이용허락 할 수 있는
              권리(저작권 및 초상권에 대한 이용권리 포함)을 부여합니다.
            </li>
            <li>
              회사는 회원이 다른 회원 또는 제 3자의 저작권 또는 초상권, 명예 등
              권리를 침해하는 경우, 『저작권법』, 『정보통신망이용촉진 및
              정보보호 등에 관한 법률』을 포함한 관계 법령에 정해진 절차에
              의하여 회원의 콘텐츠를 삭제 또는 블라인드 처리할 수 있으며, 관련
              법령에 따른 의무를 준수하는 경우 해당 법령에 따라 회사는
              면책됩니다.
            </li>
            <li>
              회사는 제3항의 빅데이터 분석 및 AI 기술 적용 과정에서
              개인정보보호법 등 관계 법령을 준수하며, 회원의 개인정보가 침해되지
              않도록 기술적·관리적 보호조치를 취합니다.
            </li>
            <li>
              회사는 제3항에 기재된 콘텐츠 활용의 구체적 범위, 방법, 목적 등에
              관한 사항을 이용자에게 사전 고지하고 개별 동의를 받아야 합니다.
              고지 및 동의 방법은 회사가 별도로 정한 바에 따릅니다.
            </li>
          </ul>

          <br />

          <h2>제12조 (콘텐츠 저작권)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              사용권 구매회원은 라이선스 규정에 따라 허용된 용도 및 기간의 범위
              내에서 콘텐츠를 사용할 수 있는 권한을 갖는 것뿐이며, 저작권을 양도
              받는 것은 아닙니다.
            </li>
            <li>
              “스탠바이”에서 제공되는 콘텐츠의 무단 복제, 무단 사용, 배포 또는
              회사와 사전협의 없이 사이트에 게시된 “콘텐츠 라이선스 규정”을
              벗어나는 행위에 대해서는 저작권법 및 관련법률에 의한 권리 침해로
              간주됩니다. 따라서 회원은 이 약관과 라이선스 규정에서 명시적으로
              허용하는 사용 이외의 이미지 사용으로 인하여 발생하는 모든 종류의
              손해 또는 책임에 대해 회사와 제3자에게 손해를 배상하여야 합니다.
              또한 제3자의 청구에 대해서는 자신의 비용으로 회사를 면책시켜야
              합니다.
            </li>
            <li>
              사이트에서 제공되는 일부 콘텐츠(인물, 건물, 장소)에 대한 초상권,
              저작권, 상표권, 특허권(이하 ‘저작권 등’)은 회사가 보유하지 않은
              것이 있을 수 있습니다(예: 인물의 초상권,
              로고/심벌/캐릭터/트레이드마크/서비스마크 등의 상표권, 건물의
              재산권 등). 따라서 “스탠바이”에서 제공되는 일부 콘텐츠는 사용
              형태에 따라서 회원이 사용 전에 직접 권리자로부터 해당 권리를
              취득해야 합니다. 사용 시 저작권 등에 대한 권리의 존재가 의심이
              되는 일부 콘텐츠에 대해서는 사전에 회사에 문의하여야 합니다. 만일
              회사와 사전협의 없이 사용하여 제3자와 이들 권리에 대한 분쟁이
              발생할 경우 회사는 일체의 책임을 지지 않으며, 모든 책임은 회원에게
              있습니다.
            </li>

            <li>
              회사의 사용중지 고지에도 불구하고 기존 제작물에 사용된 콘텐츠를
              수정 또는 삭제하지 않고 계속 사용해서 분쟁이 발생할 경우 회사는
              일체의 책임을 지지 않으며, 모든 책임은 회원에게 있습니다.
            </li>

            <li>
              회사는 다음과 같은 내용이 포함된 콘텐츠의 업로드를 금지합니다.
            </li>
            <ul>
              <li>
                1) 제삼자의 저작권, 기타 지적재산권, 초상권 등을 침해하는 내용
              </li>

              <li>
                2) 음란물, 명예 훼손 또는 그 외 불법적이거나 비도덕적인 콘텐츠가
                포함된 내용
              </li>

              <li>3) 불법적이거나 폭력적인 행동을 묘사하는 내용</li>

              <li>4) 동물 학대 또는 동물에게 폭력을 행사하는 내용</li>

              <li>5) 사기성 광고 또는 불공정 경쟁을 일으키는 내용</li>

              <li>6) 상표, 디자인 마크, 사업자명, 로고 일부가 포함된 내용</li>
            </ul>

            <li>
              회사는 초상권, 재산권, 지적재산권 등의 권리가 침해된 콘텐츠의
              업로드를 금지합니다.
            </li>

            <li>
              위와 같은 문제로 콘텐츠에 문제가 발생할 경우, 관련된 모든 책임은
              콘텐츠를 업로드한 회원에게 있으며, 회사는 어떠한 책임도 부담하지
              않습니다.
            </li>

            <li>
              허위의 정보를 입력하거나 약관을 충분히 숙지하지 않고 콘텐츠를
              업로드하여 문제가 발생한 경우, 관련된 모든 책임은 콘텐츠를
              업로드한 회원에게 있으며, 회사는 어떠한 책임도 부담하지 않습니다.
            </li>
          </ul>

          <br />

          <h2>제13조 (콘텐츠 라이선스 규정의 준수)</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원은 “스탠바이”에서 정한 “이용약관”을 숙지하여야 하며,
              회원가입기간과 각 회원 별 라이선스 (이용허락) 범위 안에서
              이용하여야 합니다.
            </li>
            <li>
              회원이 회사와 사전협의 없이 “이용약관 규정”(이용허락 범위)을
              벗어나는 용도로 사용할 경우, 다운로드 받은 콘텐츠에 대하여 저작권
              보호를 받을 수 없으며 적발 시 형사처벌이나 손해배상의 의무를 지게
              됩니다.
            </li>
          </ul>

          <br />

          <h2>제14조 (사용권 콘텐츠 이용 제한사항)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              사이트에서 제공되는 콘텐츠는 광고와 홍보를 위한 '디자인 요소'로만
              사용해야 하며, 제공하는 콘텐츠의 전부 또는 일부를 활용하여 특정
              회사의 상품으로 오인시키거나 콘텐츠 상의 인물 (모델)이 특정 상품의
              효용이나 품질을 보증하는 것처럼 사용하는 것을 금합니다.
            </li>
            <li>
              모든 콘텐츠에 대하여 변경, 모방, 가공 등의 방법으로 재사용 및
              재배포, 재판매할 수 없으며 어떠한 경우에도 권리를 주장할 수
              없습니다.
            </li>
            <li>
              회원은 콘텐츠를 외부에서 다운로드할 수 있도록 유무선의 온라인
              네트워크나 휴대전화로 배포할 수 있는 형태로 업로드해서는 안
              됩니다. P2P, 블로그, 카페, 콘텐츠 공유 네트워크, SNS, 온라인
              클라우드 시스템 등에 업로드하거나 웹사이트 내 자료실 형태로 보관할
              수 없고 커뮤니티, SNS 등에 스크랩할 수 있도록 게재하는 것을
              금합니다. 또한, 어떠한 경우에도 제3자에게 ID와 비밀번호를 제공하여
              콘텐츠 사용을 공유할 수 없습니다.
            </li>

            <li>
              회원은 어떠한 경우에도 회원에게 부여되는 콘텐츠 사용에 대한 권리를
              타인에게 판매, 재판매, 배급, 양도, 대여, 임대, 임차, 복제, 송신
              (메신저, 웹하드, FTP, 네트워크 등) 하는 행위를 금합니다.
            </li>

            <li>
              다운로드한 콘텐츠를 전시회, 공모전, 행사 등의 엠블럼, 로고, 심벌,
              트레이드마크, 서비스마크, 상표, 캐릭터 등의 상징물로 사용할 수
              없습니다.
            </li>

            <li>
              동양 인물 콘텐츠는 사회 미풍양속을 저해하는 용도 (성인오락실,
              전화방, 음란물, 성인 채팅사이트, 성인 커뮤니티 등의 성인 사이트 및
              인쇄물, 성인 제품, 유흥업소 및 숙박업소, 고리대금업, 운세상담,
              사주풀이 등의 풍속업 등)와 채팅 당사자로 오인할 수 있는 인물사진,
              비뇨기과, 성형외과, 산부인과에서 사용하는 것을 금합니다. 또한,
              특정 제품이나 서비스를 모델이 보증하는 형식의 과대광고 등에
              사용하거나, 모델의 신체, 얼굴 등과 제3자의 사진을 합성하여
              재가공하는 행위 등을 금합니다.
            </li>

            <li>
              회원은 외설적인, 중상 모략적인, 음성적인, 타인을 비방하는 용도
              또는 기타 어떠한 비합법적인 용도로 사이트에서 제공한 콘텐츠를
              사용하거나 다른 이가 사용하게 할 수 없습니다.
            </li>

            <li>
              회원 기간이 만료된 이후에는 회원 기간 동안 다운로드한 콘텐츠를
              삭제해야 합니다. 그리고 회원 기간 동안 다운로드한 콘텐츠를
              이용하여 새로운 저작물을 만들어서는 안 됩니다.
            </li>

            <li>
              다운로드한 콘텐츠 및 그의 2차 저작물에 대하여 지적재산권의 등록을
              금합니다. 2차 저작물을 만들기 위해서는 별도의 협의가 필요합니다.
            </li>

            <li>
              저작물을 납품 시 납품된 저작물의 원본 소스는 납품할 수 없습니다.
              클라이언트가 원본 소스를 이용하여 2차 저작물을 제작할 경우 그로
              인한 모든 책임은 납품한 회원에게 있습니다.
            </li>
          </ul>

          <br />

          <h2>제15조 (수익금 현금 전환)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원이 스탠바이 내 콘텐츠 판매를 통해 획득한 수익금 5,000원 이상인
              경우에 한하여 현금 전환을 신청할 수 있습니다.
            </li>
            <li>현금 전환 시 적용되는 비율은 원화를 기준으로 합니다.</li>
            <li>현금 전환 시 현금 기준 수수료 33.3%가 발생합니다.</li>

            <li>
              현금 전환 신청이 접수되면 회사는 신청일로부터 30일 이내에 회원이
              지정한 은행계좌로 해당 금액을 입금하는 것으로 적법한 지급 의무를
              이행한 것으로 봅니다.
            </li>
            <li>
              현금 전환으로 발생하는 제세공과금 및 송금 수수료 등 부대비용은
              회원이 부담하는 것 을 원칙으로 합니다.
            </li>
          </ul>

          <br />

          <h2>제16조 (구매완료 금액의 환불)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>회원은 다음 각 호의 경우 환불을 할 수 없습니다.</li>

            <ol>
              <li>- 이미 구매를 완료한 콘텐츠</li>
              <li>- 결제시스템(PG)의 오류인 경우</li>
            </ol>
            <li>
              환불 요청이 접수되면 회사는 환불 승인 후 3영업일 이내에 환불
              처리를 완료해야 합니다. 미성년자가 법정대리인 동의 없이 계약을
              체결한 경우, 미성년자 또는 법정대리인이 이를 취소할 수 있습니다.
            </li>

            <li>
              콘텐츠 구매의 경우 결제일로부터 7일 이내 결제취소시 전액
              환불됩니다. 그러나 결제 후 유료 콘텐츠 를 다운로드하였거나 7일이
              경과한 경우에는 취소할 수 없습니다.
            </li>

            <li>
              디지털 콘텐츠의 특성상 구매한 콘텐츠에 대해서는 환불이
              불가능합니다. 회사는 청약 철회 불가 콘텐츠에 관하여
              콘텐츠산업진흥법 제27조 제1항, 전자상거래 등에서의 소비자 보호에
              관한 법률 제17조 제2항, 제6항에 따라 청약철회 불가 콘텐츠라는
              사실을 명시 할 것입니다.
            </li>
          </ul>

          <br />

          <h2>제17조 (서비스의 제공 및 제한)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 제5조의 규정에 따라 이용계약이 완료된 회원에게 그 즉시
              서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의
              필요에 따라 지정된 일자부터 서비스를 개시할 수 있습니다.
            </li>
            <li>
              회사는 회원에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를
              포함하여 기타 부가적인 서비스 를 함께 제공할 수 있습니다.
            </li>
            <li>
              회사의 정책에 따라 이용자를 구분하여 서비스 이용범위나 혜택, 또는
              서비스 사용료 징수 등을 세분하여 이용에 차등을 둘 수 있습니다.
            </li>
          </ul>

          <br />

          <h2>제18조 (서비스의 변경 및 중단)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라
              서비스를 변경할 수 있으 며, 변경 전에 해당 내용을 서비스 내에
              공지합니다. 다만, 버그.오류 등의 수정이나 긴급 업데 이트 등
              부득이하게 변경할 필요가 있는 경우 또는 중대한 변경에 해당하지
              않는 경우에는 사후에 공지할 수 있습니다.
            </li>
            <li>
              회사는 영업양도.분할.합병 등에 따른 영업의 폐지, 서비스제공의
              계약만료, 당해 서비스의 현저 한 수익 악화 등 경영상의 중대한
              사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할
              수 있습니다. 이 경우 중단일자 30일 이전까지 중단일자.중단사유 등을
              애플리케이션 등 초기화면 또는 그 연결화면을 통해 공지하고 제27조의
              방법으로 회원에게 통지합니다.
            </li>
          </ul>

          <br />

          <h2>제 19조 (서비스 이용제한)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 회원이 “스탠바이” 이용과 관련하여 제25조 (회원의 의무) 또는
              제20조 (콘텐츠 이용 제한사항)의 내용을 위반하는 행위를 하여서는 안
              되며, 해당 행위를 하는 경우에 회사는 다음 각 호의 구분에 따른
              서비스 이용제한, 관련 정보(글, 사진, 영상 등) 삭제 및 기타의
              조치를 포함한 이용제한 조치를 할 수 있습니다. 이용제한 조치가
              이루어지는 구체적인 사유 및 절차는 제18조 제1항에 따라 개별
              서비스의 운영정책에서 정합니다.
            </li>

            <ul>
              <li>
                가. 일부 권한 제한: 일정 기간 각 채널에서의 일정 권한을 제한
              </li>
              <li>
                나. 계정 이용제한 : 일정 기간 또는 영구히 회원 계정의 이용을
                제한
              </li>
              <li>
                다. 회원 이용제한 : 일정 기간 또는 영구히 회원의 서비스 이용을
                제한
              </li>
            </ul>

            <li>
              제1항의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이
              입은 손해를 배상하지 않습니다.
            </li>

            <li>
              회사는 다음 각 호의 사유에 대한 조사가 완료될 때까지 해당 계정의
              서비스이용을 정지할 수 있습니다.
            </li>

            <ul>
              <li>
                가. 계정이 해킹 또는 도용 당했다는 정당한 신고가 접수된 경우
              </li>
              <li>나. 불법프로그램 사용자 등 위법행위자로 의심되는 경우</li>
              <li>
                다. 이 계약 제27조의 위반자로 의심되거나 다른 회원의 신고가
                접수된 경우
              </li>
              <li>
                라. 그 밖에 각 호에 준하는 사유로 서비스 이용의 잠정조치가
                필요한 경우
              </li>
            </ul>

            <li>
              회사는 회원이 제공하는 정보, 자료에 대하여 회사의 공지, 서비스
              이용안내에서 정한 바에 따라 일정한 게재기한 및 용량을 정할 수
              있습니다.
            </li>
          </ul>

          <br />

          <h2>제20조 (이용제한 조치의 사유와 절차)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 제12조 제1항에 따른 이용제한 조치의 구체적인 사유 및 절차를
              제22조 제1항에 따른 금지행위의 내용․정도․횟수․결과 등을 고려하여
              운영정책으로 정합니다.
            </li>
            <li>
              회사가 제12조 제1항에서 정한 이용제한 조치를 하는 경우에는 다음 각
              호의 사항을 회원에게 사전 통지합니다. 다만, 긴급히 조치할 필요가
              있는 경우에는 사후에 통지할 수 있습니다.
            </li>

            <ul>
              <li>가. 이용제한 조치의 사유</li>
              <li>나. 이용제한 조치의 유형 및 기간</li>
              <li>다. 이용제한 조치에 대한 이의신청 방법</li>
            </ul>
          </ul>

          <br />

          <h2>제21조 (이용제한 조치에 대한 이의신청 절차)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원이 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의
              통지를 받은 날부터 14일 이내 에 불복 이유를 기재한 이의 신청서를
              서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하 여야
              합니다.
            </li>
            <li>
              회사는 제1항의 이의신청서를 접수한 날부터 15일 이내에 불복 이유에
              대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변합니다. 다만,
              회사는 이 기간 내에 답변이 어려운 경우에는 그 사유와 처리일정을
              통지합니다.
            </li>
            <li>
              회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.
            </li>
          </ul>

          <br />

          <h2>제22조 (회원 탈퇴 및 자격 상실 등)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시
              회원탈퇴를 처리합니다. 회원이 다음 각 호의 사유에 해당하는 경우 및
              제25조 의 회원의 의무 위반에 해당하는 경우 회사는 즉시 회원자격을
              제한 및 정지시킬 수 있습니다. 이때, 회원은 가입비의 반환을 청구할
              수 없으며, 회사가 손해를 입은 경우 그 손해까지 배상해야 합니다.
            </li>

            <ul>
              <li>
                1) 가입 신청 시에 허위 내용을 등록한 경우 (단, 타인의 ID도용의
                경우 제7조 제2항에 따릅니다.)
              </li>
              <li>
                2) 다른 사람의 “스탠바이” 이용을 방해하거나 그 정보를 도용하는
                등 전자거래질서를 위협하는 경우
              </li>
              <li>
                3) “스탠바이”를 이용하여 법령과 이 약관이 금지하거나 공서양속에
                반하는 행위를 하는 경우
              </li>
              <li>
                4) 제16조 제9항의 제4호 중 회사와 계약한 이용범위와 관련한
                라이선스 규정을 위반한 경우에는 회사는 회원에게 전자우편(E-Mail)
                등을 통해 추가 계약 또는 시정을 요구할 수 있으며, 7일 이내에
                추가 계약 또는 시정이 이루어지지 않는다면 즉시 회원자격을 제한
                및 정지시킬 수 있습니다.
              </li>
            </ul>

            <li>
              회사가 회원 자격을 제한/정지시킨 후, 동일한 행위가 2회 이상
              반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는
              회원자격을 상실시킬 수 있습니다.
            </li>
            <li>
              회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이
              경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를
              부여합니다.
            </li>
            <li>
              회사는 회원 탈퇴 시에도 회원 정보와 다운로드 기록은 저작권 보호를
              위하여 회사에 암호화하여 보관합니다.
            </li>
          </ul>

          <br />
          <h1>제3장 회사와 회원의 의무</h1>

          <br />

          <h2>제23조 (회사의 의무)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지
              않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
              재화/용역을 제공하는 데 최선을 다하여야 합니다.
            </li>
            <li>
              회사는 개인정보 보호를 위해 보안시스템을 구축하며
              "개인정보처리방침"을 공시하고 준수합니다.
            </li>
            <li>
              회사가 제공하는 “스탠바이” 서비스는 회사의 업무상 또는 기술상
              특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다.
              다만, 아래의 사항이 발생할 경우 회사는 부득이하게 “스탠바이”
              서비스를 한시적으로 중단할 수 있습니다.
            </li>

            <ul>
              <li>
                1) 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한
                날이나 시간에 서비스를 일시적으로 중단할 수 있으며, 예정되어
                있는 작업으로 인한 서비스 일시 중단은 사이트를 통해 사전에
                공지합니다.
              </li>
              <li>
                2) 회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로
                인하여 예고 없이 일시적으로 서비스를 중단할 수 있습니다. 또한
                새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유가 있을
                경우에는 사이트에 사전 공지 후 현재 제공되는 서비스를 일시적으로
                완전히 중단 할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을
                회원에게 사전 또는 사후에 공지합니다.
              </li>
              <li>
                3) 회사는 국가비상사태, 정전, 서비스의 전부 또는 일부를
                제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을
                회원에게 사전 또는 사후에 공지합니다.
              </li>
            </ul>
          </ul>

          <br />

          <h2>제24조 (회원의 ID 및 비밀번호에 대한 의무)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원의 ID, 비밀번호의 관리 책임은 이용회원에게 있으며, ID를
              타인에게 대여하거나 유출해서는 안 됩니다. ID, 비밀번호 관리소홀로
              인한 ID 유출, 제3자의 부정사용 등의 책임은 회원에게 있으며, 법률적
              책임을 져야 합니다.
            </li>
            <li>
              회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고
              있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는
              경우에는 그에 따라야 합니다.
            </li>
          </ul>

          <br />

          <h2>제25조 (회원의 의무)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원은 가입 신청 또는 회원정보 변경 시 모든 사항을 사실대로
              작성하여야 합니다. 만일 허위의 사실이나 타인의 정보를 이용하여
              등록한 경우에는 일체의 권리를 주장하거나 보호받을 수 없습니다.
            </li>
            <li>
              회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 “콘텐츠
              라이선스 규정” 및 회사가 공지하는 사항을 준수하여야 합니다. 위반
              시 회원은 회원자격 정지 및 손해배상 등의 불이익 조치를 받을 수
              있습니다.
            </li>
            <li>
              회원은 주소, 연락처, 전자우편주소 등 이용계약사항이 변경된 경우에
              해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다. 특히
              “스탠바이”에서 제공하는 콘텐츠에 저작권 관련 문제가 발생 시 이를
              홈페이지 또는 e-mail을 통해 공지하므로 항상 회원(담당자)의 정확한
              e-mail을 제공하여야 합니다.
            </li>
            <li>
              회원은 서비스의 이용권한, 계약상의 지위 등을 타인에게 양도, 증여할
              수 없으며 이를 담보로 제공할 수 없습니다.
            </li>

            <li>
              회원은 어떠한 경우에도 회원 이외의 제 3자에게 ID와 비밀번호를
              제공하여 콘텐츠 사용을 공유할 수 없습니다.
            </li>

            <li>
              사용권을 구매한 콘텐츠는 기간 중 다운로드 받은 콘텐츠는 사용기간이
              종료된 이후 어떠한 이유와 상관없이 사용할 수 없습니다. 기간 만료
              후 콘텐츠를 사용하는 것은 저작권 침해입니다.
            </li>

            <li>
              회원이 구매한 사용권은 회사가 정한 “콘텐츠 라이선스 규정”에 따라
              사용이 가능 합니다.
            </li>

            <li>
              회원은 기간을 연장하거나 회원타입을 변경(전환)할 시 반드시
              이용약관과 “콘텐츠 라이선스 규정”을 숙지하여 변동사항이 없는지
              확인하여야 합니다. 이때 회원은 연장 혹은 전환하는 시점의 “서비스
              이용약관” 및 “콘텐츠 라이선스 규정”에 따라야 합니다. (필요한 경우
              사이트 담당자와의 상담 및 견적 절차를 받으실 수 있습니다.)
            </li>

            <li>
              회원은 다음 각 호에 해당하는 행위를 하여서는 안 되며, 해당 행위를
              하는 경우에 회사는 회원의 서비스 이용 제한 및 적법 조치를 포함한
              제재를 가할 수 있습니다.
            </li>

            <ul>
              <li>
                1) 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용
                외의 목적으로 복제하거나, 제3자에게 제공하는 행위
              </li>
              <li>
                2) 서비스를 통해 얻은 콘텐츠를 공공질서 및 미풍양속에 위반되는
                저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시,
                전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
              </li>
              <li>3) 범죄와 결부된다고 객관적으로 판단되는 행위</li>
              <li>
                4) 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용조건을
                위반하는 행위
              </li>
              <li>5) 서비스에 위해를 가하거나 고의로 방해하는 행위</li>
              <li>
                6) 비정상적 다운로드 행위(지속적인 일정 간격의 초단위 다운로드,
                라이선스별 사용 목적을 현저히 벗어난 대량 수집보관형 다운로드 등
                정상적인 허용행위로 볼 수 없는 행위 등)
              </li>
              <li>
                7) 이용신청 또는 회원 정보 변경 시 허위사실을 기재하거나, 타인의
                명의로 본인인증을 수행하거나, 다른 회원의 계정 또는 타인의
                결제수단을 도용, 부정하게 사용하는 행위{" "}
              </li>
              <li>
                8) 회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여
                글을 게시하거나 메일, 가. , 타인으로 가장하거나 타인과의 관계를
                허위로 명시하는 행위거나 다른 이용자에게 혼란을 초래하는 행위{" "}
              </li>
              <li>
                9) 회사나 타인의 지적재산권, 초상권 등 기타 권리를 침해하는
                행위, 타인의 명예를 훼손하거나 손해를 가하는 행위, 타인을 희롱
                또는 위협하거나 고통, 피해, 또는 불편을 주는 행위
              </li>
              <li>10) 기타 관계법령에 위배되는 행위</li>
              <li>11) 기타 회사의 업무에 방해되는 행위</li>
            </ul>

            <li>
              회사는 회원의 콘텐츠 사용에 관한 권리 보호를 위해 회사 또는 회사의
              법률대리인이 회원에게 해당 콘텐츠 사용에 관한 구입경로 및 사용경위
              등의 확인을 요청할 수 있으며, 이에 대해 회원은 성실히 답변하여야
              합니다.
            </li>
          </ul>

          <br />

          <h2>제26조 (개인정보 보호 및 사용)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 관계법령에 의거하여 회원 등록정보를 포함한 이용자의
              개인정보를 보호하기 위해 노력하며, 이용자 개인정보의 보호 및
              사용에 대해서는 관련법령 및 회사의 “개인정보 취급방침”이
              적용됩니다. 그러나 이용 회원의 귀책 사유로 인해 노출된 정보에
              대해서는 일체의 책임을 지지 않습니다.
            </li>
          </ul>

          <br />
          <h1>제4장 계약해지, 손해배상 및 면책조항 등</h1>

          <br />

          <h2>제27조 (계약 해지 등) </h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원은 언제든지 회원 탈퇴를 통해 이용계약을 해지할 수 있습니다.
              회원탈퇴로 인해 회원이 서비스 내에서 보유한 이용정보는 모두
              삭제되어 복구가 불가능하게 됩니다.
            </li>
            <li>
              회사는 회원이 이 약관 및 그에 따른 운영정책, 서비스 정책에서
              금지하는 행위를 하는 등 본 계약을 유지할 수 없는 중대한 사유가
              있는 경우에는 상당한 기간 전에 최고하고 기간을 정하여 서비스
              이용을 중지하거나 이용계약을 해지할 수 있습니다.
            </li>
            <li>
              회사는 최근의 서비스 이용일부터 연속하여 1년 동안 회사의 서비스를
              이용하지 않은 회원(이하 “휴면계정”이라 합니다)의 개인정보를
              보호하기 위해 이용계약을 해지하고 회원의 개인정보 파기 등의 조치를
              취할 수 있습니다. 이 경우 조치일 30일 전까지 계약해지, 개인정보
              파기 등의 조치가 취해진다는 사실 및 파기될 개인정보 등을 회원에게
              통지합니다.
            </li>
          </ul>

          <br />

          <h2>제28조 (손해배상) </h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌
              경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의 또는 과실이
              없는 경우에는 그러하지 아니합니다.
            </li>
            <li>
              “스탠바이”에서 제공되는 콘텐츠의 무단 복제, 무단 사용 또는 본
              약관에 규정된 내용을 벗어나는 행위(사용범위 초과 사용 등)에
              대해서는 저작권법 및 관련 법률에 의한 권리 침해에 해당하며, 이로
              인하여 회사에 손해가 발생하는 경우 유료사용요금(정상가)을 기준으로
              하여 회사에게 발생한 손해를 배상하여야 하며, 회사가 이를 초과하는
              손해를 입은 경우 그 초과 손해도 배상하여야 합니다.
            </li>
          </ul>

          <br />

          <h2>제 29조 (면책조항) </h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 통신판매중개자로서 회원 간의 자유로운 상품 등의 거래를 위한
              시스템을 운영 및 관리, 제공할 뿐 회원을 대리하지 않으며, 회원
              사이에 성립된 거래와 관련된 책임과 회원이 제공한 정보에 대한
              책임은 해당 회원이 직접 부담하여야 합니다.
            </li>
            <li>
              회사는 회원이 등록한 콘텐츠 등의 내용과 지적재산권 보유 및 적법성,
              거래조건에 대해서 어떠한 보증이나 대리를 하지 않으며, 회원은
              본인의 위험과 책임 하에 상품 등을 구매해야 합니다.
            </li>
            <li>
              회사는 회원 간의 모든 행위(거래행위 포함)와 관련하여 판매의사 또는
              구매의사의 존부 및 진정성, 등록 콘텐츠의 품질, 완전성, 안정성,
              적법성 및 타인의 권리에 대한 비침해성, 회원이 입력하는 정보 및 그
              정보를 통하여 연결된 웹사이트에 게재된 자료의 진실성 또는 적법성
              등에 대하여 보증하지 아니하며, 이와 관련된 위험과 책임은 해당
              당사자들이 부담합니다.
            </li>
            <li>
              회사는 회원이 본인의 개인정보를 타인에게 유출하거나 제공하여
              발생하는 피해에 대하여 책임을 부담하지 아니합니다.
            </li>
            <li>
              회사는 천재지변, 컴퓨터 등 정보통신설비의 보수, 점검, 교체, 고장
              및 통신두절 등의 사유가 발생한 경우에는 서비스 제공을 일시적으로
              중단할 수 있으며, 이와 관련하여 고의 또는 과실이 없는 한 어떠한
              책임도 부담하지 아니합니다.
            </li>
            <li>
              회사의 고의 또는 중과실로 인한 손해에 대해서는 제1항 내지 제5항에
              불구하고 회사가 책임을 부담합니다.
            </li>
            <li>
              회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로
              제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
            </li>

            <li>
              회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한
              사유로 발생한 손해에 대한 책임이 면제됩니다.
            </li>
            <li>
              회원들의 접속 폭주로 인한 서비스 속도의 지체나 일시적 오류 그리고
              회사의 서비스개선을 위한 정비 등으로 인해 서비스가 일시 중단된
              경우에 대해서는 환급 또는 배상하지 않습니다.
            </li>
            <li>
              회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여
              회사는 책임이 없습니다.
            </li>
            <li>
              회사는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가
              발생한 경우 또는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우에는
              책임을 지지 않습니다.
            </li>
            <li>
              회사에서 서비스하는 저작물에 대해 제 3자가 사용 중지 및 저작권
              침해 소송을 제기하는 경우, 회사는 즉시 사실관계를 확인한 후 해당
              콘텐츠의 삭제 조치를 한 후 사이트에 기재하여 이를 회원들에게
              고지합니다. 회원은 즉시 공지된 해당 콘텐츠의 사용을 중지하여야
              하며, 이전에 제작된 제작물에도 소급하여 해당 콘텐츠를 수정 또는
              삭제조치를 하여야 합니다. 공지된 이후에도 해당 콘텐츠를 계속
              사용하여 문제가 발생할 경우에는 회사는 책임을 지지 않습니다.
            </li>
            <li>
              회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는
              어떠한 손해도 책임을 지지 않습니다.
            </li>
            <li>
              회사에서 회원에게 제공하는 콘텐츠의 키워드 정보는 검색의 편의를
              위한 참고자료로 제공되는 것이며, 정보의 정확성이나 신뢰도 등의
              내용에 대해서는 책임을 지지 않습니다.
            </li>
          </ul>

          <br />

          <h2>제30조 (정보의 제공)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에
              대해서 전자우편이나 서신우편 또는 sms(문자메시지) 등의 방법으로
              회원에게 제공할 수 있습니다.
            </li>
            <li>
              회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의
              동의하에 추가적인 정보를 요구할 수 있습니다.
            </li>
            <li>
              회사에서 제공하는 서비스를 통하여 거래가 성사되었을 때 회사는
              회원에게 보내는 물품의 배송 및 온라인상의 서비스를 제공하기 위하여
              필요한 범위 내에서 회원의 개인정보를 외부전문업체에게 제공할 수
              있습니다.
            </li>
          </ul>

          <br />

          <h2>제31조 (분쟁해결)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를
              보상 처리하기 위하여 고객센터를 설치/운영합니다.
            </li>
            <li>
              회사는 이용자로부터 제출되는 불만사항 및 의견 사항을 우선적으로
              처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그
              사유와 처리일정을 통보해 드립니다.
            </li>
            <li>
              회사와 이용자간에 발생한 분쟁은 전자거래기본법 제32조 및 동 시행령
              제19조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수
              있습니다.
            </li>
          </ul>

          <br />

          <h2>제32조 (재판권 및 준거법)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사와 이용자간에 발생한 전자거래 분쟁에 관한 소송은
              서울중앙지방법원에 제기합니다.
            </li>
            <li>
              회사와 이용자간에 제기된 전자거래 소송에는 국내법을 적용합니다.
            </li>
          </ul>

          <br />

          <h2>제33조 (회원에 대한 통지)</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사가 회원에 대한 통지를 하는 경우, 회원이 회사에 제출한 전자우편
              주소 또는 SMS(휴대폰 문자 메시지)로 할 수 있습니다. 따라서 회원은
              전자우편 주소가 변경될 경우 반드시 회원 본인이 “스탠바이”에 있는
              [마이페이지]에서 직접 변경해야 합니다.
            </li>
            <li>
              회사는 불특정다수 회원에 대한 통지의 경우 일주일 이상 “스탠바이”에
              게시하며 이는 개별통지와 같은 효력으로 간주합니다. 다만, 회원
              본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
              전자우편으로 개별통지를 합니다.
            </li>
            <li>
              회사는 제2항의 기재방법에 의해 회원에게 통지함으로써, 회원에 대한
              모든 통지 의무를 다한 것으로 봅니다. 제공 콘텐츠에 대한 제 3자의
              저작권 침해 주장 등 권리 침해가 문제 되었을 경우에도 같습니다.
            </li>
          </ul>

          <br />

          <h2>부칙</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>본 약관은 2024년 10월 28일부터 적용됩니다.</li>
            <li>
              종전 약관에 귀속하여 가입을 한 회원에 한해서는 남은 회원기간 동안
              종전약관에 따른 서비스를 받을 수 있습니다. 단, 본 약관을 적용받기
              위한 회원은 회사가 정하는 절차에 의해 변경할 수 있습니다.
            </li>
          </ul>
        </div>
      </Content>
    </Modal>
  );
}
