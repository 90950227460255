import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const payDetailList = async (payOrderId: any) => {
  const url = `${API_DEFAULT}/user/payment/list/detail`;
  const response = await instance
    .get(url, {
      params: { orderId: payOrderId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const handlePayCancel = async (payload: any) => {
  const url = `${API_DEFAULT}/user/payment/cancel`;
  const response = await instance
    .post(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const payDetailInfo = async (payOrderId: any) => {
  const url = `${API_DEFAULT}/user/payment/detail?orderId=${payOrderId}`;
  const response = await instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
