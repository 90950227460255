import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import { setLocationList } from "../../../store/reducer/locationSlice";
import Text from "../../../components/design-system/Text";
import StockListCard from "../../feed/stock-list/StockListCard";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { instance } from "../../../api";
import { API_DEFAULT } from "../../../api/api";
import { useMediaQuery } from "react-responsive";

export default function LocationSideView() {
  const themeData = useAppSelector((state) => state.theme.data);
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [contentId, setContentId] = useState<number | null>(null);
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const [itmes, setItems] = useState<any>([]);

  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: 36.507468472827576,
      latMax: 38.60617845599798,
      lonMin: 125.24388600000003,
      lonMax: 128.75951100000003,
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  return (
    <div className={`${isMobile ? "mt-[20px]" : "mt-[105px]"}`}>
      <JustifiedInfiniteGrid
        // className="container"
        placeholder={
          <div className="placeholder">추가 데이터를 불러오는 중...</div>
        }
        // stretch={true}
        // passUnstretchRow={true}
        // sizeRange={[120, 120]}
        // stretchRange={[144, 320]}
        columnRange={isMobile ? [0, 2] : [0, 1]}
        threshold={3000}
        gap={8}
        onRequestAppend={(e) => {
          const nextGroupKey = (+e.groupKey! || 0) + 1;
          // 같은 그룹키는 무시
          if (nextGroupKey === groupKey) {
            return;
          }
          setGroupKey(nextGroupKey);
          e.wait();

          setTimeout(async () => {
            const add = await getItems(nextGroupKey, 20);
            if (add.length === 0) {
              e.ready();
              setContentId(null);
              return true;
            }

            dispatch(setLocationList({ data: add, reset: false }));
            setItems([...itmes, ...add]);
            e.ready();
          }, 1);
        }}
      >
        {itmes?.length < 1 ? (
          <div className="flex flex-col items-center justify-center">
            <Text className="subtitle-2 dark:text-darkText">
              조회된 피드가 없습니다.
            </Text>
            <Text className="label-1 dark:text-darkText">
              필터를 사용해서 다시 조회 해주세요!
            </Text>
          </div>
        ) : (
          itmes?.map((item: any, index: any) => (
            <StockListCard
              data-grid-groupkey={item.groupKey}
              key={index}
              index={index}
              item={item}
              onClick={undefined}
            />
          ))
        )}
      </JustifiedInfiniteGrid>
    </div>
  );
}
