import { Switch } from "@material-tailwind/react";

export function SwitchButton({ id, value, onClick, onChange }: any) {
  return (
    <Switch
      id={id}
      ripple={false}
      className="h-full w-full checked:bg-[#134B70]"
      containerProps={{
        className: "w-11 h-6",
      }}
      circleProps={{
        className: "before:hidden left-0.5 border-none",
      }}
      crossOrigin={undefined}
      value={value}
      onClick={onClick}
      onChange={onChange}
      defaultChecked={value}
    />
  );
}
