import React, { useEffect, useState } from "react";
import { useContext } from "../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import { setDetail } from "../../store/reducer/feedSlice";
import { useLocation } from "react-router-dom";
import AssetsMode from "../../components/feed/modal/AssetsMode";
import FeedContent from "../../components/feed/modal/FeedContent";
import SellerInfo from "../../components/feed/modal/SellerInfo";
import FeedComment from "../../components/feed/modal/FeedComment";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../api";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { FadeLoader } from "react-spinners";
import Switcher from "../../components/common/Switcher";

export default function FeedInfoPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state } = useContext();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [id, setId] = useState<any>();
  const { detail } = useAppSelector((state) => state.feed);
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const data = JSON.parse(window.opener.name);
  const contentId = data.contentId;
  const [loading, setLoading] = useState(true);

  async function onFetch() {
    const payload = {
      contentId: contentId || state?.contentId,
    };

    const res = await instance.get(`${API_DEFAULT}/contents/search/detail`, {
      params: payload,
    });
    if (res?.data.success) {
      dispatch(setDetail(res?.data.data));
      setLoading(false);
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    onFetch();
  }, []);

  useEffect(() => {
    const temp = location?.pathname;
    const params = temp.split("/");
    setId(params);
  }, [location]);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (loading) {
    return (
      <div
        className={"w-full h-full flex flex-col justify-center items-center"}
      >
        <FadeLoader color={"#121212"} />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col h-full">
      <div className="hidden">
        <Switcher />
      </div>

      <div
        className={`${isMobile ? "mt-4 px-4" : "mt-[100px] mx-auto"}  flex flex-col justify-center max-w-[1280px]`}
      >
        <div className={`flex ${isMobile && "flex-col"} gap-10`}>
          <AssetsMode />
          <FeedContent
            contentId={contentId}
            showAllKeywords={showAllKeywords}
            setShowAllKeywords={setShowAllKeywords}
          />
        </div>
        <div>
          <SellerInfo detail={detail} state={contentId} />
          <FeedComment detail={detail} state={contentId} />
        </div>
      </div>

      <div
        className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
      >
        <div className="flex flex-col justify-center items-center gap-4 z-[9999]">
          {!isMobile && <FeedNav />}

          {!isMobile && (
            <IconButton
              //@ts-ignore
              className="rounded-full px-[28px] py-[28px]"
              placeholder={undefined}
              children={undefined}
              size="lg"
              onClick={moveToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}
