import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const PaginationContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props: any) => (props.isMobile ? "" : "120px")};
  margin-bottom: 10px;
`;

const PaginationButton = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  /* background-color: #f8f9fa; */
  color: #b5b5b5;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;

  /* &:hover {
    background-color: #e2e6ea;
  } */

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.active {
    background-color: inherit;
    color: #535353;
    /* border-color: #007bff; */
  }
`;

const PaginationButtonArrow = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background-color: inherit;
  color: #b5b5b5;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: #e2e6ea;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.active {
    background-color: inherit;
    color: #535353;
    /* border-color: #007bff; */
  }
`;

export function Pagination({
  postsNum,
  postsPerPage,
  setCurrentPage,
  currentPage,
}: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const pageList = [];
  const totalPages = Math.ceil(postsNum / postsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageList.push(i);
  }

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  if (totalPages === 1) {
    return null;
  }

  return (
    <PaginationContainer isMobile={isMobile}>
      <PaginationButtonArrow
        onClick={goToPrevPage}
        disabled={currentPage === 1}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </PaginationButtonArrow>

      {pageList?.map((page) => (
        <PaginationButton
          key={page}
          onClick={() => setCurrentPage(page)}
          className={currentPage === page ? "active" : ""}
        >
          {page}
        </PaginationButton>
      ))}

      <PaginationButtonArrow
        onClick={goToNextPage}
        disabled={currentPage === pageList?.length}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </PaginationButtonArrow>
    </PaginationContainer>
  );
}
