import React, { useEffect, useState } from "react";
import Text from "../../design-system/Text";
import { useAppDispatch } from "../../../store/store";
import { useQuery } from "@tanstack/react-query";
import AssetsMode from "./AssetsMode";
import InsightModal from "../../design-system/modal/InsightModal";
import { useMediaQuery } from "react-responsive";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { contentsInsight } from "../../../api/feed/feed";
import Dayjs from "dayjs";
import { FadeLoader } from "react-spinners";

export default function FeedInsightModal({ open, onClose, detail }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const { data, status } = useQuery({
    queryKey: ["insight"],
    queryFn: () => contentsInsight(detail?.contentId),
  });

  useEffect(() => {
    const images = [
      "/img/standbuy/background/insight-like-bg.svg",
      "/img/standbuy/background/insight-cart-bg.svg",
      "/img/standbuy/background/insight-coin-bg.svg",
    ];

    images.forEach((imagePath) => {
      const img = new Image();
      img.src = imagePath;
    });
  }, []);

  return (
    <InsightModal open={open} onClose={onClose} headerNone width={600}>
      {status !== "success" ? (
        <div
          className={"w-full h-full flex flex-col justify-center items-center"}
        >
          <FadeLoader color={"#121212"} />
        </div>
      ) : (
        <>
          <div
            className={`${isMobile && "mt-[50px]"} flex items-center gap-3 mb-8`}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={onClose}
              fontSize={20}
              className="cursor-pointer dark:text-darkText"
            />
            <Text
              fontSize={20}
              fontWeight={700}
              color="#1a1a1a"
              className="dark:text-darkText"
            >
              콘텐츠 인사이트
            </Text>
          </div>

          <div className="flex flex-col items-center justify-center">
            <Text
              fontSize={18}
              fontWeight={700}
              className="dark:text-darkText mb-4"
            >
              {detail?.contentName}
            </Text>

            <AssetsMode />

            <div className={"flex justify-evenly w-full mt-4 gap-2"}>
              <div className="min-w-[100px] max-h-[90px] bg-[#f5f5f5] flex justify-center items-center p-[18px] rounded-lg">
                <div className="flex flex-col items-center justify-center">
                  <Text fontSize={14} fontWeight={500} color="#363636">
                    업로드 일자
                  </Text>

                  <Text fontSize={14} fontWeight={700} color="#363636">
                    {Dayjs(data?.data.createTime).format("YYYY.MM.DD")}
                  </Text>
                </div>
              </div>

              <div
                className={`${isMobile ? "min-w-[90px]" : "min-w-[100px]"}  max-h-[90px] bg-[#f5f5f5] flex justify-center items-center p-[18px] rounded-lg`}
              >
                <div className="flex flex-col items-center justify-center">
                  <Text fontSize={14} fontWeight={500} color="#363636">
                    조회수
                  </Text>

                  <Text fontSize={14} fontWeight={700} color="#363636">
                    {data?.data.view}
                  </Text>
                </div>
              </div>

              <div
                className={`${isMobile ? "min-w-[90px]" : "min-w-[100px]"}  max-h-[90px] bg-[#f5f5f5] flex justify-center items-center p-[18px] rounded-lg`}
              >
                <div className="flex flex-col items-center justify-center">
                  <Text fontSize={14} fontWeight={500} color="#363636">
                    댓글수
                  </Text>

                  <Text fontSize={14} fontWeight={700} color="#363636">
                    {data?.data.commentCount}
                  </Text>
                </div>
              </div>
            </div>

            <div className={`mt-10 relative`}>
              <div className="absolute top-10 left-0 w-full h-full flex flex-col items-center">
                <Text fontSize={18} fontWeight={500} color="#363636">
                  좋아요
                </Text>
                <Text fontSize={24} fontWeight={700} color="#363636">
                  {data?.data.likeCount}
                </Text>
              </div>
              <img
                src="/img/standbuy/background/insight-like-bg.svg"
                alt=""
                width={450}
                loading="eager"
              />
            </div>

            <div
              className={
                "mt-5 flex flex-col items-center justify-center mb-[30px]"
              }
            >
              <Text fontSize={18} fontWeight={600}>
                내 콘텐츠를 좋아해주는 사람
              </Text>

              <div className={"flex flex-col items-center mt-2"}>
                <Text fontSize={14} fontWeight={400}>
                  당신의 콘텐츠를 좋아해주는 사람을 위해
                </Text>

                <Text fontSize={14} fontWeight={400}>
                  우리 분발해서 업로드해볼까요?
                </Text>
              </div>
            </div>

            <div className={"mt-10 relative"}>
              <div className="absolute top-10 left-0 w-full h-full flex flex-col items-center">
                <Text fontSize={18} fontWeight={500} color="#363636">
                  이용권 판매량
                </Text>
                <Text fontSize={24} fontWeight={700} color="#363636">
                  {data?.data.ticketCount}
                </Text>
              </div>
              <img
                src="/img/standbuy/background/insight-cart-bg.svg"
                alt=""
                width={450}
                loading="eager"
              />
            </div>

            <div
              className={
                "mt-5 flex flex-col items-center justify-center mb-[30px]"
              }
            >
              <Text fontSize={18} fontWeight={600}>
                지금까지 판매된 판매량이에요!
              </Text>

              <div className={"flex flex-col items-center mt-2"}>
                <Text fontSize={14} fontWeight={400}>
                  만약 지역을 설정하지 않았다면 설정해보세요
                </Text>

                <Text fontSize={14} fontWeight={400}>
                  사람들에게 더 많이 노출될거에요
                </Text>
              </div>
            </div>

            <div className={"mt-10 relative"}>
              <div className="absolute top-10 left-0 w-full h-full flex flex-col items-center">
                <Text fontSize={18} fontWeight={500} color="#363636">
                  누적 판매 수익
                </Text>
                <Text fontSize={24} fontWeight={700} color="#363636">
                  {data?.data.totalProfit ? data?.data.totalProfit : "0"}
                </Text>
              </div>
              <img
                src="/img/standbuy/background/insight-coin-bg.svg"
                alt=""
                width={450}
                loading="eager"
              />
            </div>

            <div
              className={
                "mt-5 flex flex-col items-center justify-center mb-[30px]"
              }
            >
              <Text fontSize={18} fontWeight={600}>
                수익금은 5,000원부터 이체가 가능해요
              </Text>

              <div className={"flex flex-col items-center mt-2"}>
                <Text fontSize={14} fontWeight={400}>
                  모바일 : 메뉴 / 정산
                </Text>

                <Text fontSize={14} fontWeight={400}>
                  PC : 마이페이지 / 정산
                </Text>
              </div>
            </div>
          </div>
        </>
      )}
    </InsightModal>
  );
}
