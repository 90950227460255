import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const signUp = async (data: any) => {
  const url = `${API_DEFAULT}/user/auth/sign-in`;
  const response = await instance
    .post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const signOutLogin = async (data: any) => {
  const url = `${API_DEFAULT}/user/auth/sign-out`;
  const response = await instance
    .delete(url, { data: data })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
