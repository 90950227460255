import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const thumbnailInfo = async (
  thumbnailStat: any,
  startUploadFlag: any,
) => {
  const url = `${API_DEFAULT}/contents/thumbnail-stat?jobId=${thumbnailStat}&s=${startUploadFlag}`;
  const response = await instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const onUploadEnd = async (formData: any) => {
  const url = `${API_DEFAULT}/contents/create-convert`;

  const _form = new FormData();
  for (const [key, value] of Object.entries(formData)) {
    const _key: string = key;
    const _value: any = value;
    _form.append(_key, _value);
  }

  const response = await instance
    .post(url, _form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
