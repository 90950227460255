import { Avatar } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { LabelInputLength } from "../../components/design-system/input/LabelInputLength";
import { useContext } from "../../util/useContext";
import Text from "../../components/design-system/Text";
import FillButton from "../../components/design-system/button/FillButton";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCookie, setCookie } from "../../util/cookies";
import ProfileSetModal from "./modal/ProfileSetModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProfileAvatarModal from "./modal/ProfileAvatarModal";
import { toastFetched } from "../../store/reducer/toastSlice";
import { useAppDispatch } from "../../store/store";
import ProfileNickModal from "./modal/ProfileNickModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { mySimple } from "../../api/info/info";
import { cartItemRemove } from "../../api/cart/cart";
import { onNicknameChange, onProfileImg } from "../../api/profile/profile";

export default function EditAvatarForm() {
  const dispatch = useAppDispatch();
  const { me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const queryClient = useQueryClient();
  const { form, get, post } = useAxios();
  const isInfo = getCookie("me");
  const [profileModal, setProfileModal] = useState({ show: false });
  const [profileAvatarModal, setProfileAvatarModal] = useState({ show: false });
  const [profileNickModal, setProfileNickModal] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [file, setFile] = useState<any>();
  const fileInput = useRef(null);
  const { data, status: getStatus } = useQuery({
    queryKey: ["profile-simple"],
    queryFn: mySimple,
  });

  const [image, setImage] = useState<any>();
  const [nickname, setNickname] = useState<any>();

  const handleKeyDown = (event: any) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
    }
  };

  const onChange = (e: any) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    } else {
      setImage(isInfo?.profileImage || "/img/standbuy/icon/no-profile.svg");
      return;
    }
    //화면에 프로필 사진 표시
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (data?.data?.profileImage !== null) {
      setImage(data?.data?.profileImage);
    } else {
      setImage("/img/standbuy/icon/no-profile.svg");
    }
  }, [data, file]);

  useEffect(() => {
    if (data?.data?.nickname) {
      setNickname(data?.data?.nickname);
    }
  }, [data]);

  async function onProfileImgReset() {
    const isConfirmed = window.confirm(
      "지금 프로필 이미지를 변경하면 1주일간 변경할 수 없습니다.",
    );

    if (isConfirmed) {
      try {
        const res = await onProfileImg(null);

        if (res?.success) {
          alert("프로필 이미지를 초기화하였습니다.");
          window.location.reload();
        } else {
          dispatch(
            toastFetched({
              show: true,
              text: res?.error?.message || "알 수 없는 오류가 발생했습니다.",
              type: "error",
            }),
          );
        }
      } catch (error) {
        dispatch(
          toastFetched({
            show: true,
            text: "프로필 이미지 초기화 중 오류가 발생했습니다.",
            type: "error",
          }),
        );
      }
    }
  }
  const { mutate, status } = useMutation({
    mutationFn: onProfileImg,
    onSuccess: (data) => {
      setFile(undefined);
      setProfileAvatarModal({ show: false });
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });

      if (data?.success) {
        dispatch(
          toastFetched({
            show: true,
            text: data?.data.message,
            type: "success",
          }),
        );
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          }),
        );
      }
    },
  });

  const { mutate: onNickname } = useMutation({
    mutationFn: onNicknameChange,
    onSuccess: (data) => {
      setProfileNickModal({ show: false });
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });

      if (data?.success) {
        dispatch(
          toastFetched({
            show: true,
            text: data?.data.message,
            type: "success",
          }),
        );
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          }),
        );
      }
    },
  });

  useEffect(() => {
    if (status === "pending") {
      setLoading(true);
    } else if (status === "success") {
      setLoading(false);
    }
  }, [status]);

  return (
    <>
      <div className="mt-20">
        <div className="flex flex-col items-center">
          {getStatus === "pending" ? (
            <Skeleton width={210} height={210} circle={true} />
          ) : (
            <div className={`flex ${isMobile && "flex-col"} items-center`}>
              <div
                className="relative"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                <Avatar
                  variant="circular"
                  alt="profile img"
                  src={image}
                  placeholder={undefined}
                  className={`w-[210px] h-[210px] ring-2 hover:ring-2 ring-[#134B70] hover:ring-[#396984] p-0.5 cursor-pointer ${isMobile ? "" : "ml-[80px]"} `}
                  onClick={() => {
                    //@ts-ignore
                    fileInput.current.click();
                  }}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg, image/svg, image/jpg"
                  name="profile_img"
                  onChange={onChange}
                  ref={fileInput}
                />

                {isHover && (
                  <div
                    className={`w-[210px] h-[210px] absolute top-0 left-0 rounded-full flex items-center justify-center ${isMobile ? "" : "ml-[80px]"}`}
                    style={{
                      background:
                        "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
                      pointerEvents: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={faPencil} className="text-white" />
                  </div>
                )}
              </div>

              <FillButton
                onClick={() => {
                  setProfileAvatarModal({ show: true });
                }}
                text="적용하기"
                size="lg"
                className={`flex justify-center ${isMobile ? "mt-10" : "ml-20"}`}
                loading={loading}
                disabled={
                  status === "pending" || file === undefined ? true : false
                }
              />
            </div>
          )}

          <div className={`flex ${isMobile && "flex-col"} gap-10 mt-20`}>
            <LabelInputLength
              width="340px"
              label="닉네임"
              value={nickname}
              setValue={setNickname}
              placeholder={"닉네임은 2~15글자의 한글, 영문, 숫자만 가능합니다."}
              maxLength={15}
              onKeyDown={handleKeyDown}
            />

            <FillButton
              onClick={() => {
                setProfileNickModal({ show: true });
              }}
              text="수정"
              size="lg"
              className="flex justify-center"
              loading={loading}
              disabled={status === "pending" ? true : false}
            />
          </div>
        </div>

        <div className="flex flex-col justify-center items-center my-[40px]">
          <Text
            fontSize={12}
            className={"mt-4 cursor-pointer"}
            color={"#686868"}
            fontWeight={500}
            onClick={onProfileImgReset}
          >
            프로필 기본 이미지로 변경
          </Text>
        </div>
      </div>
      {profileModal.show && (
        <ProfileSetModal
          open={profileModal.show}
          onClose={() => setProfileModal({ show: false })}
        />
      )}
      {profileAvatarModal.show && (
        <ProfileAvatarModal
          open={profileAvatarModal.show}
          onClick={() => mutate(file ? file : me?.profileImage)}
          onClose={() => setProfileAvatarModal({ show: false })}
        />
      )}

      {profileNickModal.show && (
        <ProfileNickModal
          open={profileNickModal.show}
          onClick={() => onNickname(nickname)}
          onClose={() => setProfileNickModal({ show: false })}
        />
      )}
    </>
  );
}
