import Switcher from "../../components/common/Switcher";
import { useNavigate } from "react-router-dom";
import JoinCheckForm from "./card/JoinCheckForm";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { useMediaQuery } from "react-responsive";

export default function JoinPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  return (
    <>
      {isMobile ? (
        <div className="bg-white dark:bg-black h-screen flex justify-center items-center">
          <Switcher className="hidden" />
          <div className={`w-full flex flex-col items-center gap-12`}>
            <img
              src={
                themeData === "light" || themeData === ""
                  ? "/img/standbuy/logo/logo.svg"
                  : "/img/standbuy/logo/logo-white.svg"
              }
              alt="standbuy"
              onClick={() => navigate("/")}
              className="cursor-pointer"
              width={200}
            />
            <JoinCheckForm />
          </div>
        </div>
      ) : (
        <div className="bg-gray-200 dark:bg-black h-screen flex justify-center items-center">
          <Switcher className="hidden" />
          <div
            className={`w-full flex flex-col items-center gap-12 ${isMobile && "mx-5"}`}
          >
            <img
              src={
                themeData === "light" || themeData === ""
                  ? "/img/standbuy/logo/logo.svg"
                  : "/img/standbuy/logo/logo-white.svg"
              }
              alt="standbuy"
              onClick={() => navigate("/")}
              className="cursor-pointer"
              width={200}
            />
            <JoinCheckForm />
          </div>
        </div>
      )}
    </>
  );
}
