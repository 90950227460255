import React from "react";
import Modal from "../../design-system/modal/Modal";
import Text from "../../design-system/Text";
import FillButton from "../../design-system/button/FillButton";
import { useAppDispatch } from "../../../store/store";
import { API_DEFAULT } from "../../../api/api";
import { instance } from "../../../api/index";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function FeedRemoveModal({ open, onClose, detail }: any) {
  const dispatch = useAppDispatch();

  async function removeContent() {
    const res = await instance.delete(
      `${API_DEFAULT}/contents/delete?id=${detail?.contentId}`,
    );

    if (res?.data.success) {
      onClose();
      dispatch(
        toastFetched({
          show: true,
          text: "삭제 완료",
          type: "success",
        }),
      );
      if (window.opener && !window.opener.closed) {
        window.opener.location.reload();
      }

      window.close();
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.data.error.message,
          type: "error",
        }),
      );
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: removeContent,
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ["me-data"] });
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          콘텐츠를 삭제하시겠습니까?
        </Text>
        <Text className="subtitle-2 dark:text-darkText">
          다운 받지 않은 콘텐츠는 영구적으로 삭제 됩니다.
        </Text>

        <FillButton
          text="삭제하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={mutate}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
