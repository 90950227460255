import React, { useEffect, useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import { useAxios } from "../../../lib/api/useAxios";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { API_DEFAULT } from "../../../api/api";
import { setDetail } from "../../../store/reducer/feedSlice";
import Text from "../../../components/design-system/Text";
import { Input } from "@material-tailwind/react";
import styled from "styled-components";
import UploadNormalInput from "../../../components/design-system/input/UploadNormalInput";
import UploadAutoInput from "../../../components/design-system/input/UploadAutoInput";
import FillButton from "../../../components/design-system/button/FillButton";
import CustomCheckBox from "../../../components/design-system/CustomCheckBox";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toastFetched } from "../../../store/reducer/toastSlice";
import JoinTermsModal from "../../join/modal/JoinTermsModal";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorText, UploadTextColor } from "../../../styles/color";
import { addComma } from "../../../util/number";
import { instance } from "../../../api";
import { itemResell } from "../../../api/feed/feed";

// 사파리 브라우저 확인 함수
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
};

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: ${isSafari() ? "100%" : "860px"};
`;

const CheckboxBorderWrap = styled(BorderWrap)`
  width: 100%;
`;

export default function ResellModal({ open, onClose, data }: any) {
  const { get, post } = useAxios();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { detail } = useAppSelector((state) => state.feed);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState<any>();
  const [content, setContent] = useState("");
  const [keyword, setKeyword] = useState([]);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });

  const [ownershipPrice, setOwnershipPrice] = useState<any>("");
  const [ticketPrice, setTicketPrice] = useState<any>("");

  // 체크박스 선택 여부
  const [ownerChecked, setOwnerChecked] = useState(false);
  const [tickeChecked, setTicketChecked] = useState(false);

  const [step, setStep] = useState(1);

  async function onFetch() {
    const payload = { contentId: data?.contentId };
    const res = await instance.get(`${API_DEFAULT}/contents/search/detail`, {
      params: payload,
    });
    if (res?.data.success) {
      setLoading(false);
      dispatch(setDetail(res?.data));
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    onFetch();
  }, []);

  useEffect(() => {
    setTitle(detail?.contentName);
    const keywordStrings = detail.keywords
      ? detail.keywords.map((k: any) => k.keyword)
      : [];
    setKeyword(keywordStrings);
    setOwnerChecked(detail?.ownershipPrice !== 0 ? true : false);
    setTicketChecked(detail?.ticketPrice !== 0 ? true : false);
    setOwnershipPrice(detail?.ownershipPrice);
    setTicketPrice(detail?.ticketPrice);
  }, [detail]);

  const formatPrice = (price: string | number) => {
    if (typeof price === "string") {
      return price.replace(/,/g, "");
    }
    return price;
  };

  const { mutate, status } = useMutation({
    mutationFn: itemResell,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["locker-get"] });
      if (data?.success) {
        onClose();
        dispatch(
          toastFetched({
            show: true,
            text: "판매 완료",
            type: "success",
          }),
        );
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          }),
        );
      }
    },
  });

  useEffect(() => {
    if (ownerChecked === false) {
      setOwnershipPrice("");
    }

    if (tickeChecked === false) {
      setTicketPrice("");
    }
  }, [ownerChecked, tickeChecked]);

  const inputPriceFormat = (str: any) => {
    const comma = (str: any) => {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    };
    const uncomma = (str: any) => {
      return str.replace(/[^\d]+/g, "");
    };
    return comma(uncomma(str));
  };

  function shouldDisableButton({
    keyword,
    title,
    status,
    tickeChecked,
    ticketPrice,
    ownerChecked,
    ownershipPrice,
    switchData,
  }: any) {
    const basicConditions =
      keyword.length >= 5 && title !== "" && status !== "pending";

    if (!basicConditions) return true;

    const parsePrice = (price: string | number) => {
      if (typeof price === "string") {
        return parseInt(price.replace(/,/g, ""), 10);
      }
      return price;
    };

    const ticketPriceValue = parsePrice(ticketPrice);
    const ownershipPriceValue = parsePrice(ownershipPrice);

    if (
      switchData ||
      (tickeChecked &&
        !ownerChecked &&
        !isNaN(ticketPriceValue) &&
        ticketPriceValue >= 100) ||
      (!tickeChecked &&
        ownerChecked &&
        !isNaN(ownershipPriceValue) &&
        ownershipPriceValue >= 100)
    ) {
      return false;
    }

    if (tickeChecked && ownerChecked) {
      if (
        !isNaN(ticketPriceValue) &&
        ticketPriceValue >= 100 &&
        !isNaN(ownershipPriceValue) &&
        ownershipPriceValue > ticketPriceValue
      ) {
        return false;
      }
    }

    return true;
  }

  return (
    <>
      <Modal open={open} onClose={onClose} headerNone width="800">
        {step === 1 ? (
          <div className="flex flex-col items-center justify-center w-[490px]">
            <Text className="subtitle-2 dark:text-darkText">
              콘텐츠를 판매하면 결제 취소가 불가능합니다.
            </Text>

            <FillButton
              text="다음"
              className="w-[350px] mt-[30px]"
              size="lg"
              onClick={() => {
                setStep(2);
              }}
            />

            <Text
              className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
              onClick={onClose}
            >
              취소
            </Text>
          </div>
        ) : (
          <>
            <Text
              fontSize={20}
              fontWeight={700}
              color="#1a1a1a"
              className="mb-8 dark:text-darkText"
            >
              콘텐츠 정보 수정
            </Text>

            <div className="mt-5 mb-10 flex flex-col gap-4">
              <BorderWrap className="p-6">
                <UploadNormalInput
                  value={title}
                  setValue={(e: any) => setTitle(e.target.value)}
                  label="제목"
                  placeholder="콘텐츠 제목을 입력해주세요."
                  helperText="제목은 필수 입니다."
                  error={title === ""}
                />
              </BorderWrap>

              <BorderWrap className="p-6">
                <UploadNormalInput
                  value={content}
                  setValue={(e: any) => setContent(e.target.value)}
                  label="설명"
                  placeholder="콘텐츠 설명을 입력해주세요."
                />
              </BorderWrap>

              <BorderWrap className="p-6">
                <UploadAutoInput value={keyword} setValue={setKeyword} />
              </BorderWrap>

              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <li>키워드를 입력하고 엔터를 꼭 눌러주세요!</li>
              </ul>
            </div>

            <div className="w-full">
              <Text className="subtitle-3 dark:text-darkText mb-3">
                판매 금액 설정
              </Text>

              <div className="flex gap-3">
                <CheckboxBorderWrap className="p-5 pr-4 flex flex-col gap-2">
                  <div className="flex items-center gap-6">
                    <CustomCheckBox
                      labelStyle="dark:text-darkText"
                      checked={tickeChecked}
                      onChange={() => setTicketChecked(!tickeChecked)}
                      label={"1년 이용권"}
                    />
                    <input
                      type="text"
                      placeholder="가격 입력"
                      className="outline-none bg-inherit dark:text-darkText max-w-[200px]"
                      value={ticketPrice}
                      onChange={(e: any) => {
                        const value = inputPriceFormat(e.target.value);
                        const numericValue = parseInt(
                          value.replace(/,/g, ""),
                          10,
                        );

                        if (value === "" || numericValue <= 10000000) {
                          setTicketPrice(value);
                        }
                      }}
                    />
                    <Text className="dark:text-darkText">₩</Text>
                  </div>
                </CheckboxBorderWrap>

                <CheckboxBorderWrap className="p-5 pr-4 flex flex-col gap-2">
                  <div className="flex items-center gap-6">
                    <CustomCheckBox
                      labelStyle="dark:text-darkText"
                      checked={ownerChecked}
                      onChange={() => setOwnerChecked(!ownerChecked)}
                      label={"소유권"}
                    />
                    <input
                      type="text"
                      placeholder="가격 입력"
                      className="outline-none bg-inherit dark:text-darkText max-w-[200px]"
                      value={ownershipPrice}
                      onChange={(e: any) => {
                        const value = inputPriceFormat(e.target.value);
                        const numericValue = parseInt(
                          value.replace(/,/g, ""),
                          10,
                        );

                        if (value === "" || numericValue <= 50000000) {
                          setOwnershipPrice(value);
                        }
                      }}
                    />

                    <Text className="dark:text-darkText">₩</Text>
                  </div>
                </CheckboxBorderWrap>
              </div>
            </div>

            {ownerChecked &&
              (typeof ticketPrice === "string" ||
                typeof ticketPrice === "number") &&
              (typeof ownershipPrice === "string" ||
                typeof ownershipPrice === "number") &&
              ownerChecked &&
              parseInt(ticketPrice.toString().replace(/,/g, ""), 10) >
                parseInt(ownershipPrice.toString().replace(/,/g, ""), 10) && (
                <div className="w-full flex items-center justify-end mt-3 gap-2">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="text-[#dc281f]"
                  />
                  <Text fontSize={14} fontWeight={600} color={ErrorText}>
                    판매 금액이 1년 이용권 금액보다 작으면 안돼요!
                  </Text>
                </div>
              )}

            <div className="mt-5">
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                <li>
                  1년 이용권은 1년간 상업/비상업적 용도로 사용할 수 있는
                  이용권이에요.
                </li>
                <li>
                  소유권은 콘텐츠의 모든 권한(소유권)이 구매자에게 승계 됨으로
                  신중한 판매 금액 설정 부탁드립니다.
                </li>
                <li>모든 콘텐츠는 판매시, 환불이 불가능 합니다.</li>
                <li>
                  유저의 실수로 인한 사고 발생시, 스탠바이는 책임지지 않습니다.
                  이용약관을 꼭 확인해주세요.
                </li>
              </ul>
              <Text
                fontSize={12}
                fontWeight={600}
                color={"#A7A7A7"}
                className="cursor-pointer underline underline-offset-4"
                onClick={() => setInfoModal({ show: true })}
              >
                *이용약관 확인하기
              </Text>
            </div>

            <div className="flex flex-col items-center justify-center mt-10">
              <FillButton
                text="판매하기"
                className="w-[350px]"
                size="lg"
                onClick={() => {
                  const payload = {
                    contentId: detail?.contentId,
                    contentName: title,
                    description: content ? content : null,
                    keyword: keyword,
                    ownershipPrice: ownerChecked
                      ? formatPrice(ownershipPrice)
                      : 0,
                    ticketPrice: tickeChecked ? formatPrice(ticketPrice) : 0,
                    ownerChecked: ownerChecked ? ownerChecked : false,
                    ticketChecked: tickeChecked ? tickeChecked : false,
                  };
                  mutate(payload);
                }}
                disabled={shouldDisableButton({
                  keyword,
                  title,
                  status,
                  tickeChecked,
                  ticketPrice,
                  ownerChecked,
                  ownershipPrice,
                })}
              />
              <Text
                className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
                onClick={onClose}
              >
                취소
              </Text>
            </div>
          </>
        )}
      </Modal>

      {infoModal.show && (
        <JoinTermsModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
    </>
  );
}
