import React from "react";
import Modal from "../../../components/design-system/modal/Modal";

export default function GeoInfoModal({ open, onClose }: any) {
  return (
    <Modal open={open} onClose={onClose} width={"800px"} headerNone>
      <img
        src={"/img/standbuy/background/geo-info-banner.png"}
        alt="GeoInfo Logo"
      />
    </Modal>
  );
}
