import { Modal as MuiModal } from "@mui/material";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Text from "../Text";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

const ModalHeader = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalBody = styled.div<any>`
  /* padding: ${(props) => (props.feed ? "65px" : "30px")}; */
`;

export default function ImgModal({
  title,
  children,
  open,
  onClose,
  noOutSideClosed = false,
  width,
  height,
  headerNone = false,
  feed = false,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const widthView = window.innerWidth;

  const style = {
    "&:focus": {
      outline: "none",
    },
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgColor: themeData === "light" || themeData === "" ? "white" : "#121212",
    // borderRadius: "20px",
    borderColor: "white",
    // boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
    // padding: isMobile ? "" : "0 240px",
    // maxWidth: width > height ? "100%" : "30%",
  };

  return (
    <MuiModal
      keepMounted
      open={open}
      onClose={noOutSideClosed ? false : onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!headerNone && (
          <ModalHeader>
            <Text className="subtitle-2" fontSize={16}>
              {title}
            </Text>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={onClose}
              fontSize={24}
              className="cursor-pointer text-white"
            />
          </ModalHeader>
        )}

        <ModalBody feed={feed}>{children}</ModalBody>
      </Box>
    </MuiModal>
  );
}
