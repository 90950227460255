import { createSlice } from "@reduxjs/toolkit";

const initialBoundsState = {
  ne: { lat: 36.507468472827576, lng: 125.24388600000003 },
  sw: { lat: 38.60617845599798, lng: 128.75951100000003 },
  zoom: 9,
};

export const mapBoundSlice = createSlice({
  name: "bounds",
  initialState: initialBoundsState,
  reducers: {
    boundsFetched: (state, action) => {
      state.ne = action.payload.ne;
      state.sw = action.payload.sw;
    },
    zoomFetched: (state, action) => {
      state.zoom = action.payload.zoom;
    },
  },
});

export const { boundsFetched, zoomFetched } = mapBoundSlice.actions;

export default mapBoundSlice.reducer;
