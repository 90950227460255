import React, { useState } from "react";
import PageCard from "../../components/design-system/PageCard";
import EditAvatarForm from "./EditAvatarForm";
import RemoveUserModal from "./modal/RemoveUserModal";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";
import GoogleAdsense from "react-adsense-google";

export default function ProfileEditPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [userRemove, setUserRemove] = useState({ show: false });
  return (
    <div className="flex flex-col justify-between h-full">
      <PageCard
        title="프로필 편집"
        textButton="탈퇴하기"
        onClick={() => setUserRemove({ show: true })}
      >
        <EditAvatarForm />
      </PageCard>
      {userRemove.show && (
        <RemoveUserModal
          open={userRemove.show}
          onClose={() => setUserRemove({ show: false })}
        />
      )}
      <GoogleAdsense
        adClient="ca-pub-1049694803672930"
        adSlot="9250904794"
        style={{ display: "block", "text-align": "center" }}
        adLayout="in-article"
        adFormat="fluid"
        fullWidthResponsive="true"
      />
      {isMobile ? <MobileFooter /> : <CustomFooter />}
    </div>
  );
}
