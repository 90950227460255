import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const onProfileImg = async (formData: any) => {
  const url = `${API_DEFAULT}/user/profile/update/image`;

  const _form = new FormData();
  for (const [key, value] of Object.entries({ profileImage: formData })) {
    const _key: string = key;
    const _value: any = value;
    _form.append(_key, _value);
  }

  const response = await instance
    .post(url, _form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const onNicknameChange = async (nickname: any) => {
  const url = `${API_DEFAULT}/user/profile/nick?nick=${nickname}`;
  const response = await instance
    .post(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
