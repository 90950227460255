import React from "react";
import styled from "styled-components";
import ImgModal from "../../design-system/modal/ImgModal";
import { useMediaQuery } from "react-responsive";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// 스타일드 컴포넌트 정의
const StyledFigure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img<{ width: number; isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  height: auto;
  max-height: ${(props) =>
    props.isMobile
      ? "calc(100vh - 20px)"
      : "90vh"}; /* 화면 높이에 맞게 최대 높이 제한 */
  object-fit: contain;
`;

export default function FeedOrignalImgModal({ open, onClose, data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <ImgModal
      open={open}
      onClose={onClose}
      width={data?.contentWidth}
      height={data?.contentHeight}
    >
      <TransformWrapper initialScale={1} minScale={1} maxScale={5}>
        <TransformComponent>
          <StyledFigure>
            <StyledImage
              src={data?.previewPath}
              alt=""
              width={data?.contentWidth}
              height={data?.contentHeight}
              isMobile={isMobile}
            />
          </StyledFigure>
        </TransformComponent>
      </TransformWrapper>
    </ImgModal>
  );
}
