import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const settleGet = async () => {
  const url = `${API_DEFAULT}/user/settle/stat`;
  const response = await instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const handleTransFof = async () => {
  const url = `${API_DEFAULT}/user/settle`;
  const response = await instance
    .post(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const addBankInfo = async (payload: any) => {
  const url = `${API_DEFAULT}/user/info/account/nice`;
  const response = await instance
    .post(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const cancelExchange = async (payload: any) => {
  const url = `${API_DEFAULT}/user/settle/cancel?exchangeId=${payload}`;
  const response = await instance
    .post(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
