import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const mySimple = async () => {
  const url = `${API_DEFAULT}/user/info/my-simple`;
  const response = await instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const meData = async () => {
  const url = `${API_DEFAULT}/user/info/me`;
  const response = await instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
