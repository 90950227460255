import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import LoopItem from "../../../components/common/LoopItem";
import {
  setThumbnailSelect,
  setUploadPayload,
} from "../../../store/reducer/uploadSlice";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Thumbnail({ status, makeStatus }: any) {
  const dispatch = useAppDispatch();
  const { thumbnail_select, thumbnail } = useAppSelector(
    (state) => state.upload,
  );

  const extractThumbnailPath = (url: any) => {
    try {
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      const index = pathname.indexOf("preview/thumbnail/");
      if (index !== -1) {
        return pathname.slice(index);
      }
      return url; // 만약 예상된 부분을 찾지 못하면 원래 URL 반환
    } catch (e) {
      console.error("Invalid URL:", url);
      return url;
    }
  };

  return (
    <div className="flex justify-center items-center gap-4">
      {status === "PROGRESSING" ||
      status === "SUBMITTED" ||
      makeStatus === "pending" ? (
        <Skeleton width={150} height={150} />
      ) : (
        <LoopItem
          info={thumbnail}
          renderItem={({ item, index }) => {
            return (
              <button
                className={`rounded-xl ${
                  thumbnail_select === index
                    ? "border-[#134B70] ring-4 ring-[#134B70] p-0.5"
                    : ""
                }`}
                onClick={() => {
                  const processedItem = extractThumbnailPath(item);
                  dispatch(setThumbnailSelect(index));
                  dispatch(setUploadPayload({ thumbnail: processedItem }));
                }}
              >
                <img
                  src={item}
                  alt={"썸네일"}
                  // style={{ maxWidth: 150, width: "auto", height: 150 }}

                  className="rounded-xl max-w-[150px] object-cover"
                />
              </button>
            );
          }}
        />
      )}
    </div>
  );
}
