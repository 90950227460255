import React, { useEffect, useState } from "react";
import { useContext } from "../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import { setDetail } from "../../store/reducer/feedSlice";
import { useLocation, useNavigate } from "react-router-dom";
import AssetsMode from "../../components/feed/modal/AssetsMode";
import FeedContent from "../../components/feed/modal/FeedContent";
import SellerInfo from "../../components/feed/modal/SellerInfo";
import FeedComment from "../../components/feed/modal/FeedComment";
import { useMediaQuery } from "react-responsive";
import MobileFeedContent from "../../components/feed/modal/MobileFeedContent";
import { instance } from "../../api";

export default function MobileFeedPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state } = useContext();
  const { get } = useAxios();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState<any>();
  const { detail } = useAppSelector((state) => state.feed);

  async function onFetch() {
    const payload = { contentId: state?.target || state?.contentId };
    const res = await instance
      .get(`${API_DEFAULT}/contents/search/detail`, {
        params: payload,
      })
      .then((response) => {
        if (response?.data.success) {
          dispatch(setDetail(response?.data.data));
          return true;
        } else {
          return false;
        }
      });
  }

  useEffect(() => {
    onFetch();
  }, [state]);

  useEffect(() => {
    const temp = location?.pathname;
    const params = temp.split("/");
    setId(params);
  }, [location]);

  return (
    <div className="w-full flex flex-col h-full">
      <div
        className={`${isMobile ? "mt-4 px-4" : "mt-[50px] mx-auto"}  flex flex-col justify-center max-w-[1280px]`}
      >
        <div className={`flex ${isMobile && "flex-col"} gap-10`}>
          <AssetsMode />
          <MobileFeedContent state={state} />
        </div>
        <div>
          <SellerInfo detail={detail} />
          <FeedComment detail={detail} state={state} />
        </div>
      </div>
    </div>
  );
}
