import FillButton from "../../components/design-system/button/FillButton";
import Card from "../../components/design-system/Card";
import Text from "../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import Switcher from "../../components/common/Switcher";
import styled from "styled-components";
import FadeMotionDiv from "../../components/design-system/motion/FadeMotionDiv";
import { GOOGLE_LOGIN, KAKAO_LOGIN, NAVER_LOGIN } from "../../api/api";
import { useMediaQuery } from "react-responsive";

const Wrap = styled.div`
  background-image: url("/img/standbuy/background/login-bg.png");
`;

export default function LoginPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  return (
    <>
      {isMobile ? (
        <div
          className={`bg-white dark:bg-darkBg h-screen flex justify-center items-center`}
        >
          <Switcher className="hidden" />
          <FadeMotionDiv>
            <div>
              <div className="flex flex-col items-center gap-4">
                <img
                  src={
                    themeData === "light" || themeData === ""
                      ? "/img/standbuy/logo/logo.svg"
                      : "/img/standbuy/logo/logo-white.svg"
                  }
                  alt="standbuy"
                  onClick={() => navigate("/")}
                  className="cursor-pointer my-4"
                  width={300}
                />
                {/* <Text className="subtitle-1 my-4 dark:text-darkText">
              네가 지나온 길엔 스톡이 남아
            </Text> */}
                <FillButton
                  text={"이메일로 로그인"}
                  onClick={() => navigate("/login-email")}
                  className="w-full"
                  size="lg"
                />

                <div className="flex gap-4 items-center">
                  <Text
                    className="underline underline-offset-4 cursor-pointer my-4 dark:text-darkText"
                    onClick={() => navigate("/join")}
                    fontSize={14}
                    fontWeight={500}
                    color="#363636"
                  >
                    회원가입
                  </Text>

                  <div className="w-[1px] h-[14px] bg-[#f1f1f1]"></div>

                  <Text
                    className="underline underline-offset-4 cursor-pointer my-4 dark:text-darkText"
                    onClick={() => navigate("/password-find")}
                    fontSize={14}
                    fontWeight={500}
                    color="#363636"
                  >
                    비밀번호 찾기
                  </Text>
                </div>

                <div className="flex gap-12">
                  {/*<img*/}
                  {/*  src="/img/standbuy/sns/sns_naver.png"*/}
                  {/*  width={50}*/}
                  {/*  alt="standbuy"*/}
                  {/*  onClick={() => {*/}
                  {/*    const url = NAVER_LOGIN;*/}
                  {/*    window.location = url as any;*/}
                  {/*  }}*/}
                  {/*  // onClick={() =>*/}
                  {/*  //   dispatch(*/}
                  {/*  //     toastFetched({*/}
                  {/*  //       show: true,*/}
                  {/*  //       text: "네이버 로그인은 테스트 기간 중 이용이 불가합니다.",*/}
                  {/*  //       type: "error",*/}
                  {/*  //     }),*/}
                  {/*  //   )*/}
                  {/*  // }*/}
                  {/*  className="cursor-pointer"*/}
                  {/*/>*/}
                  <img
                    src="/img/standbuy/sns/sns_kakao.png"
                    width={50}
                    alt="standbuy"
                    onClick={() => {
                      const url = KAKAO_LOGIN;
                      window.location = url as any;
                    }}
                    className="cursor-pointer"
                  />
                  <img
                    src="/img/standbuy/sns/sns_google.png"
                    width={50}
                    alt="standbuy"
                    onClick={() => {
                      const url = GOOGLE_LOGIN;
                      window.location = url as any;
                    }}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </FadeMotionDiv>
        </div>
      ) : (
        <Wrap
          className={`bg-gray-200 dark:bg-darkBg h-screen flex justify-center items-center bg-cover`}
        >
          <Switcher className="hidden" />
          <FadeMotionDiv>
            <Card>
              <div className="flex flex-col items-center gap-4">
                <img
                  src={
                    themeData === "light" || themeData === ""
                      ? "/img/standbuy/logo/logo.svg"
                      : "/img/standbuy/logo/logo-white.svg"
                  }
                  alt="standbuy"
                  onClick={() => navigate("/")}
                  className="cursor-pointer my-4"
                  width={300}
                />
                {/* <Text className="subtitle-1 my-4 dark:text-darkText">
              네가 지나온 길엔 스톡이 남아
            </Text> */}
                <FillButton
                  text={"이메일로 로그인"}
                  onClick={() => navigate("/login-email")}
                  className="w-full"
                  size="lg"
                />

                <div className="flex gap-4 items-center">
                  <Text
                    className="underline underline-offset-4 cursor-pointer my-4 dark:text-darkText"
                    onClick={() => navigate("/join")}
                    fontSize={14}
                    fontWeight={500}
                    color="#363636"
                  >
                    회원가입
                  </Text>

                  <div className="w-[1px] h-[14px] bg-[#f1f1f1]"></div>

                  <Text
                    className="underline underline-offset-4 cursor-pointer my-4 dark:text-darkText"
                    onClick={() => navigate("/password-find")}
                    fontSize={14}
                    fontWeight={500}
                    color="#363636"
                  >
                    비밀번호 찾기
                  </Text>
                </div>

                <div className="flex gap-12">
                  {/*<img*/}
                  {/*  src="/img/standbuy/sns/sns_naver.png"*/}
                  {/*  width={50}*/}
                  {/*  alt="standbuy"*/}
                  {/*  onClick={() => {*/}
                  {/*    const url = NAVER_LOGIN;*/}
                  {/*    window.location = url as any;*/}
                  {/*  }}*/}
                  {/*  // onClick={() =>*/}
                  {/*  //   dispatch(*/}
                  {/*  //     toastFetched({*/}
                  {/*  //       show: true,*/}
                  {/*  //       text: "네이버 로그인은 테스트 기간 중 이용이 불가합니다.",*/}
                  {/*  //       type: "error",*/}
                  {/*  //     }),*/}
                  {/*  //   )*/}
                  {/*  // }*/}
                  {/*  className="cursor-pointer"*/}
                  {/*/>*/}
                  <img
                    src="/img/standbuy/sns/sns_kakao.png"
                    width={50}
                    alt="standbuy"
                    onClick={() => {
                      const url = KAKAO_LOGIN;
                      window.location = url as any;
                    }}
                    className="cursor-pointer"
                  />
                  <img
                    src="/img/standbuy/sns/sns_google.png"
                    width={50}
                    alt="standbuy"
                    onClick={() => {
                      const url = GOOGLE_LOGIN;
                      window.location = url as any;
                    }}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </Card>
          </FadeMotionDiv>
        </Wrap>
      )}
    </>
  );
}
