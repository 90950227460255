//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import Text from "../../components/design-system/Text";
import { motion } from "framer-motion";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import LocationFeedMap from "./card/LocationFeedMap";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import { useAxios } from "../../lib/api/useAxios";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import {
  setLocationListKeywordList,
  setPositions,
  setSearchResult,
} from "../../store/reducer/locationSlice";
import { FeedNav } from "../../components/design-system/FeedNav";
import StockListCard from "../feed/stock-list/StockListCard";
import { useContext } from "../../util/useContext";
import { useLocation, useNavigate } from "react-router-dom";
import { shallowEqual } from "react-redux";
import debounce from "lodash/debounce";
import { boundsFetched } from "../../store/reducer/mapBoundSlice";
import { isEqual } from "lodash";
import AlertModal from "./modal/AlertModal";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../api";
import LocationSideView from "./card/LocationSideView";

export default function LocationFeedSearch({ lat, lng }: any) {
  const { me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const bounds = useAppSelector((state) => state.bounds, shallowEqual);
  const location = useLocation();
  const navigate = useNavigate();
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { positions } = useAppSelector((state) => state.location);
  const { state } = useContext();
  const [keywordId, setKeywordId] = useState<number | null>(null);
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [items, setItems] = useState<any>([]);
  const { locationKeywordList } = useAppSelector(
    (state) => state.location,
    shallowEqual,
  );
  const zoom = useAppSelector((state) => state.bounds.zoom);
  const previousBoundsRef = useRef(bounds);

  const fetchItems = useRef(
    debounce(async () => {
      const newItems = await getItemsKeyword(1, 30);
      setItems(newItems);
    }, 2000),
  ).current;

  const debouncedOnAllLocation = useRef(
    debounce(async ({ latMin, latMax, lonMin, lonMax, zoom }: any) => {
      const payload = {
        latMin: latMin,
        latMax: latMax,
        lonMin: lonMin,
        lonMax: lonMax,
        zoom: zoom,
      };

      const res = await instance.post(
        `${API_DEFAULT}/contents/search/markers`,
        payload,
      );
      if (res?.data.success) {
        dispatch(setSearchResult([]));
        dispatch(setPositions(res?.data.data));
        dispatch(
          boundsFetched({
            ne: { lat: latMax, lng: lonMax },
            sw: { lat: latMin, lng: lonMin },
          }),
        );
      } else {
        // 에러 처리
      }
    }, 800),
  ).current;

  useEffect(() => {
    const prevBounds = previousBoundsRef.current;
    if (
      bounds.ne &&
      bounds.sw &&
      (!isEqual(prevBounds.ne, bounds.ne) || !isEqual(prevBounds.sw, bounds.sw))
    ) {
      debouncedOnAllLocation({
        latMin: bounds.sw.lat,
        latMax: bounds.ne.lat,
        lonMin: bounds.sw.lng,
        lonMax: bounds.ne.lng,
        zoom: zoom,
      });
      fetchItems();
    }
    previousBoundsRef.current = bounds;

    // 클린업 함수 (언마운트 시 실행)
    return () => {
      debouncedOnAllLocation.cancel();
      fetchItems.cancel();
    };
  }, [bounds, debouncedOnAllLocation, fetchItems]);

  async function getItemsKeyword(nextGroupKey: number, count: number) {
    const prevBounds = previousBoundsRef.current;
    let nextItems = [];
    const nextKey = nextGroupKey * count;

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: prevBounds.sw.lat,
      latMax: prevBounds.ne.lat,
      lonMin: prevBounds.sw.lng,
      lonMax: prevBounds.ne.lng,
      page: nextGroupKey,
      size: count,
    });
    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // 새로고침 막기 변수
  const preventClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
  };

  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  useEffect(() => {
    (() => {
      window.addEventListener("beforeunload", preventClose);
    })();

    return () => {
      window.removeEventListener("beforeunload", preventClose);
    };
  }, []);

  // useEffect(() => {
  //   if (!positions) {
  //     navigate("/location-feed", { replace: true });
  //   }
  // }, [positions]);

  useEffect(() => {
    if (!me || !me.memberId) return;

    if (
      me.memberId === 1 ||
      me.memberId === 2 ||
      me.memberId === 3 ||
      me.memberId === 6
    ) {
      return;
    } else {
      navigate("/feed");
    }
  }, [me]);

  return (
    <div className={`w-full flex max-w-screen`}>
      {!isMobile && (
        <div className="w-1/6 h-full ml-[20px] mt-[100px] relative">
          <JustifiedInfiniteGrid
            columnRange={[0, 1]}
            threshold={3000}
            gap={8}
            onRequestAppend={(e) => {
              const nextGroupKey = (+e.groupKey! || 0) + 1;
              // 같은 그룹키는 무시
              if (nextGroupKey === groupKey) {
                return;
              }
              setGroupKey(nextGroupKey);
              e.wait();

              setTimeout(async () => {
                const add = await getItemsKeyword(nextGroupKey, 10);
                if (add.length === 0) {
                  setKeywordId(null);
                  e.ready();

                  return true;
                }
                setItems([...items, ...add]);
                e.ready();
              }, 2000);
            }}
          >
            {items?.length < 1 ? (
              <div className="flex flex-col items-center justify-center">
                <Text className="subtitle-2 dark:text-darkText">
                  조회된 피드가 없습니다.
                </Text>
                <Text className="label-1 dark:text-darkText">
                  장소, 주소를 다시 검색해주세요!
                </Text>
              </div>
            ) : (
              items?.map((item: any, index: any) => (
                <StockListCard
                  data-grid-groupkey={item.groupKey}
                  key={index}
                  index={index}
                  item={item}
                  onClick={undefined}
                />
              ))
            )}
          </JustifiedInfiniteGrid>
        </div>
      )}

      <div
        className={`flex flex-col justify-between  ${
          isMobile ? "pt-[60px]" : "pt-[80px] fixed"
        }`}
        style={{
          left: isMobile ? "" : "calc(16.6667% + 20px)",
          width: isMobile ? "100%" : "calc(83.3333% - 20px)",
        }}
      >
        <motion.div
          className={`w-full ${
            isMobile ? "px-10" : "flex justify-center"
          } mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className={`${isMobile ? "" : "w-full mx-10"}`}>
            <div className="flex justify-between items-center mb-[10px] mt-[20px]">
              <Text
                fontSize={isMobile ? 20 : 28}
                fontWeight={600}
                className="dark:text-darkText"
              >
                <Text
                  fontSize={isMobile ? 20 : 28}
                  fontWeight={600}
                  className="dark:text-darkText"
                  color="green"
                  span
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={() => {
                      navigate("/location-feed", { replace: true });
                    }}
                    className={"cursor-pointer"}
                  />{" "}
                  {state?.keyword} {""}
                </Text>
                장소 검색 결과
              </Text>
            </div>

            <LocationFeedMap lat={state?.data.lat} lng={state?.data.lng} />

            <div className="mt-10 mb-4">
              <Text
                fontSize={16}
                fontWeight={600}
                color="#303030"
                className="dark:text-darkText"
              >
                검색 장소와 관련된 게시물
              </Text>
            </div>

            <JustifiedInfiniteGrid
              columnRange={[0, 1]}
              threshold={3000}
              gap={8}
              onRequestAppend={(e) => {
                const nextGroupKey = (+e.groupKey! || 0) + 1;
                // 같은 그룹키는 무시
                if (nextGroupKey === groupKey) {
                  return;
                }
                setGroupKey(nextGroupKey);
                e.wait();

                setTimeout(async () => {
                  const add = await getItemsKeyword(nextGroupKey, 10);
                  if (add.length === 0) {
                    setKeywordId(null);
                    e.ready();

                    return true;
                  }
                  setItems([...items, ...add]);
                  e.ready();
                }, 2000);
              }}
            >
              {items?.length < 1 ? (
                <div className="flex flex-col items-center justify-center">
                  <Text className="subtitle-2 dark:text-darkText">
                    조회된 피드가 없습니다.
                  </Text>
                  <Text className="label-1 dark:text-darkText">
                    장소, 주소를 다시 검색해주세요!
                  </Text>
                </div>
              ) : (
                items?.map((item: any, index: any) => (
                  <StockListCard
                    data-grid-groupkey={item.groupKey}
                    key={index}
                    index={index}
                    item={item}
                    onClick={undefined}
                  />
                ))
              )}
            </JustifiedInfiniteGrid>
          </div>

          <div
            className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
          >
            <div className="flex flex-col justify-center items-center gap-4">
              <FeedNav />

              {!isMobile && (
                <IconButton
                  //@ts-ignore
                  className="rounded-full px-[28px] py-[28px]"
                  placeholder={undefined}
                  children={undefined}
                  size="lg"
                  onClick={moveToTop}
                >
                  <FontAwesomeIcon icon={faArrowUp} />
                </IconButton>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
