import { API_DEFAULT } from "../../api/api";
import { LOCAL_STORAGE, SESSION_STORAGE } from "../const";
import { ajax } from "../api/useAxios";
import {
  setAccessToken,
  setExpiresAt,
  setLogin,
  setMe,
  setRefreshToken,
} from "../../store/reducer/authSlice";
import { meData } from "../../api/info/info";

export async function signIn(payload: any) {
  payload.dispatch(setAccessToken(payload?.data?.accessToken));
  payload.dispatch(setRefreshToken(payload?.data?.refreshToken));
  payload.dispatch(setExpiresAt(payload?.data?.accessTokenExpiresIn));
  payload.dispatch(setLogin(true));

  // 세션스토리지에 로그인정보를 저장한다.
  !!payload?.data?.accessToken &&
    (await localStorage.setItem(
      LOCAL_STORAGE.TOKEN,
      payload?.data?.accessToken,
    ));

  !!payload?.data?.refreshToken &&
    (await localStorage.setItem(
      LOCAL_STORAGE.R_TOKEN,
      payload.data?.refreshToken,
    ));

  !!payload?.data?.accessTokenExpiresIn &&
    (await localStorage.setItem(
      LOCAL_STORAGE.EXPIRES_AT,
      payload.data?.accessTokenExpiresIn,
    ));

  localStorage.setItem(LOCAL_STORAGE.LOGIN, "true");

  const me = await meData();
  if (me?.success) {
    await payload.dispatch(setMe(me?.data));
  } else {
    return { status: "fail", message: me?.message || "유저정보실패" };
  }

  return { status: "success", message: me?.data };
}

export async function signOut(payload?: any) {
  payload.dispatch(setAccessToken(null));
  payload.dispatch(setRefreshToken(null));
  payload.dispatch(setLogin(false));
  localStorage.removeItem(LOCAL_STORAGE.TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.R_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.EXPIRES_AT);
  localStorage.removeItem(LOCAL_STORAGE.LOGIN);
  localStorage.removeItem(LOCAL_STORAGE.BROWSER);

  window.location.href = process.env.REACT_APP_HOST as any;
}

export async function signOutLogin(payload?: any) {
  payload.dispatch(setAccessToken(null));
  payload.dispatch(setRefreshToken(null));
  payload.dispatch(setLogin(false));
  localStorage.removeItem(LOCAL_STORAGE.TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.R_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.EXPIRES_AT);
  localStorage.removeItem(LOCAL_STORAGE.LOGIN);
  localStorage.removeItem(LOCAL_STORAGE.BROWSER);

  window.location.href = `https://www.standbuy.io/login` as any;
}

// export async function signInComplete(payload: any) {
//   // 세션스토리지설정
//   !!payload?.token &&
//     (await sessionStorage.setItem(SESSION_STORAGE.TOKEN, payload?.token));
//   return true;
// }
