import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import FillButton from "../../../components/design-system/button/FillButton";
import Text from "../../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../../util/useContext";

export default function LocationInfoModal({ open, onClose }: any) {
  const { me } = useContext();
  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={onClose} headerNone noOutSideClosed={false}>
      <Text fontSize={20} fontWeight={600} className="mb-8 dark:text-darkText">
        공지
      </Text>
      <div className="flex flex-col items-center justify-center dark:text-darkText">
        <Text className="dark:text-darkText" fontSize={16} fontWeight={600}>
          예상치 못한 급격한 데이터 증가로
        </Text>
        <Text className="dark:text-darkText" fontSize={16} fontWeight={600}>
          지도 기능을 잠시 중단합니다.
        </Text>

        <Text
          className="dark:text-darkText mt-6"
          fontSize={16}
          fontWeight={600}
        >
          금방 업그레이드 하여 제공하겠습니다.
        </Text>

        <Text className="dark:text-darkText" fontSize={16} fontWeight={600}>
          감사합니다.
        </Text>
      </div>

      <div className="flex flex-col items-center gap-4 justify-center mt-8">
        <FillButton
          onClick={onClose}
          text={"닫기"}
          className="w-full"
          size="lg"
        />
      </div>
    </Modal>
  );
}
