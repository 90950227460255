import { Autocomplete, TextField } from "@mui/material";
import { Chip as MaterialChip } from "@material-tailwind/react";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import styled from "styled-components";
import { useEffect, useState } from "react";

export const InputTextField = styled(TextField)<{ themeData: string }>`
  & label {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & label.Mui-focused {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & label.Mui-error {
    color: #d32f2f;
  }
  & .MuiOutlinedInput-root {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
    & fieldset {
      border-color: ${({ themeData }) =>
        themeData === "dark" ? "white" : "#363636"};
    }
  }
  & .MuiInputBase-input {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ themeData }) =>
      themeData === "dark" ? "white" : "#363636"};
  }
`;

export default function UploadAutoInput({
  value,
  setValue,
  readOnly = false,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  const [inputValue, setInputValue] = useState("");
  const [isComposing, setIsComposing] = useState(false);

  return (
    <Autocomplete
      readOnly={readOnly}
      multiple
      options={[]}
      defaultValue={[]}
      freeSolo
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (newInputValue !== inputValue) {
          const cleanedValue = newInputValue.replace("#", "");

          if (!isComposing && cleanedValue.includes(",")) {
            const newKeywords = cleanedValue
              .split(",")
              .map((keyword) => keyword.trim())
              .filter((keyword) => keyword !== "");

            setValue((prev: any) => {
              const uniqueKeywords = newKeywords.filter(
                (keyword) => !prev.includes(keyword),
              );
              return [...prev, ...uniqueKeywords];
            });
            setInputValue("");
          } else {
            setInputValue(cleanedValue);
          }
        }
      }}
      onCompositionStart={() => {
        setIsComposing(true);
      }}
      onCompositionEnd={(event) => {
        setIsComposing(false);

        // 입력이 완료된 후에 쉼표가 포함되어 있으면 처리
        if (inputValue.includes(",")) {
          const newKeywords = inputValue
            .split(",")
            .map((keyword) => keyword.trim())
            .filter((keyword) => keyword !== "");

          setValue((prev: any) => {
            const uniqueKeywords = newKeywords.filter(
              (keyword) => !prev.includes(keyword),
            );
            return [...prev, ...uniqueKeywords];
          });
          setInputValue("");
        }
      }}
      onKeyDown={(event) => {
        if (isComposing) return;

        if (event.key === "Enter" && inputValue.trim() !== "") {
          const newKeywords = inputValue
            .split(",")
            .map((keyword) => keyword.trim())
            .filter((keyword) => keyword !== "");

          setValue((prev: any) => {
            const uniqueKeywords = newKeywords.filter(
              (keyword) => !prev.includes(keyword),
            );
            return [...prev, ...uniqueKeywords];
          });
          setInputValue("");
          event.preventDefault();
        }
      }}
      value={value || []}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <MaterialChip
            variant="filled"
            value={option}
            {...getTagProps({ index })}
            className="rounded-full my-1 mr-1"
            style={{ textTransform: "none" }}
          />
        ))
      }
      renderInput={(params) => (
        <InputTextField
          themeData={themeData}
          {...params}
          label="키워드(쉼표나 엔터를 눌러주세요!)"
          variant="standard"
          placeholder="한단어씩 쉼표 또는 엔터로 적용해요"
          sx={{
            borderBottom:
              themeData === "dark" && inputValue?.length <= 4
                ? "1px solid transparent"
                : "1px solid white",
          }}
          error={value?.length < 5}
          helperText={
            value?.length < 5 ? "최소 5개의 키워드를 입력해야 합니다." : ""
          }
        />
      )}
    />
  );
}
