import Text from "../design-system/Text";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../util/useContext";
import React, { useState } from "react";
import CartEndModal from "../../pages/cart/modal/CartEndModal";
import StandbuyModal from "./StandbuyModal";

export default function MobileTopSection() {
  const navigate = useNavigate();
  const { me } = useContext();
  const [videoModal, setVideoModal] = useState<any>({
    show: false,
    data: "",
  });
  return (
    <>
      <div className="flex justify-between">
        <div
          className="flex flex-col items-center gap-2"
          onClick={() => navigate("/location-feed")}
        >
          <div
            className={
              "w-[62px] h-[62px] rounded-full bg-[#EDEDED] flex items-center justify-center"
            }
          >
            <img
              src="/img/standbuy/icon/main/map-marker.png"
              alt=""
              width={"40"}
            />
          </div>

          <Text fontWeight={500} fontSize={11}>
            지도로 찾기
          </Text>
        </div>
        <div
          className="flex flex-col items-center gap-2"
          onClick={() => setVideoModal({ show: true })}
        >
          <img src={"/img/standbuy/icon/main/standbuy.svg"} alt={""} />
          <Text fontWeight={500} fontSize={11}>
            스탠바이란?
          </Text>
        </div>
        <div
          className="flex flex-col items-center gap-2"
          onClick={() => navigate("/locker")}
        >
          <div
            className={
              "w-[62px] h-[62px] rounded-full bg-[#EDEDED] flex items-center justify-center"
            }
          >
            <img src="/img/standbuy/icon/main/locker.png" alt="" width={"40"} />
          </div>
          <Text fontWeight={500} fontSize={11}>
            보관함
          </Text>
        </div>
        <div
          className="flex flex-col items-center gap-2"
          onClick={() => navigate("/cart")}
        >
          <div
            className={
              "w-[62px] h-[62px] rounded-full bg-[#EDEDED] flex items-center justify-center"
            }
          >
            <img src="/img/standbuy/icon/main/cart.png" alt="" width={"40"} />
          </div>
          <Text fontWeight={500} fontSize={11}>
            장바구니
          </Text>
        </div>

        <div
          className="flex flex-col items-center gap-2"
          onClick={() => navigate(`/my-studio/${me?.memberId}`)}
        >
          <div
            className={
              "w-[62px] h-[62px] rounded-full bg-[#EDEDED] flex items-center justify-center"
            }
          >
            <img src="/img/standbuy/icon/main/studio.png" alt="" width={"40"} />
          </div>
          <Text fontWeight={500} fontSize={11}>
            내 스튜디오
          </Text>
        </div>
      </div>
      {videoModal.show && (
        <StandbuyModal
          open={videoModal.show}
          onClose={() => setVideoModal({ show: false })}
          data={videoModal.data}
        />
      )}
    </>
  );
}
