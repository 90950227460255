import React, { useState } from "react";
import Text from "../../../components/design-system/Text";
import { CustomImage } from "../../../components/design-system/CustomImg";
import Dayjs from "dayjs";
import PayListInfoModal from "../modal/PayListInfoModal";
import { addComma } from "../../../util/number";
import PayCancelModal from "../modal/PayCancelModal";
import PayClickDetail from "./PayClickDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

export default function PayListDetail({ data }: any) {
  const [payInfo, setPayInfo] = useState<any>({ show: false, data: "" });
  const [payCancel, setPayCancel] = useState<any>({ show: false, data: "" });
  const [selectedOrderId, setSelectedOrderId] = useState<any>(null);
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const today = Dayjs();
  const aWeekAgo: any = today.subtract(7, "day");

  const handleItemClick = (orderId: any) => {
    if (selectedOrderId === orderId) {
      setSelectedOrderId(null); // 같은 항목을 클릭하면 닫기
    } else {
      setSelectedOrderId(orderId); // 다른 항목을 클릭하면 해당 항목 열기
    }
  };

  return (
    <>
      {data?.map((item: any, index: any) => (
        <div key={index} className="py-[30px] border-b border-[#EBEBEB]">
          <div className="flex justify-between">
            <div
              className={`flex items-center ${isMobile ? "gap-3" : "gap-6"}`}
            >
              <div
                className={`${isMobile ? "w-[33px]" : "w-[66px] p-[19px] border border-[#E1E1E1] rounded-full"}  `}
              >
                {item?.cancelYn || item?.status === "READY" ? (
                  <img src="/img/standbuy/icon/pay-coin-fail.svg" />
                ) : (
                  <CustomImage
                    src="/img/standbuy/icon/pay-coin.svg"
                    color={"#42BC7F"}
                    className="cursor-pointer"
                    // onClick={() => {
                    //   setPayInfo({ show: true, data: item });
                    // }}
                  />
                )}
              </div>
              <div>
                <Text
                  fontSize={isMobile ? 12 : 16}
                  fontWeight={600}
                  color={
                    item?.cancelYn || item?.status === "READY"
                      ? "#f87171"
                      : "#b2b2b2"
                  }
                >
                  {Dayjs(item?.createTime).format("YYYY.MM.DD, HH:mm:ss")}{" "}
                  {item?.cancelYn
                    ? "결제 취소"
                    : item?.status === "WAITING_FOR_DEPOSIT"
                      ? "결제 대기"
                      : item?.status === "READY"
                        ? "결제 미완료"
                        : "결제 완료"}
                </Text>

                <div className="flex items-center gap-2">
                  <Text
                    fontSize={isMobile ? 12 : 14}
                    fontWeight={600}
                    onClick={() => {
                      setPayInfo({ show: true, data: item });
                    }}
                    className={"cursor-pointer dark:text-darkText"}
                  >
                    상세보기
                  </Text>

                  <Text className={"dark:text-darkText"}>·</Text>

                  <div className={"flex items-center gap-1"}>
                    <Text
                      fontSize={isMobile ? 12 : 14}
                      fontWeight={600}
                      onClick={() => handleItemClick(item?.orderId)}
                      className={"cursor-pointer dark:text-darkText"}
                    >
                      콘텐츠 보기
                    </Text>

                    <FontAwesomeIcon
                      icon={
                        item?.orderId === selectedOrderId
                          ? faChevronDown
                          : faChevronRight
                      }
                      width={item?.orderId === selectedOrderId ? 12 : 7}
                      className="dark:text-darkText"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-end justify-center">
              <Text
                fontSize={isMobile ? 12 : 20}
                fontWeight={600}
                color="#484848"
                className="dark:text-darkText"
              >
                {addComma(item?.amount)}원 결제
              </Text>

              {!item?.cancelYn &&
              Dayjs(item?.createTime).isSame(Dayjs(), "day") ? (
                <Text
                  fontSize={16}
                  fontWeight={600}
                  color="#5C5C5C"
                  className="underline underline-offset-4 cursor-pointer mt-1 dark:text-darkText"
                  onClick={() => {
                    setPayCancel({ show: true, data: item, flag: "all" });
                  }}
                >
                  결제 취소
                </Text>
              ) : null}
            </div>
          </div>

          {item?.orderId === selectedOrderId && (
            <PayClickDetail payOrderId={selectedOrderId} />
          )}
        </div>
      ))}

      {payInfo.show && (
        <PayListInfoModal
          open={payInfo.show}
          onClose={() => setPayInfo({ show: false })}
          data={payInfo.data}
        />
      )}
      {payCancel.show && (
        <PayCancelModal
          open={payCancel.show}
          onClose={() => setPayCancel({ show: false })}
          data={payCancel.data}
          flag={payCancel.flag}
        />
      )}
    </>
  );
}
