//@ts-nocheck
import Banner from "../../components/common/Banner";
import Text from "../../components/design-system/Text";
import FilterBar from "../../components/feed/filter/FilterBar";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { API_DEFAULT } from "../../api/api";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import { setStockList } from "../../store/reducer/mainStock";
import StockListCard from "./stock-list/StockListCard";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FeedNav } from "../../components/design-system/FeedNav";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { instance } from "../../api";
import NoticeModal from "../../components/common/NoticeModal";
import MobileTopSection from "../../components/common/MobileTopSection";
import SlideBanner from "../../components/common/SlideBanner";
import FilterOrderBtn from "../../components/feed/filter/FilterOrderBtn";
import FilterContainer from "../../components/feed/filter/box/FilterContainer";
import FilterReload from "../../components/feed/filter/FilterReload";

export default function NewFeedPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { stockList } = useAppSelector(
    (state) => state.mainStock,
    shallowEqual,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [itmes, setItems] = useState<any>([]);
  const [order, setOrder] = useState<any>("DEFAULT");
  const [toggle, setToggle] = useState(false);

  const handleChange = (event: any) => {
    setOrder(event.target.value as string);
  };

  // const [isPopupShow, setPopupShow] = useState(true);
  // const [infoModal, setInfoModal] = useState<any>({ show: true, data: "" });
  // const expireDay: any = localStorage.getItem("popupNoShow");
  //
  // const closePopup = (expireDays: any) => {
  //   let expire: any = new Date();
  //   expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
  //   localStorage.setItem("popupNoShow", expire.getTime());
  // };
  //
  // const checkPopupClose = () => {
  //   const expireDay: any = localStorage.getItem("popupNoShow");
  //   let today: any = new Date();
  //
  //   if (today.getTime() > expireDay) {
  //     // 이렇게 하면 localStorage에 아무것도 저장되어 있지 않을 경우 undefined 거나 null 이므로 true를 반환한다.
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  //
  // const closePopupToday = () => {
  //   closePopup(1); // 하루 동안 표시 안 할 것임을 명시
  //   setPopupShow(false); // 오늘 하루 안 보기 누름과 동시에 팝업 사라짐
  // };
  //
  // useEffect(() => {
  //   checkPopupClose() ? setPopupShow(true) : setPopupShow(false);
  //   // 최초 컴포넌트가 마운트되었을 때, 팝업을 표시할 지 말지 조회
  // }, []);
  //
  // useEffect(() => {
  //   setInfoModal({ show: true });
  //   if (expireDay) {
  //     setPopupShow(false);
  //   } else {
  //     setPopupShow(true);
  //   }
  // }, [expireDay]);
  //
  // useEffect(() => {
  //   setInfoModal({ show: true });
  // }, []);

  useEffect(() => {
    dispatch(setStockList({ data: itmes, reset: false }));
  }, [itmes]);

  // 진입 페이지
  async function getItems(nextGroupKey: number, count: number, order: string) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await instance.post(
      `${API_DEFAULT}/contents/search/main?page=${nextGroupKey}&size=${count}&order=${order}`,
    );

    if (res?.data?.success) {
      nextItems = res.data.data; // 응답 구조에 따라 수정 필요
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setGroupKey(1);
    setItems([]);

    (async () => {
      const initialItems = await getItems(1, 15, order);
      if (initialItems?.length > 0) {
        dispatch(setStockList({ data: initialItems, reset: true }));
        setItems(initialItems);
      }
    })();
  }, [order]);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div
        className={`${isMobile ? "mx-3  mt-[80px]" : "mx-12  mt-[120px]"} mb-10 min-h-screen`}
      >
        <div className="my-[50px] mb-[15px]">
          {isMobile ? <MobileTopSection /> : <SlideBanner />}
        </div>

        <div className="mb-[14px] flex gap-5 overflow-auto items-center justify-between">
          {isMobile ? (
            <FilterBar itmes={itmes} toggle={toggle} setToggle={setToggle} />
          ) : (
            <FilterReload toggle={toggle} setToggle={setToggle} />
          )}

          <FilterOrderBtn order={order} handleChange={handleChange} />
        </div>

        <div className={"flex"}>
          {!isMobile && toggle && (
            <FilterContainer toggle={toggle} setToggle={setToggle} />
          )}

          <JustifiedInfiniteGrid
            // key={toggle ? "expanded" : "collapsed"}
            useResizeObserver={true}
            className={`transition-all duration-300 ease-in-out`}
            style={{
              width: toggle && !isMobile ? "calc(100% - 220px)" : "100%",
            }}
            loading={
              <FadeLoader
                color={
                  themeData === "light" || themeData === ""
                    ? "#121212"
                    : "#ffffff"
                }
              />
            }
            stretch={true}
            passUnstretchRow={true}
            sizeRange={[228, 228]}
            stretchRange={[144, 320]}
            {...(isMobile && { columnRange: [0, 2] })}
            threshold={3000}
            gap={8}
            onRequestAppend={(e) => {
              const nextGroupKey = (+e.groupKey! || 0) + 1;
              if (nextGroupKey === groupKey) {
                return;
              }
              setGroupKey(nextGroupKey);
              e.wait();

              setTimeout(async () => {
                const add = await getItems(nextGroupKey, 15, order);
                if (add?.length === 0) {
                  e.ready();
                  return true;
                }

                dispatch(setStockList({ data: add, reset: false }));
                setItems([...itmes, ...add]);
                e.ready();
              }, 1);
            }}
          >
            {itmes?.length < 1 ? (
              <div className="flex flex-col items-center justify-center animate-pulse">
                <div className="h-4 w-32 bg-gray-200 rounded mb-2"></div>
                <div className="h-4 w-48 bg-gray-200 rounded"></div>
              </div>
            ) : (
              itmes?.map((item: any, index: any) => (
                <StockListCard
                  data-grid-groupkey={item.groupKey}
                  key={index}
                  index={index}
                  item={item}
                  onClick={undefined}
                />
              ))
            )}
          </JustifiedInfiniteGrid>
        </div>
      </div>

      <div
        className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
      >
        <div className="flex flex-col justify-center items-center gap-4 z-[9999]">
          <FeedNav />

          {!isMobile && (
            <IconButton
              //@ts-ignore
              className="rounded-full px-[28px] py-[28px]"
              placeholder={undefined}
              children={undefined}
              size="lg"
              onClick={moveToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </IconButton>
          )}
        </div>
      </div>
      {/*{infoModal.show && isPopupShow && (*/}
      {/*  <NoticeModal*/}
      {/*    open={infoModal.show}*/}
      {/*    onClose={() => setInfoModal({ show: false })}*/}
      {/*    data={infoModal.data}*/}
      {/*    closePopupToday={closePopupToday}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
}
