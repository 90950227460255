import React, { useEffect, useState } from "react";
import Text from "../../design-system/Text";
import { Avatar, Tooltip } from "@material-tailwind/react";
import { checkRole } from "../../../util";
import FillButton from "../../design-system/button/FillButton";
import { useAxios } from "../../../lib/api/useAxios";
import { API_SOCIAL } from "../../../api/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FillIconButton from "../../design-system/button/FillButtonIcon";
import FollowerCheck from "../../svg/FollowerCheck";
import { useContext } from "../../../util/useContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../../util/cookies";
import {
  modalFetched,
  niceModalFetched,
} from "../../../store/reducer/globalModalSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CustomImage } from "../../design-system/CustomImg";
import { shallowEqual } from "react-redux";
import CustomTooltip from "../../design-system/CustomTooltip";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { instance } from "../../../api";

export default function SellerInfo({ detail, state, showAllKeywords }: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const queryClient = useQueryClient();
  const { me, isLogin } = useContext();
  const navigate = useNavigate();
  // const isInfo = getCookie("me");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [fbps, setFbps] = useState<any>("");
  const params = useParams();

  const { status, data } = useQuery({
    queryKey: ["social-detail", detail],
    queryFn: async () =>
      await instance.get(`${API_SOCIAL}/user/social/get/detail`, {
        params: {
          contentId: state?.contentId || detail?.contentId,
          artistId: state?.memberId || detail?.memberId,
        },
      }),
  });

  async function userFollower() {
    const payload = {
      artistId: detail?.memberId,
    };
    const res = await instance.post(`${API_SOCIAL}/user/social/sub`, payload);
  }

  const { mutate: handleFollower } = useMutation({
    mutationFn: userFollower,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["social-detail"] });
    },
  });

  // useEffect(() => {
  //   if (detail) {
  //     let parts = detail?.r_frame_rate?.split("/");
  //
  //     parts[0] = parseInt(parts[0]);
  //     parts[2] = parseInt(parts[1]);
  //
  //     setFbps((parts[0] / parts[2]).toFixed(2));
  //   }
  // }, [detail]);

  function containsKorean(text: any) {
    const koreanRegex = /[가-힣]/;
    return koreanRegex.test(text);
  }

  return (
    <div className={`${isMobile ? "mt-10" : "mt-10"}`}>
      <Text
        fontSize={14}
        color="#838383"
        fontWeight={600}
        className={`${showAllKeywords && "mt-[90px]"} dark:text-darkText`}
      >
        판매자 정보
      </Text>

      <div className="flex justify-between items-center mb-5">
        <div
          className="mt-5 flex gap-3 cursor-pointer"
          onClick={() => {
            if (me?.memberId === detail?.memberId) {
              if (window.opener && !window.opener.closed) {
                // 부모 창의 navigateToPage 함수 호출
                window.opener.navigateToPage(`/my-studio/${detail?.memberId}`, {
                  replace: true,
                });
                window.close();
              }
            } else if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            } else {
              // 부모 창이 존재하고 접근 가능한지 확인
              if (window.opener && !window.opener.closed) {
                // 부모 창의 navigateToPage 함수 호출
                window.opener.navigateToPage(`/studio/${detail?.memberId}`, {
                  state: detail?.memberId,
                  replace: true,
                });
                // 자식 창 닫기
                window.close();
              }
            }
          }}
        >
          <Avatar
            variant="circular"
            alt="profile img"
            src={
              detail?.profileImage
                ? detail?.profileImage
                : "/img/standbuy/icon/no-profile.svg"
            }
            placeholder={undefined}
            className="border border-[#134B70] ring-2 ring-[#134B70] p-0.5"
          />

          <div>
            <div className={`relative w-full`}>
              <Text
                fontSize={20}
                fontWeight={600}
                color="#373737"
                className="dark:text-darkText"
              >
                {detail?.memberNickname}
              </Text>
              {detail?.memberGrade === "ROLE_DIRECTOR" &&
                !location?.pathname.includes("/share") && (
                  <div
                    className={`w-[20px] absolute -top-1  z-[998]  ${detail?.memberNickname.length <= 2 ? "right-1" : containsKorean(detail?.memberNickname) ? "-right-7" : "-right-1"}`}
                  >
                    <CustomTooltip
                      title="Director 마크 : 스탠바이가 인증한 전문가에요😀"
                      grade
                    />
                  </div>
                )}
            </div>
            <Text fontSize={13} fontWeight={500} color="#7b7b7b">
              {checkRole(detail?.memberGrade)}
              {data?.data.data.subscriberCount > 0
                ? " · " + data?.data.data.subscriberCount + "명"
                : null}
            </Text>
          </div>
        </div>

        <div>
          {data?.data.data.subState ? (
            <FillIconButton
              onClick={handleFollower}
              text="구독중"
              className="w-[110px]"
              icon={<FollowerCheck />}
            />
          ) : (
            <FillButton
              onClick={() => {
                if (isLogin && me?.mobileVerification) {
                  handleFollower();
                } else if (isLogin && !me?.mobileVerification) {
                  dispatch(niceModalFetched({ show: true }));
                } else if (isLogin === false) {
                  dispatch(modalFetched({ show: true }));
                }
              }}
              text="구독"
              className="w-[110px]"
              disabled={me?.memberId === detail?.memberId ? true : false}
            />
          )}
        </div>
      </div>

      <div>
        <Text fontSize={14} fontWeight={500} color="#535353">
          {detail?.memberDescription ? detail?.memberDescription : null}
        </Text>
      </div>

      <div className="w-full h-[1px] bg-[#f1f1f1] my-[30px] dark:bg-gray-700"></div>

      <Text
        fontSize={14}
        color="#838383"
        fontWeight={600}
        className="dark:text-darkText"
      >
        콘텐츠 구분
      </Text>

      <div className="flex gap-20">
        <div className="mt-[10px] flex flex-col gap-3">
          {detail?.formatKind === null ? (
            <div className="flex items-center gap-2">
              <CustomImage
                src="/img/standbuy/icon/metadata/codec.svg"
                color={
                  themeData === "light" || themeData === "" ? "#000" : "#fff"
                }
                width={20}
              />

              <Text fontSize={16} color="#535353" fontWeight={600}>
                {detail?.codecName}
              </Text>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <CustomImage
                src="/img/standbuy/icon/metadata/formatKind.svg"
                color={
                  themeData === "light" || themeData === "" ? "#000" : "#fff"
                }
                width={20}
              />
              <Text fontSize={16} color="#535353" fontWeight={600}>
                {detail?.formatKind || "등록된 정보가 없습니다."}
              </Text>
            </div>
          )}

          <div className="flex items-center gap-2">
            <CustomImage
              src="/img/standbuy/icon/metadata/contentWidth.svg"
              color={
                themeData === "light" || themeData === "" ? "#000" : "#fff"
              }
              width={20}
            />

            <Text fontSize={16} color="#535353" fontWeight={600}>
              {detail?.contentWidth} x {detail?.contentHeight}
            </Text>
          </div>

          {detail?.classification === "VIDEO" && (
            <div className="flex items-center gap-2">
              <CustomImage
                src="/img/standbuy/icon/metadata/frame.svg"
                color={
                  themeData === "light" || themeData === "" ? "#000" : "#fff"
                }
                width={20}
              />

              <Text fontSize={16} color="#535353" fontWeight={600}>
                {detail?.r_frame_rate} fps
              </Text>
            </div>
          )}
        </div>

        <div className="mt-[10px] flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <CustomImage
              src="/img/standbuy/icon/metadata/fileSize.svg"
              color={
                themeData === "light" || themeData === "" ? "#000" : "#fff"
              }
              width={20}
            />

            <Text fontSize={16} color="#535353" fontWeight={600}>
              {(detail?.size / 1000000).toFixed(2)}MB
            </Text>
          </div>

          {detail?.classification === "VIDEO" && (
            <div className="flex items-center gap-2">
              <CustomImage
                src="/img/standbuy/icon/metadata/bitRate.svg"
                color={
                  themeData === "light" || themeData === "" ? "#000" : "#fff"
                }
                width={20}
              />
              <Text fontSize={16} color="#535353" fontWeight={600}>
                {(detail?.bit_rate / 1000000).toFixed(2)}Mbps
              </Text>
            </div>
          )}

          {detail?.formatKind && (
            <div className="flex items-center gap-2">
              <CustomImage
                src="/img/standbuy/icon/metadata/codec.svg"
                color={
                  themeData === "light" || themeData === "" ? "#000" : "#fff"
                }
                width={20}
              />

              <Text fontSize={16} color="#535353" fontWeight={600}>
                {detail?.codecName}
              </Text>
            </div>
          )}
        </div>

        {/* <div className="mt-[10px] flex flex-col gap-1">
          {detail?.formatKind.includes("video") ||
            (detail?.formatKind.includes("QuickTime") && (
              <Text fontSize={16} color="#535353" fontWeight={600}>
                영상 길이 : {detail?.contentLength}
              </Text>
            ))}
        </div> */}
      </div>

      {detail?.description && (
        <>
          <div className="w-full h-[1px] bg-[#f1f1f1] my-[30px] dark:bg-gray-700"></div>

          <Text
            fontSize={14}
            color="#838383"
            fontWeight={600}
            className="mb-[10px] dark:text-darkText"
          >
            콘텐츠 설명
          </Text>

          <Text fontSize={14} fontWeight={500} color={"#686868"}>
            {detail?.description}
          </Text>
        </>
      )}

      <div className="w-full h-[1px] bg-[#f1f1f1] my-[30px] dark:bg-gray-700"></div>
    </div>
  );
}
