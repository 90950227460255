import styled from "styled-components";
import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useAppSelector } from "../../../store/store";
import { API_DEFAULT, GOOGLE_MAP_KEY, URL_HOST } from "../../../api/api";
import LoopItem from "../../../components/common/LoopItem";
import GoogleMap from "../../../lib/modules/google-map";
import Text from "../../../components/design-system/Text";
import { Avatar } from "@material-tailwind/react";
import FeedInfoModal from "../../feed/modal/FeedInfoModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { setDetail } from "../../../store/reducer/feedSlice";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMediaQuery } from "react-responsive";

export default function LocationFeedMap({ lat, lng }: any) {
  const { get } = useAxios();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const bounds = useAppSelector((state) => state.bounds);
  const { positions } = useAppSelector((state) => state.location);
  const [info, setInfo] = useState<any>(null);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const location = useLocation();
  const [id, setId] = useState<any>(null);
  const [pinData, setPinData] = useState<any>(null);
  const navigate = useNavigate();

  // const { data, status } = useQuery({
  //   queryKey: ["marker-detail", id],
  //   queryFn: async () =>
  //     await get(
  //       `${API_DEFAULT}/contents/search/markers/detail?contentId=${id}`,
  //     ),
  // });
  //
  // console.log(status);

  // async function onFetch() {
  //   const res = await get(
  //     `${API_DEFAULT}/contents/search/markers/detail?contentId=${id}`,
  //   );
  //   if (res) {
  //     setPinData(res?.data);
  //   }
  // }
  //
  // useEffect(() => {
  //   onFetch();
  // }, [id]);

  const renderItem = useCallback((item: any, pinData: any) => {
    return ReactDOMServer.renderToString(null);
  }, []);

  useEffect(() => {
    const _info = positions?.map((items: any) => {
      return {
        ...items,
        renderItem: () => renderItem(items, pinData),
      };
    });

    setInfo(_info);
  }, [pinData, positions]);

  const popupWidth = 1400;
  const popupHeight = 1000;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  // 팝업 창을 화면 가운데에 위치시키기 위한 좌표 계산
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  return (
    <>
      <Content>
        <GoogleMap
          // isCenter
          API_KEY={GOOGLE_MAP_KEY as any}
          style={{
            width: "100%",
            height: isMobile ? " calc(100vh - 40vh) " : "calc(100vh - 10vh)",
          }}
          onClick={(e: any) => {
            // const pos = {lat: e.latLng.lat(), lng: e.latLng.lng()}
            // console.log(e);
          }}
          onInfoWindowDomReady={(e: any) => {
            window.name = JSON.stringify({ contentId: e?.contentId });
            window.open(
              `${URL_HOST}feed-info`,
              "feed",
              `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`,
            );
          }}
          mapOptions={{
            zoom:
              location.pathname === "/location-feed" && location.state === null
                ? 9
                : 16,
            minZoom: 7,
            maxZoom: 20,
            center: {
              lat: lat || 37.5642135,
              lng: lng || 127.0016985,
            },
            zoomControl: true,
            fullscreenControl: true,
            mapTypeControl: true,
            styles: [
              {
                featureType: "poi",
                stylers: [{ visibility: "off" }], // POI 숨기기
              },
              {
                featureType: "transit",
                stylers: [{ visibility: "off" }], // 대중교통 정보 숨기기
              },
            ],
          }}
          positions={info}
        />
      </Content>
      {infoModal.show && (
        <FeedInfoModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
    </>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  /* list-wrap */
  .google-map-infowindow {
    width: 360px;
    /* padding: 15px 10px; */
    border-radius: 20px;
    .contentName {
      color: #5b5b5b;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.35px;
    }
    .thumbnailPath {
      width: 100%;
      height: 100%;
      margin-bottom: 10px;
      border-radius: 20px;
    }
    .keyword {
      display: inline-block;
      padding: 0 5px;
      color: #989898;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 169.231% */
      letter-spacing: -0.325px;
    }
    .header {
      margin-bottom: 10px;
    }
    .footer {
      display: flex;
      width: 100%;
      height: 39px;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      background-color: green;

      button {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
