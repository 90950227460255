import React, { useEffect, useRef, useState } from "react";
import { API_DEFAULT } from "../../../api/api";
import useIntersectObserver from "../../../components/hooks/useIntersectObserver";
import Text from "../../../components/design-system/Text";
import Dayjs from "dayjs";
import NoExchangeData from "./NoExchangeData";
import { addComma } from "../../../util/number";
import ExchangeCancelModal from "../modal/ExchangeCancelModal";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../../api";

export default function ExchangeList() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [cancelModal, setCancelModal] = useState<any>({
    show: false,
    data: "",
  });

  const getExchangeListData = async (page: any) => {
    try {
      const res = await instance.get(
        `${API_DEFAULT}/user/settle/get?page=${page}&size=10`,
        {
          params: {
            page,
            size: 10,
          },
        },
      );
      return res.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [page, setPage] = useState(0);
  const [commentList, setCommentList] = useState<any>([]);

  const intersectRef = useRef<any>(null);
  const [isLastPage, setIsLastPage] = useState(false);

  const { isIntersect } = useIntersectObserver(intersectRef, {
    rootMargin: "200px",
    threshold: 0.01,
  });

  const loadMoreCommentData = async () => {
    if (isIntersect) {
      try {
        const data = await getExchangeListData(page);

        if (data?.data.length === 0) {
          setIsLastPage(true);
        } else {
          // 여기서 data가 반드시 배열인지 확인
          if (Array.isArray(data?.data)) {
            setCommentList((prevComments: any) => [
              ...prevComments,
              ...data?.data,
            ]);
            setPage((prev) => prev + 1);
          } else {
            // data가 배열이 아닐 때의 처리 로직 추가
            // console.error("data is not an array", data);
          }
        }
      } catch (error) {
        console.error("Error loading more comment data:", error);
        // 필요한 경우 에러 상태 설정 또는 사용자에게 피드백 제공
      }
    }
  };

  useEffect(() => {
    loadMoreCommentData();
  }, [isIntersect, isLastPage, page]);

  return (
    <>
      {commentList?.length < 1 ? (
        <NoExchangeData />
      ) : (
        commentList?.map((item: any, index: any) => (
          <div className="py-[30px] border-b border-[#EBEBEB]">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <Text
                  fontSize={isMobile ? 14 : 16}
                  fontWeight={600}
                  color={
                    item?.status === "PROCESSING"
                      ? "#42BC7F"
                      : item?.status === "COMPLETED"
                        ? "#3b82f6"
                        : "#f87171"
                  }
                >
                  {item?.status === "PROCESSING"
                    ? "진행 중"
                    : item?.status === "COMPLETED"
                      ? "완료"
                      : "취소"}
                </Text>
                <div className="flex items-center gap-4">
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    fontWeight={600}
                    color="#b2b2b2"
                  >
                    {Dayjs(item?.createTime).format("YYYY.MM.DD")}
                  </Text>
                </div>
              </div>

              <div className="">
                {item?.cancelReason && (
                  <Text
                    fontSize={isMobile ? 12 : 16}
                    fontWeight={600}
                    color="#4b4b4b"
                    className={"dark:text-darkText"}
                  >
                    {item?.cancelReason}
                  </Text>
                )}

                {item?.refundAmount && (
                  <Text
                    fontSize={isMobile ? 12 : 16}
                    fontWeight={600}
                    color="#4b4b4b"
                    className={"dark:text-darkText"}
                  >
                    입금 : {addComma(item?.refundAmount)} ₩
                  </Text>
                )}
              </div>

              <div className="flex flex-col items-end justify-center">
                <Text
                  fontSize={isMobile ? 12 : 16}
                  fontWeight={700}
                  color={
                    item?.status === "PROCESSING"
                      ? "#42BC7F"
                      : item?.status === "COMPLETED"
                        ? "#3b82f6"
                        : "#f87171"
                  }
                >
                  {addComma(item?.amount)} ₩
                </Text>

                {/*{item?.status === "PROCESSING" && (*/}
                {/*  <Text*/}
                {/*    fontSize={16}*/}
                {/*    fontWeight={600}*/}
                {/*    color="#5C5C5C"*/}
                {/*    className="underline underline-offset-4 cursor-pointer mt-1 dark:text-darkText"*/}
                {/*    onClick={() => {*/}
                {/*      setCancelModal({ show: true, data: item });*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    정산 취소*/}
                {/*  </Text>*/}
                {/*)}*/}
              </div>
            </div>
          </div>
        ))
      )}
      {!isLastPage && <div id="intersectElement" ref={intersectRef}></div>}
      {cancelModal.show && (
        <ExchangeCancelModal
          open={cancelModal.show}
          onClose={() => setCancelModal({ show: false })}
          data={cancelModal.data}
        />
      )}
    </>
  );
}
