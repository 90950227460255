//@ts-nocheck
import React, { useEffect, useState } from "react";
import AssetsMode from "../../../components/feed/modal/AssetsMode";
import { API_DEFAULT } from "../../../api/api";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setDetail, setReportText } from "../../../store/reducer/feedSlice";
import FeedModal from "../../../components/design-system/modal/FeedModal";
import SellerInfo from "../../../components/feed/modal/SellerInfo";
import FeedContent from "../../../components/feed/modal/FeedContent";
import FeedComment from "../../../components/feed/modal/FeedComment";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { instance } from "../../../api";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function FeedInfoModal({ open, onClose, data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { detail } = useAppSelector((state) => state.feed);
  const [loading, setLoading] = useState(true);
  const [showAllKeywords, setShowAllKeywords] = useState(false);

  async function onFetch() {
    const payload = { contentId: data?.target || data?.contentId };
    const res = await instance.get(`${API_DEFAULT}/contents/search/detail`, {
      params: payload,
    });
    if (res?.data.success) {
      setLoading(false);
      dispatch(setDetail(res?.data.data));
      return true;
    } else {
      onClose();
      dispatch(
        toastFetched({
          show: true,
          text:
            "제재 사유 : " + res?.data.data.reason ||
            "제재 받은 콘텐츠 입니다.",
          type: "error",
        }),
      );
      dispatch(setReportText(res?.data.data.reason));
    }
  }

  useEffect(() => {
    onFetch();
  }, []);

  useEffect(() => {
    if (
      location.search === "" &&
      (location.pathname === "/feed" || location.pathname === "/location-feed")
    ) {
      onClose();
    }
  }, [location]);

  useEffect(() => {
    try {
      // 광고 초기화
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Adsense error: ", e);
    }
  }, []);

  return (
    <FeedModal
      open={open}
      onClose={() => {
        window.history.replaceState(null, "", window.location.pathname);
        onClose();
      }}
      width={"1000px"}
      feed={true}
      noOutSideClosed={false}
      headerNone={isMobile ? true : false}
    >
      {loading ? (
        <span>loading</span>
      ) : (
        <>
          <div className={`flex ${isMobile && "flex-col"} gap-10`}>
            <AssetsMode />
            <FeedContent
              onClose={onClose}
              showAllKeywords={showAllKeywords}
              setShowAllKeywords={setShowAllKeywords}
            />
            {/*<ins*/}
            {/*  className="adsbygoogle"*/}
            {/*  style={{ display: "block" }}*/}
            {/*  data-ad-client="ca-pub-1049694803672930"*/}
            {/*  data-ad-slot="9250904794"*/}
            {/*  data-ad-format="auto"*/}
            {/*  data-full-width-responsive="true"*/}
            {/*></ins>*/}
          </div>
          <div>
            <SellerInfo detail={detail} showAllKeywords={showAllKeywords} />
            <FeedComment detail={detail} />
          </div>
        </>
      )}
    </FeedModal>
  );
}
