import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import { useAxios } from "../../../lib/api/useAxios";
import { useContext } from "../../../util/useContext";
import { API_DEFAULT, API_SOCIAL } from "../../../api/api";
import OthersStudioFeedCard from "../card/OthersStudioFeedCard";
import { FeedNav } from "../../../components/design-system/FeedNav";
import OtherAvatarMobileCard from "./card/OtherAvatarMobileCard";
import OtherProfileMobileCard from "./card/OtherProfileMobileCard";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";

export default function OtherStudioMobilePage() {
  const { get } = useAxios();
  const { state } = useContext();
  const location = useLocation();
  const [id, setId] = useState<any>();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const parmas = useParams();

  const { data } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      const userId = parmas?.memberId || state;
      return await get(`${API_DEFAULT}/user/studio/${userId}`);
    },
    enabled: state !== null || parmas?.memberId !== null,
  });

  const { data: checkInfo } = useQuery({
    queryKey: ["subCheck"],
    queryFn: async () => {
      const userId = parmas?.memberId || state;
      return await get(`${API_SOCIAL}/user/social/get/check/sub/${userId}`);
    },
  });

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[80px] w-full max-w-[1280px]">
          <div className="flex flex-col">
            <OtherAvatarMobileCard
              data={data?.data}
              check={checkInfo?.data.check}
            />
            <OtherProfileMobileCard data={data?.data} />
          </div>

          <OthersStudioFeedCard
            data={data?.data}
            otherData={state}
            paramsData={parmas?.memberId}
          />
        </div>

        <div
          className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
        >
          <div className="flex flex-col justify-center items-center gap-4">
            <FeedNav />

            {!isMobile && (
              <IconButton
                //@ts-ignore
                className="rounded-full px-[28px] py-[28px]"
                placeholder={undefined}
                children={undefined}
                size="lg"
                onClick={moveToTop}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </IconButton>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
