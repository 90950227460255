import React, { useState } from "react";
import Card from "../../../components/design-system/Card";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";

export default function NiceSocialCard() {
  const copyToClipboard = async (val: string) => {
    try {
      await navigator.clipboard.writeText(val);
      alert(
        'URL주소가 복사되었습니다.\n\nSafari가 열리면 주소창을 길게 터치한 뒤, "붙여놓기 및 이동"를 누르면 정상적으로 이용하실 수 있습니다.',
      );
    } catch (err) {
      console.error("Failed to copy!", err);
      alert("복사에 실패했습니다. 수동으로 복사해주세요.");
    }
  };

  const inAppBrowserOut = () => {
    copyToClipboard(window.location.href);
    window.location.href = "x-web-search://?";
  };

  const openInChrome = () => {
    const userAgent = navigator.userAgent;

    if (/Android/i.test(userAgent)) {
      // Redirect to Chrome on Android
      window.location.href =
        "intent://" +
        window.location.href.replace(/https?:\/\//, "") +
        "#Intent;scheme=http;package=com.android.chrome;end";
    } else if (/iPhone|iPad/i.test(userAgent)) {
      // Attempt to open in Chrome on iOS
      const chromeUrl =
        "googlechrome://" + window.location.href.replace(/^https?:\/\//, "");
      window.location.href = chromeUrl;

      // Optional: Provide fallback if Chrome is not installed
      setTimeout(() => {
        alert(
          "Chrome이 설치되어 있지 않은 것 같습니다.\nChrome을 설치하시거나, Safari로 여시기 바랍니다.",
        );
        window.location.href =
          "https://apps.apple.com/us/app/google-chrome/id535886823";
      }, 1000);
    }
  };

  return (
    <Card>
      <div className="flex flex-col">
        <div className="leading-[2.5rem]">
          <Text className="dark:text-darkText" fontSize={32} fontWeight={900}>
            인앱브라우저
          </Text>
          <div className={"flex gap-6"}>
            <Text
              className="dark:text-darkText "
              fontSize={32}
              fontWeight={900}
            >
              지원 불가.
            </Text>
            <div className={"flex gap-2"}>
              <img src={"/img/standbuy/icon/kakao-icon.svg"} alt={""} />
              <img src={"/img/standbuy/icon/insta-icon.svg"} alt={""} />
            </div>
          </div>
        </div>

        <div className="mt-[30px]">
          <Text className="dark:text-darkText" fontSize={18} fontWeight={400}>
            스탠바이는 <strong>사파리, 크롬</strong> 사용을
          </Text>

          <Text className="dark:text-darkText" fontSize={18} fontWeight={400}>
            권장하고 있어요. 원할한 스탠바이
          </Text>

          <Text className="dark:text-darkText" fontSize={18} fontWeight={400}>
            생활을 위해 아래 버튼을 눌러주세요:)
          </Text>
        </div>
      </div>

      <div className="mt-8 flex items-center justify-center gap-3">
        <FillButton
          onClick={openInChrome}
          text={"Chrome으로 열기"}
          className="w-full"
          size="lg"
        />
        <FillButton
          onClick={inAppBrowserOut}
          text={"Safari로 열기"}
          className="w-full"
          size="lg"
        />
      </div>
    </Card>
  );
}
