//@ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import LocationFeedMap from "./card/LocationFeedMap";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import {
  setLocationList,
  setLocationListInit,
  setPositions,
  setSearchResult,
} from "../../store/reducer/locationSlice";
import Text from "../../components/design-system/Text";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import StockListCard from "../feed/stock-list/StockListCard";
import { shallowEqual } from "react-redux";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SearchByMap from "../../lib/modules/google-map/SearchByMap";
import debounce from "lodash/debounce";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../api";
import FeedInfoModal from "../feed/modal/FeedInfoModal";
import LocationInfoModal from "./modal/LocationInfoModal";
import LocationSideView from "./card/LocationSideView";
import { useContext } from "../../util/useContext";

export default function LocationFeedPage() {
  const navigate = useNavigate();
  const { me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [loading, setLoading] = useState(false);
  const bounds = useAppSelector((state) => state.bounds);
  const zoom = useAppSelector((state) => state.bounds.zoom);
  const location = useLocation();
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { positions, locationList } = useAppSelector((state) => state.location);
  const [itmes, setItems] = useState<any>([]);
  const { lat, lng } = useAppSelector(
    (state) => state.geoLocation,
    shallowEqual,
  );
  const [contentId, setContentId] = useState<number | null>(null);
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [pos, setPos] = useState({
    lat: !lat ? 37.56462664995 : lat,
    lng: !lng ? 127.02878456871 : lng,
  });
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });

  // useEffect(() => {
  //   setInfoModal({ show: true });
  // }, []);

  useEffect(() => {
    setPos({
      lat: !lat ? 37.56462664995 : lat,
      lng: !lng ? 127.02878456871 : lng,
    });
  }, [lat]);

  const debouncedOnAllLocation = useRef(
    debounce(async ({ latMin, latMax, lonMin, lonMax, zoom }) => {
      setLoading(true);
      const payload = {
        latMin: Math.min(latMax, latMin),
        latMax: Math.max(latMax, latMin),
        lonMin: Math.min(lonMax, lonMin),
        lonMax: Math.max(lonMax, lonMin),
        zoom: zoom,
      };
      const res = await instance.post(
        `${API_DEFAULT}/contents/search/markers`,
        payload,
      );
      if (res?.data.success) {
        dispatch(setSearchResult([]));
        dispatch(setPositions(res?.data.data));
        setLoading(false);
      } else {
        // 에러 처리
      }
    }, 500),
  ).current;

  useEffect(() => {
    if (bounds.ne && bounds.sw && location.pathname === "/location-feed") {
      setTimeout(() => {
        debouncedOnAllLocation({
          latMin: bounds?.ne.lat,
          latMax: bounds?.sw.lat,
          lonMin: bounds?.ne.lng,
          lonMax: bounds?.sw.lng,
          zoom: zoom,
        });
      }, 500);
    }
  }, [bounds]);

  // async function getItems(nextGroupKey: number, count: number) {
  //   let nextItems = [];
  //   const nextKey = nextGroupKey * count;
  //
  //   const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
  //     latMin: 36.507468472827576,
  //     latMax: 38.60617845599798,
  //     lonMin: 125.24388600000003,
  //     lonMax: 128.75951100000003,
  //     page: nextGroupKey,
  //     size: count,
  //   });
  //
  //   if (res?.data.success) {
  //     nextItems = res?.data.data;
  //     if (nextItems.length === 0) return [];
  //     nextItems.map((item: any, index: number) => {
  //       item.groupKey = nextGroupKey;
  //       item.key = nextKey + index;
  //     });
  //   }
  //
  //   return nextItems;
  // }

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (!me || !me.memberId) return;

    if (
      me.memberId === 1 ||
      me.memberId === 2 ||
      me.memberId === 3 ||
      me.memberId === 6
    ) {
      return;
    } else {
      navigate("/feed");
    }
  }, [me]);

  return (
    <div className={`w-full flex max-w-screen`}>
      {!isMobile && (
        <div className="w-1/6 h-full ml-[20px] relative">
          <LocationSideView />
        </div>
      )}

      <div
        className={`flex flex-col justify-between pt-[80px] ${
          isMobile ? "" : "fixed"
        }`}
        style={{
          left: isMobile ? "" : "calc(16.6667% + 20px)",
          width: isMobile ? "100%" : "calc(83.3333% - 20px)",
        }}
      >
        <motion.div
          className={`w-full ${
            isMobile ? "px-10" : "flex justify-center"
          } mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className={`${isMobile ? "" : "w-full mx-10"}`}>
            {/*<div className="mt-10 mb-4">*/}
            {/*  <Text color="#303030" className="title dark:text-darkText">*/}
            {/*    지도로 찾기*/}
            {/*  </Text>*/}
            {/*</div>*/}

            <BorderWrap className="p-6 mb-4 mt-6">
              <SearchByMap />
            </BorderWrap>

            <div style={{ position: "relative" }} className={"w-full"}>
              {/* 로딩 상태 처리 */}
              <LocationFeedMap lat={lat} lng={lng} />
            </div>

            <LocationSideView />
          </div>

          <div
            className={`fixed bottom-10 ${
              isMobile ? "left-5" : "right-10"
            } z-[9999]`}
          >
            <div className="flex flex-col justify-center items-center gap-4">
              <FeedNav />

              {!isMobile && (
                <IconButton
                  //@ts-ignore
                  className="rounded-full px-[28px] py-[28px]"
                  placeholder={undefined}
                  children={undefined}
                  size="lg"
                  onClick={moveToTop}
                >
                  <FontAwesomeIcon icon={faArrowUp} />
                </IconButton>
              )}
            </div>
          </div>
        </motion.div>

        {infoModal.show && (
          <LocationInfoModal
            open={infoModal.show}
            onClose={() => setInfoModal({ show: false })}
            data={infoModal.data}
          />
        )}
      </div>
    </div>
  );
}

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: 100%;
`;
